// import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const Container = React.memo(
  ({ children, variant, noPadding, className, style, fullBleed, id }) => {
    if ((fullBleed == null, !fullBleed)) {
      return (
        // relatively positioned and z-10 to allow backgrounds to be more easily placed
        <div
          id={id}
          className={`${classNames({
            "mx-auto w-full": variant === "full",
            "max-w-6xl xl:max-w-7xl": variant === "lg",
            "max-w-5xl xl:max-w-6xl": variant === "md" || variant === "site",
            "max-w-3xl xl:max-w-4xl": variant === "sm",
            "max-w-xl xl:max-w-2xl": variant === "xs",
            "max-w-2xl xl:max-w-3xl": variant === "copy",
            "max-w-3xl xl:max-w-5xl": variant === "heading",
            "md:w-full": variant === "blog",
            "px-6 md:px-3 lg:px-6": !noPadding,
            "relative z-10 md:mx-auto lg:w-full": true,
          })} ${className}`}
          style={style}
        >
          {children}
        </div>
      );
    }
    return children;
  }
);

Container.defaultProps = {
  noPadding: false,
  variant: "site",
  className: "",
  style: {},
  fullBleed: false,
  id: null,
};

Container.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
  variant: PropTypes.string,
  noPadding: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  fullBleed: PropTypes.bool,
};

export default Container;
