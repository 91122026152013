// eslint-disable-next-line import/prefer-default-export
export { default as HeroAnimation } from "./HeroAnimation.json";
export { default as DesignHeroAnimation } from "./DesignHeroAnimation.json";
export { default as ProjectsHeroAnimation } from "./ProjectsHeroAnimation.json";
export {
  default as ZapierSubscribe,
} from "./hubspot-subscribe-update-payload.json";
export {
  default as ZapierSubscribeResponse,
} from "./hubspot-subscribe-update-response.json";
