/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { RichText } from "@ui";
import { Container } from "@layout";
import HeroShapes from "./Hero/HeroShapes";

const HeroSimple = React.memo(
  ({ content, variant, centered, noShapes, gradient, topPadding }) => {
    const gradientColor = gradient && `gradient-${gradient}`;
    return (
      <section
        className={classNames(`${gradientColor} ${topPadding}`, {
          "text-center": centered,
          "bg-green text-white": variant === "green",
          "bg-white text-black": variant === "light",
          "bg-blue text-white": variant !== "green" && variant !== "light",
          "hero--simple flex flex-col justify-center min-h-hero-mobile sm:min-h-hero-sm pt-32 pb-16 items-center relative": true,
        })}
      >
        <HeroShapes noShapes={noShapes} variant={variant} />
        <Container>
          <div
            className={classNames({
              "justify-center": centered,
              "flex flex-wrap items-center": true,
            })}
          >
            <div className="z-10 relative w-full sm:w-6/7 md:w-2/3 lg:w-3/5">
              <h1
                className={classNames({
                  "text-blue": variant === "light",
                  "text-white": variant !== "light",
                  "leading-tight font-bold antialiased text-3xl md:text-5xl xl:text-6xl mb-6 lg:mb-10": true,
                })}
              >
                {content.heading[0].text}
              </h1>
              <div
                className={classNames({
                  "text-white": variant !== "light",
                  "rich-text text-xl": true,
                })}
              >
                <RichText content={content.subtitle} />
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
);

HeroSimple.defaultProps = {
  variant: "light",
  centered: false,
  gradient: null,
  topPadding: "pt-32",
};

HeroSimple.propTypes = {
  variant: PropTypes.string,
  centered: PropTypes.bool,
  gradient: PropTypes.string,
  topPadding: PropTypes.string,
};

export default HeroSimple;
