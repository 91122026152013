import { graphql, StaticQuery } from "gatsby";
import React, { useContext } from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import classNames from "classnames";
import { useUID } from "react-uid";
import { RichText, Button, Icon } from "@ui";
import { HeaderContext } from "@context";
import { Container } from "@layout";

import { PrismicLink } from "@utilities";
import { EmailSignup } from "@contact";

const query = graphql`
  query FooterQueryEs {
    prismic {
      site_settings(lang: "es-pr", uid: "site-settings") {
        footer_nav_first_row_label
        footer_nav_first_row {
          link_text
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
        footer_nav_second_row_label
        footer_nav_second_row {
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_text
        }
        social_links {
          icon
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
        footer_nav_third_row_label
        footer_nav_third_row {
          link_text
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
        fine_print {
          rich_text
        }
        email_signup {
          list_id
          label
          placeholder
          cta
          failure_message
          success_message
        }
        action_links {
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
        }
      }
    }
  }
`;

const template = data => {
  const footerData = data.prismic.site_settings;
  const { theme, blogPosts, isOnboarding, lang, enforceLang } = useContext(
    HeaderContext
  );
  const recentPosts = blogPosts && blogPosts.filter((val, i) => i < 4);
  const fractalColor = "gradient-fractal-light"; // `gradient-fractal-${theme}`;
  const useLogo = theme === "green" ? "LogoHeaderSunrise" : "Logo";
  const homeRoute = lang ? `/${lang}/home` : "/";
  // eslint-disable-next-line prefer-const
  let button_link_lang = footerData.action_links[0].button_link;
  let button_link_text_lang = footerData.action_links[0].button_text;
  if (lang) {
    button_link_lang._meta.lang = lang;
    button_link_text_lang = enforceLang
      ? `🇵🇷 Empezar`
      : footerData.action_links[0].button_text;
  }
  return (
    <footer
      className={`mt-auto bg-black ${fractalColor} xxs:px-3 px-6 md:px-0 pb-8 py-12 relative z-0`}
    >
      <Container>
        {/* Logo and CTAs */}
        <div className="justify-center flex flex-wrap md:justify-between mb-12">
          <TransitionLink
            to={theme === "green" ? `${homeRoute}?sunrise=lyra` : homeRoute}
            className="cursor-pointer md:order-1 w-1/2 md:w-auto"
          >
            <Icon
              iconName={useLogo}
              className="logo-inverse w-32 my-6 md:my-8 md:my-0 md:w-40 md:pr-8 md:pr-4 lg:pr-2 h-auto md:mb-4"
            />
          </TransitionLink>
          <div className="flex w-1/2 md:w-auto md:order-3 mt-2 items-center justify-end">
            {!isOnboarding && (
              <Button
                className="cursor-pointer py-2 pre-underline-2 border-3 border-blue"
                activeclass="opacity-100"
                bgClass="bg-blue"
                variant="sm"
                link={button_link_lang}
                hoverState={["bg-white", "text-blue"]}
              >
                {button_link_text_lang}
              </Button>
            )}
          </div>
          {/* nav */}
          <div className="w-full mt-5 -mb-4 md:-mb-2 md:w-2/3 xxs:justify-center xxs:text-sm md:order-2 grid md:grid-flow-col auto-cols-max">
            {footerData.footer_nav_first_row && (
              <nav className="block md:px-4 sm:px-0 py-1 text-white w-auto -mx-4 lg:mr-3 md:mr-2 xs:mr-1">
                {footerData.footer_nav_first_row_label && (
                  <h3 className="px-4 py-1 tracking-3 mb-2 text-sm sm:text-base font-bold text-white">
                    {footerData.footer_nav_first_row_label}
                  </h3>
                )}
                {footerData.footer_nav_first_row.map(item => {
                  const uuid = useUID();
                  return (
                    <PrismicLink
                      activeclass={classNames({
                        "text-white underline-2 footer-menu-link-active": true,
                      })}
                      className={classNames({
                        "block px-4 py-1 text-white md:w-auto text-sm sm:text-xs lg:text-md footer-menu-link pre-underline-2": true,
                        "hover:text-black !important": theme === "green",
                        "hover:text-white !important": theme !== "green",
                      })}
                      key={uuid}
                      link={item.link}
                    >
                      {item.link_text}
                    </PrismicLink>
                  );
                })}
              </nav>
            )}
            {footerData.footer_nav_second_row && (
              <nav className="block md:px-4 sm:px-0 py-1 text-white w-auto -mx-4 lg:mr-3 md:mr-2 xs:mr-1">
                {footerData.footer_nav_second_row_label && (
                  <h3 className="px-4 py-1 tracking-3 mb-2 text-sm sm:text-base font-bold text-white">
                    {footerData.footer_nav_second_row_label}
                  </h3>
                )}
                {footerData.footer_nav_second_row.map(item => {
                  const uuid = useUID();
                  return (
                    <PrismicLink
                      activeclass={classNames({
                        "text-white underline-2 footer-menu-link-active": true,
                      })}
                      className={classNames({
                        "block px-4 py-1 text-white md:w-auto text-sm sm:text-xs lg:text-md footer-menu-link pre-underline-2": true,
                        "hover:text-black !important": theme === "green",
                        "hover:text-white !important": theme !== "green",
                      })}
                      key={uuid}
                      link={item.link}
                    >
                      {item.link_text}
                    </PrismicLink>
                  );
                })}
              </nav>
            )}
            {footerData.footer_nav_third_row && (
              <nav className="block md:px-4 sm:px-0 py-1 text-white w-auto -mx-4 lg:mr-3 md:mr-2 xs:mr-1">
                {footerData.footer_nav_third_row_label && (
                  <h3 className="px-4 py-1 tracking-3 mb-2 text-sm sm:text-base font-bold text-white">
                    {footerData.footer_nav_third_row_label}
                  </h3>
                )}
                {footerData.footer_nav_third_row.map(item => {
                  const uuid = useUID();
                  return (
                    <PrismicLink
                      activeclass={classNames({
                        "text-white underline-2 footer-menu-link-active": true,
                      })}
                      className={classNames({
                        "block px-4 py-1 text-white md:w-auto text-sm sm:text-xs lg:text-md footer-menu-link pre-underline-2": true,
                        "hover:text-black !important": theme === "green",
                        "hover:text-white !important": theme !== "green",
                      })}
                      key={uuid}
                      link={item.link}
                    >
                      {item.link_text}
                    </PrismicLink>
                  );
                })}
              </nav>
            )}
            {recentPosts && (
              <nav className="block md:px-4 sm:px-0 py-1 text-white w-auto -mx-4 max-w-xs lg:mr-3 md:mr-2 xs:mr-1">
                <h3 className="px-4 py-1 tracking-3 mb-2 text-sm sm:text-base font-bold text-white">
                  From the blog
                </h3>
                <div>
                  <ul>
                    {recentPosts.map((post, i) => (
                      <li key={post.node.title} className="text-sm">
                        <PrismicLink
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          route={`/blog/${post.node._meta.uid}`}
                          activeclass={classNames({
                            "text-white underline-2 footer-menu-link-active": true,
                          })}
                          className={classNames({
                            "block px-4 py-1 text-white md:w-auto text-sm sm:text-xs lg:text-md footer-menu-link pre-underline-2": true,
                            "hover:text-white": theme !== "green",
                            "hover:text-black !important": theme === "green",
                          })}
                        >
                          {post.node.title}
                        </PrismicLink>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-5 text-sm">
                    <PrismicLink
                      // eslint-disable-next-line react/no-array-index-key
                      route="/blog"
                      activeclass={classNames({
                        "text-white underline-2 footer-menu-link-active": true,
                      })}
                      className={classNames({
                        "block px-4 py-1 text-white md:w-auto text-sm sm:text-xs lg:text-md footer-menu-link pre-underline-2": true,
                        "hover:text-black !important": theme === "green",
                        "hover:text-white !important": theme !== "green",
                      })}
                    >
                      Read more <span aria-hidden="true">&rarr;</span>
                    </PrismicLink>
                  </div>
                </div>
              </nav>
            )}
          </div>
        </div>
        {/* Footer Fine Print + Email Signup */}
        <div className="flex flex-wrap items-center justify-between my-12 text-white">
          <div
            className={`opacity-75 text-xs order-2 md:order-1 rich-text-minimal ${theme ===
              "green" && "dark-link"}`}
          >
            <RichText content={footerData.fine_print[0].rich_text} />
          </div>
          {/* Email Signup */}
          <div className="flex-grow order-1 md:order-2 mb-6 md:max-w-md">
            <EmailSignup content={footerData.email_signup[0]} />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default () => <StaticQuery query={`${query}`} render={template} />;
