/* eslint-disable react/prop-types */
import React from "react";

export default React.memo(({ content }) => {
  if (content.heading !== null) {
    return (
      <h2 className="font-bold text-2xl sm:text-3xl leading-none mb-6">
        {content.heading[0].text}
      </h2>
    );
  }
  return null;
});
