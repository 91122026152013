/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { HeaderContext } from "@context";
import { Hero, Container } from "@layout";
import { ShapesPatternRepeat } from "@svg";
import { RandomShapes } from "@decoration";
import {
  PageIntro,
  HighlightCard,
  NumberedSectionHeading,
  CarouselWithPreviews,
  AccordionWithImages,
  ThreeColumns,
  IconGrid,
  SectionHeading,
  EopCta,
  Testimonial,
  CTALarge,
  MetaData,
} from "@common";
import SupportedCodes from "../components/design/SupportedCodes";
import { PageTransition } from "@components";

const Design = props => {
  // disable eslint because the content is so deep
  // eslint-disable-next-line
  const { design } = props.data.prismic;
  const { setSunrisePage, setBlogLayout } = useContext(HeaderContext);
  useEffect(() => {
    setSunrisePage(false);
    setBlogLayout(false);
  }, []);
  return (
    <>
      <MetaData content={design} type="webpage" />
      <PageTransition theme="light">
        <article className="z-10 relative">
          <Hero content={design.hero[0]} size="sm" variant="light" />
          <PageIntro
            className="z-1 relative"
            content={design.intro[0]}
            media={design.intro_media_card[0]}
            curve
          />
          <HighlightCard
            className="z-10 relative"
            content={design.highlight_card[0]}
          />
          <section className="py-24 bg-blue-light ">
            <Container className="order-1 pt-3 md:py-12" variant="md">
              <NumberedSectionHeading
                className="mt-8 mb-4"
                content={design.carousel_heading[0]}
              />
            </Container>
            <CarouselWithPreviews content={design.feature_carousel} />
          </section>
          <section className="bg-white pb-24">
            <Container className="order-1 pt-3 md:py-12" variant="md">
              <NumberedSectionHeading
                className="mt-8 mb-4"
                content={design.accordion_heading[0]}
              />
            </Container>
            <AccordionWithImages content={design.feature_accordion} />
          </section>
          <section>
            <SupportedCodes
              content={design.code_support_section[0]}
              codes={design.supported_codes}
            />
          </section>
          {design.additional_features_content &&
            design.additional_features_content[0] &&
            (design.additional_features_content[0].heading[0].text ||
              design.additional_features_content[0].subheading[0].text) && (
              <section className="py-12 md:py-24 bg-blue-light">
                <Container>
                  <ThreeColumns
                    content={design.additional_features_content[0]}
                    columns={design.additional_features}
                  />
                </Container>
              </section>
            )}
          <section>
            <Container variant="copy">
              <SectionHeading
                className="mt-12 md:my-12 sm:mt-24"
                content={design.product_details_heading[0]}
                variant="sm"
              />
            </Container>
            <Container>
              <IconGrid content={design.product_details} />
            </Container>
          </section>
          <section className="relative">
            <div className="absolute inset-0 -z-10 bg-white" />
            <div className="absolute inset-0 flex items-center justify-center">
              <ShapesPatternRepeat
                className="opacity-3 text-blue stroke-current h-full w-full"
                dimensions={[10, 10]}
              />
            </div>
            <div className="relative flex w-full justify-center items-center order-last">
              <Container variant="heading">
                <CTALarge color="blue" content={design.cta_large[0]} />
                <RandomShapes
                  opacity=".1"
                  seed="2"
                  shapes={[
                    { Circle: "coral" },
                    { Square: "blue" },
                    { FourthCircle: "coral" },
                    { FourthCircle: "blue" },
                  ]}
                />
              </Container>
            </div>
          </section>
          <section className="bg-black">
            <Container className="mt-6 -mb-12 lg:-mb-24">
              <Testimonial content={design.testimonial[0]} />
            </Container>
            <Container variant="lg">
              <EopCta content={design.cta_eop[0]} background="bg-blue" />
            </Container>
            <div className="bg-black h-24 relative z-0" />
          </section>
        </article>
      </PageTransition>
    </>
  );
};

export default React.memo(Design);

export const query = graphql`
  query DesignQuery {
    prismic {
      design(lang: "en-us", uid: "design") {
        title
        meta_title
        meta_image
        meta_description
        hero {
          hero_heading
          hero_subheading
        }
        intro {
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          copy
          intro_image
          heading
          subheading
        }
        highlight_card {
          copy
          heading
          subheading
        }
        carousel_heading {
          heading
          subheading
          number
        }
        feature_carousel {
          description
          heading
          image
        }
        accordion_heading {
          heading
          subheading
          number
        }
        feature_accordion {
          description
          heading
          image
        }
        code_support_section {
          background_image
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          copy
          heading
          subheading
          number
          supported_codes_heading
        }
        supported_codes {
          code_description
          list_of_codes
        }
        additional_features {
          copy
          heading
          image
        }
        additional_features_content {
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          heading
          subheading
        }
        cta_large {
          background_image
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          heading
          copy
          icon
        }
        cta_eop {
          tracking_event
          heading
          subheading
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          button_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
        product_details_heading {
          heading
          subheading
        }
        product_details {
          copy
          heading
          icon
          link_text
          pdf {
            _linkType
            ... on PRISMIC__FileLink {
              url
            }
          }
        }
        testimonial {
          description
          full_name
          headshot
          quote
        }
        intro_media_card {
          embed_animation
          embed_code
          media_button_text
          media_description
          media_url
        }
      }
    }
  }
`;
