/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { HeaderContext } from "@context";
import { Hero, Container } from "@layout";
import { ShapesPatternRepeat } from "@svg";
import { RandomShapes } from "@decoration";
import {
  PageIntro,
  HighlightCard,
  AccordionWithImages,
  ThreeColumns,
  IconGrid,
  SectionHeading,
  EopCta,
  Testimonial,
  CTALarge,
  MetaData,
} from "@common";
import { PageTransition } from "@components";

const Permits = props => {
  // disable eslint because the content is so deep
  // eslint-disable-next-line react/destructuring-assignment
  const { permit_packages } = props.data.prismic;
  const { setSunrisePage, setBlogLayout } = useContext(HeaderContext);
  useEffect(() => {
    setSunrisePage(false);
    setBlogLayout(false);
  }, []);
  return (
    <>
      <MetaData content={permit_packages} type="webpage" />
      <PageTransition theme="light">
        <article className="z-10 relative">
          <Hero content={permit_packages.hero[0]} size="sm" variant="light" />
          <PageIntro
            className="z-1 relative"
            content={permit_packages.intro[0]}
            media={permit_packages.intro_media_card[0]}
          />
          <HighlightCard
            className="z-10 relative"
            content={permit_packages.highlight_card[0]}
          />
          <section className="bg-white pt-24 pb-24 relative z-0">
            <AccordionWithImages content={permit_packages.feature_accordion} />
          </section>
          {permit_packages.additional_features_content &&
            permit_packages.additional_features_content[0] &&
            (permit_packages.additional_features_content[0].heading[0].text ||
              permit_packages.additional_features_content[0].subheading[0]
                .text) && (
              <section className="py-12 md:py-24 bg-blue-light">
                <Container>
                  <ThreeColumns
                    content={permit_packages.additional_features_content[0]}
                    columns={permit_packages.additional_features}
                  />
                </Container>
              </section>
            )}
          <section>
            <Container variant="copy">
              <SectionHeading
                className="mt-12 md:my-12 sm:mt-24"
                content={permit_packages.product_details_heading[0]}
                variant="sm"
              />
            </Container>
            <Container className="pb-12 md:pb-24" variant="md">
              <IconGrid content={permit_packages.product_details} />
            </Container>
          </section>
          <section className="relative pb-10">
            <div className="absolute inset-0 -z-10 bg-white" />
            <div className="absolute inset-0 flex items-center justify-center">
              <ShapesPatternRepeat
                className="opacity-3 text-blue stroke-current h-full w-full"
                dimensions={[10, 10]}
              />
            </div>
            <div className="relative flex w-full justify-center items-center order-last">
              <Container variant="heading">
                <CTALarge color="blue" content={permit_packages.cta_large[0]} />
                <RandomShapes
                  opacity=".1"
                  shapes={[
                    { Circle: "coral" },
                    { Square: "blue" },
                    { FourthCircle: "coral" },
                    { FourthCircle: "blue" },
                  ]}
                />
              </Container>
            </div>
          </section>
          <section className="bg-black -mt-12">
            <Container className="mt-6 -mb-12 lg:-mb-24">
              <Testimonial content={permit_packages.testimonial[0]} />
            </Container>
            <Container variant="lg">
              <EopCta
                content={permit_packages.cta_eop[0]}
                background="bg-blue"
              />
            </Container>
            <div className="bg-black h-24 relative z-0" />
          </section>
        </article>
      </PageTransition>
    </>
  );
};

export default React.memo(Permits);

export const query = graphql`
  query PermitsEsQuery {
    prismic {
      permit_packages(lang: "es-pr", uid: "permits") {
        _meta {
          uid
          lang
          alternateLanguages {
            uid
            lang
          }
        }
        title
        meta_title
        meta_image
        meta_description
        additional_features {
          copy
          heading
          image
        }
        additional_features_content {
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          heading
          subheading
        }
        cta_eop {
          tracking_event
          heading
          subheading
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
        }
        cta_large {
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          background_image
          button_text
          copy
          heading
          icon
        }
        feature_accordion {
          description
          heading
          image
        }
        hero {
          hero_heading
          hero_subheading
        }
        highlight_card {
          copy
          heading
          subheading
        }
        intro {
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          heading
          intro_image
          copy
          subheading
        }
        product_details {
          copy
          heading
          icon
          link_text
          pdf {
            _linkType
            ... on PRISMIC__FileLink {
              url
            }
          }
        }
        product_details_heading {
          subheading
          heading
        }
        testimonial {
          description
          full_name
          headshot
          quote
        }
        intro_media_card {
          embed_animation
          embed_code
          media_button_text
          media_description
          media_url
          color
        }
      }
    }
  }
`;
