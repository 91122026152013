/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import pluralize from "pluralize";
import moment from "moment";
import { Container } from "@layout";
import { PrismicLink } from "@utilities";
import HeroShapes from "./Hero/HeroShapes";
import { Icon } from "@ui";
import { Headshot } from "@common";
import { SearchBox } from "@search";
import { detect } from "@utils";

const HeroPosts = React.memo(
  ({ content, variant, centered, noShapes, curve }) => {
    const { category } = content;
    const hasWindow = detect.browser().window;
    const bgImage = content.hero_background_image?.url
      ? {
          backgroundImage: `url(${content.hero_background_image?.url})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }
      : null;
    let iconName;
    if (category === "Case Studies") {
      iconName = "IconCaseStudies";
    } else if (category === "Events") {
      iconName = "IconEvents";
    } else if (category === "News") {
      iconName = "IconNews";
    } else if (category === "Insights") {
      iconName = "IconInsights";
    } else if (category === "Webinars") {
      iconName = "IconWebinars";
    } else if (category === "Permitting") {
      iconName = "IconPermitting";
    } else if (category === "Design") {
      iconName = "IconDesign";
    } else {
      iconName = "IconInsights";
    }

    const displayDate = content.published_date
      ? moment(content.published_date).format("MMMM Do YYYY")
      : moment(content._meta.firstPublicationDate).format("MMMM Do YYYY");

    return (
      <section
        className={classNames({
          "text-center": centered,
          "hero--posts-curve": curve,
          "bg-white text-black":
            variant === "light" && !content.hero_light_text,
          "text-white": variant !== "light" || content.hero_light_text,
          "hero--posts min-h-hero-sm pb-16 flex flex-col justify-center items-center relative shadow": true,
        })}
        style={bgImage}
      >
        {!bgImage && (
          <HeroShapes
            noShapes={noShapes}
            variant={variant}
            overflow="hidden"
            posts
          />
        )}
        <Container variant="full" className="mt-8 md:mt-0">
          <div className="z-10 relative w-full">
            <div className="mb-6">
              <PrismicLink
                link={{
                  url: `/blog`,
                }}
                className="inline-flex items-center"
              >
                <Icon iconName="LyraSun" className="w-5 h-5 mr-3" />
                <span className="uppercase text-sm font-bold tracking-1 underline-2-blue hover:border-black">
                  Blog
                </span>
              </PrismicLink>
              <span className="text-md font-bold tracking-1 mx-2">
                <strong> / </strong>
              </span>
              <PrismicLink
                link={{
                  url: `/blog/${category.replace(/\s+/g, "-").toLowerCase()}`,
                }}
                className="inline-flex items-center"
              >
                <Icon iconName={iconName} className="w-5 h-5 mr-3" />
                <span className="uppercase text-sm font-bold tracking-1 underline-2-blue hover:border-black">
                  {pluralize.singular(category)}
                </span>
              </PrismicLink>
            </div>
            <h1
              className={classNames({
                "text-black": variant === "light" || !content.hero_light_text,
                "text-white": variant !== "light" || content.hero_light_text,
                "leading-tight font-bold antialiased text-3xl md:text-4xl mb-6 lg:mb-10": true,
              })}
            >
              {content.title}
            </h1>
          </div>
          <div className="flex items-center text-xs">
            {content?.author?.linkedin_url ? (
              <a href={content?.author?.linkedin_url} target="_blank">
                <Headshot
                  src={content?.author?.photo?.url}
                  className="w-8 h-8 mr-3"
                />
              </a>
            ) : (
              <Headshot
                src={content?.author?.photo?.url}
                className="w-8 h-8 mr-3"
              />
            )}
            <span>
              {content?.author?.linkedin_url ? (
                <span>
                  <a
                    href={content?.author?.linkedin_url}
                    className="hover:underline"
                    target="_blank"
                  >
                    <span className="font-bold">
                      {content?.author?.full_name}
                    </span>
                  </a>
                </span>
              ) : (
                <span className="font-bold">{content?.author?.full_name}</span>
              )}
              {` on ${displayDate}`}
            </span>
          </div>
          <div className="mt-8 mx-8 w-auto block md:hidden">
            <div id="heroSearch" className="search--box" />
            {hasWindow && window && (
              <SearchBox
                className="block md:hidden"
                selector="heroSearch"
                limit={108}
                placeHolder="Search..."
              />
            )}
          </div>
        </Container>
      </section>
    );
  }
);

HeroPosts.defaultProps = {
  variant: "light",
  centered: false,
  curve: false,
};

HeroPosts.propTypes = {
  variant: PropTypes.string,
  centered: PropTypes.bool,
  curve: PropTypes.bool,
};

export default HeroPosts;
