/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container } from "@layout";
import Signup from "./Hero/Signup";
import Subheading from "./Hero/Subheading";
import HeroButton from "./Hero/HeroButton";
import HeroShapes from "./Hero/HeroShapes";

const Hero = ({
  content,
  variant,
  centered,
  size,
  signup,
  gradient,
  image,
}) => {
  const gradientColor = gradient && `gradient-${gradient}`;
  const bgImage = image?.url
    ? {
        backgroundImage: `url(${image?.url})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }
    : null;
  const slimHeader = !content?.hero_slim && "xl:py-24";
  if (content?.hero_heading != null) {
    return (
      <section
        className={classNames({
          "hero-small hero-sm": size === "xs",
          "bg-green": variant === "green",
          "bg-white": variant === "light",
          "bg-black": variant !== "green" && variant !== "light",
          "text-white":
            !content.hero_dark_text &&
            (variant === "green" || variant !== "light"),
          "text-black": content.hero_dark_text || variant === "light",
          [gradientColor]: gradientColor,
          "hero--page min-h-hero-mobile sm:min-h-hero-sm pt-32 pb-16 flex flex-col justify-center items-center relative": true,
          hidden: content.hero_hide,
        })}
        style={bgImage}
      >
        {!content.hero_hide_patterns && <HeroShapes variant={variant} />}
        <Container size={size}>
          <div className="flex flex-wrap items-center">
            <div
              className={`xxs:w-full w-6/7 sm:w-5/7 lg:pl-0 md:w-full xxs:px-0 py-6 ${slimHeader} sm:px-10 sm:p-0 order-2 sm:order-1 z-10 relative ${
                centered ? "text-center mx-auto" : ""
              }`}
            >
              <h1
                className={classNames({
                  "text-blue": variant === "light" && !content.hero_dark_text,
                  "text-white":
                    (variant === "green" || variant !== "light") &&
                    !content.hero_dark_text,
                  "text-black": content.hero_dark_text,
                  "leading-tight font-bold antialiased text-3xl md:text-5xl xl:text-6xl mb-6 lg:mb-10": true,
                })}
              >
                {content?.hero_heading[0].text}
              </h1>
              <Subheading content={content} />
              <div>
                <HeroButton
                  content={content}
                  color={content.hero_button_color}
                />
              </div>
              <div>
                <Signup signup={signup} />
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
  return (
    <section
      className={classNames({
        "bg-green text-white": variant === "green",
        "bg-white": variant === "light",
        "bg-blue text-white": variant === "green" && variant !== "light",
        "hero--page absolute inset-0 z-0 min-h-hero-mobile sm:min-h-hero-sm pt-32 pb-16 flex flex-col justify-center items-center": true,
      })}
    >
      <HeroShapes variant={variant} />
    </section>
  );
};

Hero.defaultProps = {
  size: "md",
  variant: "light",
  signup: [],
};

Hero.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  signup: PropTypes.array,
};

export default Hero;
