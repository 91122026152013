/* eslint-disable react/prop-types */
import React from "react";
import { Image } from "@ui";

const BlogImage = React.memo(({ image, className }) => {
  const { url } = image || {};

  if (url) {
    return (
      <Image
        src={url}
        height="h-auto xs:h-full sm:h-full md:h-auto md:rounded"
        className={className}
      />
    );
  }
  return null;
});

export default BlogImage;
