import React from "react";
import PropTypes from "prop-types";
import { RichText, ArrowLink } from "@ui";

import * as SVG from "@svg";
import { PrismicLink } from "@utilities";

const LargeCard = React.memo(({ content, color }) => {
  const Icon = SVG[content.icon];
  return (
    <PrismicLink
      link={content.link}
      className={`border-${color} block bg-white border-l-4 rounded-2xl h-full cursor-pointer shadow transition p-8 hover:translate-y-lift hover:shadow-lift`}
    >
      <div className={`text-${color} w-16 h-16`}>
        <Icon className="w-full h-full fill-current stroke-current" />
      </div>
      <h3 className="mt-6 mb-4 font-bold">{content.heading[0].text}</h3>
      <div className="rich-text leading-normal mb-6">
        <RichText content={content.copy} />
      </div>
      <div className="text-sm">
        <ArrowLink>{content.link_cta}</ArrowLink>
      </div>
    </PrismicLink>
  );
});

LargeCard.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
  color: PropTypes.string.isRequired,
};

export default LargeCard;
