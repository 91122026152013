import React from "react";
import PropTypes from "prop-types";
import { RichText, ArrowLink, Icon } from "@ui";

const HeaderTextColumn = React.memo(({ content, color }) => {
  return (
    <div className="py-12">
      <h3 className="flex items-center leading-tight text-2xl font-bold mb-4">
        <span className={`mr-4 w-12 h-12 text-${color}`}>
          <Icon className="w-12 h-12" iconName={content.icon} />
        </span>
        {content.column_heading[0].text}
      </h3>
      <div className="rich-text leading-normal mb-6">
        <RichText content={content.column_copy} />
      </div>
      <div>
        {content.column_link && (
          <ArrowLink link={content.column_link}>
            {content.column_link_cta}
          </ArrowLink>
        )}
      </div>
    </div>
  );
});

HeaderTextColumn.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object])
  ).isRequired,
  color: PropTypes.string.isRequired,
};

export default HeaderTextColumn;
