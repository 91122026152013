/* eslint-disable react/prop-types */
import React from "react";
import SeedRandom from "seedrandom";

export default React.memo(({ position }) => {
  const rn = new SeedRandom(`${position[0]}${position[1]}`);
  // convert position to translate offset
  const offset = `${position[0] * 40 + 3}, ${position[1] * 40 + 3}`;
  return (
    <g transform={`translate(${offset})`}>
      <path
        d="M0 40 V0 Q40 0 40 40 H40 V40z"
        className="rotate-sometimes"
        // starts each instance of the shape at a different point in the animation
        // get the 120 from the animation duration
        style={{ animationDelay: `-${rn() * 120}s` }}
      />
    </g>
  );
});
