/* eslint-disable no-else-return */
/* eslint-disable no-console */
const resolver = doc => {
  try {
    const { link_type, _linkType, type, __typename, path } = doc;
    // if richtext link
    if (link_type != null || type != null || __typename === "PRISMIC_Posts") {
      if (
        type === "posts" ||
        _linkType === "posts" ||
        __typename === "PRISMIC_Posts"
      ) {
        if (doc._meta != null) {
          return `/blog/${doc?._meta?.uid}`;
        }
        return `/blog/${doc.uid}`;
      } else if (
        type === "design" ||
        _linkType === "design" ||
        __typename === "PRISMIC_Design"
      ) {
        return `/design`;
      } else if (
        type === "permit_packages" ||
        _linkType === "permit_packages" ||
        __typename === "PRISMIC_Permit_packages"
      ) {
        return `/permits`;
      } else if (
        type === "for_manufacturers" ||
        _linkType === "for_manufacturers" ||
        __typename === "PRISMIC_For_manufacturers" ||
        type === "for_platforms" ||
        _linkType === "for_platforms" ||
        __typename === "PRISMIC_For_platforms"
      ) {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/${doc.uid}`;
      } else if (
        _linkType === "PRISMIC_Solar_economy" ||
        _linkType === "solar_economy" ||
        __typename === "PRISMIC_Solar_economy" ||
        __typename === "solar_economy"
      ) {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/${doc.uid}`;
      } else if (__typename === "PRISMIC_Pages" && path === "features") {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/${doc.uid}`;
      } else if (__typename === "PRISMIC_Pages" && path === "solutions") {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/solutions/${doc.uid}`;
      } else if (__typename === "PRISMIC_Pages" && path === "about") {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/${doc.uid}`;
      } else if (
        __typename === "PRISMIC_Pages" &&
        (path !== "products" && path !== "solutions" && path !== "about") &&
        path != null
      ) {
        if (doc._meta != null) {
          return `/${path}/${doc._meta.uid}`;
        }
        return `/${path}/${doc.uid}`;
      }
      return `/${doc.uid}`;
    }

    // if document link
    if (_linkType != null) {
      if (_linkType === "PRISMIC_Posts" || _linkType === "posts") {
        if (doc._meta != null) {
          return `/blog/${doc._meta.uid}`;
        }
        return `/blog/${doc.uid}`;
      } else if (
        _linkType === "PRISMIC_Design" ||
        _linkType === "design" ||
        __typename === "PRISMIC_Design"
      ) {
        return `/design`;
      } else if (
        _linkType === "PRISMIC_Permit_packages" ||
        _linkType === "permit_packages" ||
        __typename === "PRISMIC_Permit_packages"
      ) {
        return `/permits`;
      } else if (
        _linkType === "PRISMIC_For_manufacturers" ||
        _linkType === "for_manufacturers" ||
        __typename === "PRISMIC_For_manufacturers" ||
        _linkType === "PRISMIC_For_platforms" ||
        _linkType === "platforms" ||
        __typename === "PRISMIC_For_platforms"
      ) {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/${doc.uid}`;
      } else if (
        _linkType === "PRISMIC_Solar_economy" ||
        _linkType === "solar_economy" ||
        __typename === "PRISMIC_Solar_economy" ||
        __typename === "solar_economy"
      ) {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/${doc.uid}`;
      } else if (__typename === "PRISMIC_Pages" && path === "features") {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/${doc.uid}`;
      } else if (__typename === "PRISMIC_Pages" && path === "solutions") {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/${doc.uid}`;
      } else if (__typename === "PRISMIC_Pages" && path === "about") {
        if (doc._meta != null) {
          return `/${doc._meta.uid}`;
        }
        return `/${doc.uid}`;
      } else if (
        __typename === "PRISMIC_Pages" &&
        (path !== "products" && path !== "solutions" && path !== "about") &&
        path != null
      ) {
        if (doc._meta != null) {
          return `/${path}/${doc._meta.uid}`;
        }
        return `/${path}/${doc.uid}`;
      }
      if (doc._meta != null) {
        return `/${doc._meta.uid}`;
      }
      return `/${doc.uid}`;
    }
    if (doc._meta != null) {
      return `/${doc._meta.uid}`;
    }
    return `/${doc.uid}`;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default resolver;
