/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import { navigate } from "@reach/router";

/* common utilities */
const sunset = (location, previousPath) => {
  // See if we're passing embed details and if so, pass them to the emebd url
  const { origin, search, pathname, host, hostname } = location;
  const urlParams = new URLSearchParams(search);
  const hasParams = !!urlParams;

  // Set up constants from env so they're changeable. Set defaults as backup
  const domainDef =
    process.env.GATSBY_SUNSET_DOMAIN || "get.solardesigntool.com";
  const pageDef = "/solardesigntool-is-now-lyra";
  const sunsetOriginDef = "https://get.solardesigntool.com";

  // Set the variables for the sunrise/sunset options... whether or not to load a sunrise page, redirect from a sunset visit, etc
  const domain =
    host === domainDef ||
    hostname === domainDef ||
    host === "get.solardesigntool.com";
  const page = pathname === pageDef;
  const sunsetOrigin =
    origin === sunsetOriginDef || origin === "http://get.solardesigntool.com";
  const sunriseOrigin = previousPath === null;
  const sunRisen = hasParams && urlParams.get("sunrise");
  const utms =
    page &&
    hasParams &&
    urlParams.get("utm_campaign") === "sdt-sunset" &&
    urlParams.get("utm_term") === "lyra-sunrise" &&
    urlParams.get("utm_content") === "solardesigntool-is-now-lyra" &&
    urlParams.get("utm_medium") === "web" &&
    urlParams.get("utm_source") === "sdt";
  const sunrise = domain || page || utms;
  const sunsetRedirect = previousPath === null && pathname === "/" && !sunRisen;
  const newVisit =
    !sunRisen &&
    domain &&
    sunriseOrigin &&
    sunsetOrigin &&
    pathname === "/" &&
    !sunrise;
  const route = pageDef;
  const loginUrl =
    process.env.GATSBY_SUNSET_APP_URL || "https://go.lyra.solar/sdt";

  // test with: ?utm_campaign=sdt-sunset&utm_medium=web&utm_source=sdt&utm_term=lyra-sunrise&utm_content=solardesigntool-is-now-lyra
  // console.log("loadSDT:location", location);
  /*
  console.log("loadSDT.sunriseOrigin", sunriseOrigin);
  console.log("loadSDT.sunsetOrigin", sunsetOrigin);
  console.log("loadSDT.previousPath", previousPath);
  console.log("loadSDT:newVisit", newVisit);
  console.log("loadSDT:origin", origin);
  console.log("loadSDT:sunset", {
    sunrise,
    domain,
    page,
    utms,
    sunset: newVisit,
    route,
  });
  */

  return {
    sunrise,
    domain,
    page,
    utms,
    sunset: sunsetRedirect,
    newVisit,
    route,
    loginUrl,
  };
};

const sunrise = hasWindow => {
  const route = "/solardesigntool-is-now-lyra";
  if (hasWindow && window) {
    console.log("sun is rising");
    setTimeout(() => {
      // window.location.replace(route);
      navigate(route);
    }, 555);
  }
};

const loadSDT = {
  sunset,
  sunrise,
};

export default loadSDT;
