const postsSearchQuery = `
  query BlogSearchQuery() {
    prismic {
      posts(lang: "en-us") {
        title
        published_date
        meta_title
        meta_description
        body {
          ... on PRISMIC_PostsBodyCopy_block {
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_PostsBodyPull_quote_block {
            label
            primary {
              citation
              quote
            }
          }
          ... on PRISMIC_PostsBodyLead_copy_block {
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_PostsBodyEmail_signup_block {
            type
            label
            primary {
              background_image
              failure_message
              heading
              submit_button_text
              submit_button_color
              icon
              subtitle
              success_message
              list_id
              tracking_event
              promo_code
              promo_memo
              require_phone_number
              show_phone_number
            }
          }
        }
        category
        author {
          ... on PRISMIC_People {
            full_name
          }
        }
        title
        _meta {
          uid
        }
      }
    }
  }
`;

const blogSearchQuery = `
  query BlogSearchQuery() {
    prismic {
      blog(lang: "en-us", uid: "blog") {
        _meta {
          uid
        }
        title
        meta_title
        meta_description
        hero {
          hero_heading
          hero_subheading
        }
        cta_eop {
          heading
          subheading
          button_text
          arrow_link_text
        }
        body {
          ... on PRISMIC_BlogBodyCopy_block {
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_BlogBodyPull_quote_block {
            label
            primary {
              citation
              quote
            }
          }
          ... on PRISMIC_BlogBodyLead_copy_block {
            label
            primary {
              copy
            }
          }
        }
      }
    }
  }
`;

const homeSearchQuery = `
  query HomeSearchQuery {
    prismic {
      home(lang: "en-us", uid: "home") {
        title
        meta_title
        meta_description
        overview_heading {
          heading
          subheading
        }
        hero {
          hero_copy
          hero_heading
        }
        design_description {
          column_copy
          column_heading
        }
        benefits_description {
          column_copy
          column_heading
        }
        benefits_cards {
          copy
        }
        permits_description {
          column_copy
          column_heading
        }
        testimonial {
          description
          full_name
          quote
        }
        collaborate_heading {
          heading
          subheading
        }
        collaborate_cards {
          copy
          heading
        }
      }
    }
  }
`;

const pageSearchQuery = `
  query PageSearchQuery() {
    prismic {
      pages(lang: "en-us") {
        _meta {
          uid
        }
        path
        title
        meta_title
        meta_description
        body {
          ... on PRISMIC_PagesBodyCopy_block {
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_PagesBodyPull_quote_block {
            type
            label
            primary {
              citation
              quote
            }
          }
          ... on PRISMIC_PagesBodyLead_copy_block {
            type
            label
            primary {
              copy
            }
          }
        }
        title
        hero {
          hero_button_text
          hero_heading
          hero_copy
        }
      }
    }
  }
`;

const pricingSearchQuery = `
  query PricingSearchQuery {
    prismic {
      pricing(lang: "en-us", uid: "pricing") {
        title
        meta_title
        meta_description
        _meta {
          id
        }
        hero {
          heading
          copy
        }
        hero_details {
          copy
        }
        permit_package_pricing {
          copy
          lead_copy
          heading
        }
        why_lyra {
          bullets
          subheading
          heading
          icon
        }
      }
    }
  }
`;

const permitsSearchQuery = `
  query PermitsSearchQuery {
    prismic {
      permit_packages(lang: "en-us", uid: "permit-packages") {
        title
        meta_title
        meta_description
        additional_features {
          copy
          heading
        }
        additional_features_content {
          heading
          subheading
        }
        cta_large {
          copy
          heading
        }
        feature_accordion {
          description
          heading
        }
        hero {
          hero_heading
          hero_subheading
        }
        highlight_card {
          copy
          heading
          subheading
        }
        intro {
          heading
          copy
          subheading
        }
        product_details {
          copy
          heading
        }
        product_details_heading {
          subheading
          heading
        }
        testimonial {
          description
          full_name
          quote
        }
      }
    }
  }
`;

const manufacturersSearchQuery = `
  query ManufacturersSearchQuery {
    prismic {
      for_manufacturers(lang: "en-us", uid: "manufacturers") {
        title
        meta_title
        meta_description
        hero {
          heading
          subtitle
        }
        intro {
          copy
          heading
          subheading
        }
        manufacturer_testimonials {
          company
          full_name
          quote
        }
        product_details_heading {
          heading
          subheading
        }
        product_details {
          copy
          heading
        }
      }
    }
  }
`;

const designSearchQuery = `
  query DesignSearchQuery {
    prismic {
      design(lang: "en-us", uid: "system-design") {
        title
        meta_title
        meta_description
        hero {
          hero_heading
          hero_subheading
        }
        intro {
          copy
          heading
          subheading
        }
        highlight_card {
          copy
          heading
          subheading
        }
        carousel_heading {
          heading
          subheading
        }
        feature_carousel {
          description
          heading
        }
        accordion_heading {
          heading
          subheading
        }
        feature_accordion {
          description
          heading
        }
        code_support_section {
          copy
          heading
          subheading
          supported_codes_heading
        }
        supported_codes {
          code_description
          list_of_codes
        }
        additional_features {
          copy
          heading
        }
        additional_features_content {
          heading
          subheading
        }
        cta_large {
          heading
          copy
        }
        product_details_heading {
          heading
          subheading
        }
        product_details {
          copy
          heading
          link_text
        }
        testimonial {
          description
          full_name
          quote
        }
      }
    }
  }
`;

const aboutSearchQuery = `
  query AboutSearchQuery {
    prismic {
      about(lang: "en-us", uid: "about") {
        title
        meta_title
        meta_description
        careers {
          copy
          heading
          positions_heading
          subheading
        }
        hero {
          heading
          subtitle
        }
        positions {
          position_title
        }
        team {
          team_member {
            ... on PRISMIC_People {
              full_name
              job_title
              description
              linkedin_url
            }
          }
        }
        team_heading {
          heading
          subheading
        }
        title_card {
          caption
        }
        value_cards {
          caption
          heading
        }
      }
    }
  }
`;

const queries = [
  // Blog index
  {
    query: postsSearchQuery,
    queryVariables: {}, // optional. Allows you to use graphql query variables in the query
    transformer: ({ data }) => data.pages.nodes, // optional
    indexName: process.env.ALGOLIA_BLOG_INDEX_NAME || "lyra_blog", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
  },
  {
    query: blogSearchQuery,
    queryVariables: {}, // optional. Allows you to use graphql query variables in the query
    transformer: ({ data }) => data.pages.nodes, // optional
    indexName: process.env.ALGOLIA_BLOG_INDEX_NAME || "lyra_blog", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
  },
  // Site index
  {
    query: homeSearchQuery,
    queryVariables: {}, // optional. Allows you to use graphql query variables in the query
    transformer: ({ data }) => data.pages.nodes, // optional
    indexName: process.env.ALGOLIA_SITE_INDEX_NAME || "lyra_site", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
  },
  {
    query: pageSearchQuery,
    queryVariables: {}, // optional. Allows you to use graphql query variables in the query
    transformer: ({ data }) => data.pages.nodes, // optional
    indexName: process.env.ALGOLIA_SITE_INDEX_NAME || "lyra_site", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
  },
  {
    query: pricingSearchQuery,
    queryVariables: {}, // optional. Allows you to use graphql query variables in the query
    transformer: ({ data }) => data.pages.nodes, // optional
    indexName: process.env.ALGOLIA_SITE_INDEX_NAME || "lyra_site", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
  },
  {
    query: permitsSearchQuery,
    queryVariables: {}, // optional. Allows you to use graphql query variables in the query
    transformer: ({ data }) => data.pages.nodes, // optional
    indexName: process.env.ALGOLIA_SITE_INDEX_NAME || "lyra_site", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
  },
  {
    query: manufacturersSearchQuery,
    queryVariables: {}, // optional. Allows you to use graphql query variables in the query
    transformer: ({ data }) => data.pages.nodes, // optional
    indexName: process.env.ALGOLIA_SITE_INDEX_NAME || "lyra_site", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
  },
  {
    query: designSearchQuery,
    queryVariables: {}, // optional. Allows you to use graphql query variables in the query
    transformer: ({ data }) => data.pages.nodes, // optional
    indexName: process.env.ALGOLIA_SITE_INDEX_NAME || "lyra_site", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
  },
  {
    query: aboutSearchQuery,
    queryVariables: {}, // optional. Allows you to use graphql query variables in the query
    transformer: ({ data }) => data.pages.nodes, // optional
    indexName: process.env.ALGOLIA_SITE_INDEX_NAME || "lyra_site", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
  },
];

export default queries;
