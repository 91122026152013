/* eslint-disable jsx-a11y/accessible-emoji */
/* 

See AppHeader for layout logic.

*/

import React, { useState, useContext } from "react";
import { StaticQuery, graphql } from "gatsby";
import { useCookies } from "react-cookie";
import {
  ConsentManager,
  openConsentManager,
  doNotTrack,
  // onPreferencesSaved,
} from "@segment/consent-manager";
// import inEU from "@segment/in-eu"; // optional for trigger rules for location of gdpr
import { PrismicLink } from "@utilities";
import { HeaderContext } from "@context";
import { Button, RichText as RichTextComponent } from "@ui";
import { Container } from "@layout";
import * as hubspot from "@hubspot";

/* eslint react/prop-types: 0 */

const query = graphql`
  query GDPRQueryEs {
    prismic {
      site_settings(lang: "es-pr", uid: "site-settings") {
        gdpr_button_text
        gdpr_copy
        consent_banner_content
        consent_banner_sub_content
        consent_preferences_dialog_title
        consent_preferences_dialog_content
        consent_cancel_dialog_title
        consent_cancel_dialog_content
        consent_banner_background_color
        consent_banner_show_actions
        consent_preferences_default_essentials
        consent_preferences_default_advertising
        consent_preferences_default_marketing
        consent_preferences_default_functional
        consent_other_write_keys {
          write_key
        }
      }
    }
  }
`;

const bannerContent = (
  <span>
    Utilizamos cookies (y otras tecnologías similares) para recopilar datos para
    mejorar su experiencia en nuestro sitio. Al utilizar nuestro sitio web,
    usted acepta las recopilación de datos como se describe en nuestra{" "}
    <PrismicLink
      route="/data-collection-policy/"
      className="block p-6 md:px-12 md:py-12 bg-white shadow my-12 text-center transition hover:translate-y-lift hover:shadow-lift"
    >
      Política de recopilación de datos
    </PrismicLink>
    .
  </span>
);
const bannerSubContent = "You can change your preferences at any time.";
const preferencesDialogTitle = "Data Collection Preferences";
const preferencesDialogContent =
  "Utilizamos cookies (y otras tecnologías similares) para recopilar datos y mejorar su experiencia en nuestros sitios web y aplicaciones. Al utilizar nuestros sitios web y aplicaciones, usted acepta la recopilación de datos. como se describe en nuestra Política de recopilación de datos.";
const cancelDialogTitle = "Are you sure you want to cancel?";
const cancelDialogContent =
  "Tus preferencias no se han guardado. Al continuar utilizando nuestro(s) sitio(s) web y aplicaciones, usted acepta nuestra Política de recopilación de datos.";

const ConsentBanner = ({
  data,
  preferences,
  setShowConsentManager,
  location,
}) => {
  const {
    // consent_banner_content,
    consent_banner_sub_content,
    consent_preferences_dialog_title,
    consent_preferences_dialog_content,
    consent_cancel_dialog_title,
    consent_cancel_dialog_content,
    consent_banner_background_color,
    consent_banner_show_actions,
    consent_preferences_default_essentials,
    consent_preferences_default_advertising,
    consent_preferences_default_marketing,
    consent_preferences_default_functional,
    consent_other_write_keys,
  } = data.prismic.site_settings;

  const initialPreferences = {
    advertising: consent_preferences_default_advertising || false,
    marketingAndAnalytics: consent_preferences_default_marketing || true,
    functional: consent_preferences_default_functional || true,
    essential: consent_preferences_default_essentials || true,
  };

  const preferencesDialogContentOutput =
    consent_preferences_dialog_content || preferencesDialogContent;

  // List all relevant Segment sources where this consent should be used.
  // Note: Appliction consent is provided once a user creates an account at app.lyrasolar.com
  const otherWriteKeys = consent_other_write_keys.map(item => {
    return item.write_key;
  });
  const consentOtherWriteKeys =
    otherWriteKeys && otherWriteKeys.length >= 1
      ? otherWriteKeys
      : [
          "tC6Q3jILzoaMBHFR5QC5NFerW8puVxBj", // live ui client
          "roIEEGgfnMWyaxSv6E8D0KT4wLmBLCFV", // live design tool
          "3FSfLl93BpNB8IBq0Tqb3LXCIjwB7HHI", // next
          "Z6jsol4FTf3pksh3Neqz7OkRQTsjK2XD", // developer hub
          "ddaueYQwRo6OjVBpJk2u46HYivMugFaL", // netlify functions
          "Di4Kj21y0YW5wJ1GB846d7S5ERTgH68m", // test key
        ];

  const writeKey =
    process.env.GATSBY_ANALYTICS_ENV === "production"
      ? process.env.GATSBY_SEGMENT_ID
      : process.env.GATSBY_SEGMENT_DEV_ID;

  const [error, setError] = useState(null);
  const consentAction = () => {
    const action = error ? () => hubspot.showConsent() : openConsentManager;
    return action;
  };

  const bannerActionsBlock = ({ acceptAll, denyAll, changePreferences }) => (
    <div>
      <Button
        className="text-sm disabled:bg-blue disabled:opacity-50 disabled:cursor-default mr-2"
        textClass="text-white"
        bgClass="bg-blue"
        onClick={acceptAll}
        variant="xs"
        rounded="rounded-sm"
        button
      >
        Permitir todo
      </Button>
      <Button
        className="text-sm disabled:bg-blue disabled:opacity-50 disabled:cursor-default mr-2"
        textClass="text-white"
        bgClass="bg-success"
        onClick={denyAll}
        variant="xs"
        rounded="rounded-sm"
        button
      >
        Negar todo
      </Button>
      <Button
        className="text-sm disabled:bg-blue disabled:opacity-50 disabled:cursor-default"
        textClass="text-white"
        bgClass="bg-black"
        onClick={changePreferences}
        variant="xs"
        rounded="rounded-sm"
        button
      >
        Personalizar
      </Button>
    </div>
  );

  const ConsentPreferences = () => {
    return (
      <Button
        id="hs_show_banner_button"
        className="w-full py-6 hover:bg-black bg-blue text-lg disabled:bg-blue disabled:opacity-50 disabled:cursor-default mr-2 fixed bottom-0 left-0 right-0 items-center justify-center"
        textClass="text-white"
        bgClass="bg-black"
        onClick={consentAction()}
        variant="xs"
        rounded="rounded-sm"
        button
      >
        <span role="img">🛡️</span>
        <span className="hover:underline">
          Actualizar preferencias de recopilación de datos
        </span>
      </Button>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const handleError = e => {
    // eslint-disable-next-line no-console
    console.log("Ad blocker enabled");
    setShowConsentManager(false);
    setError(true);
    hubspot.load(location);
  };

  return (
    <div className="data-consent-manager-wrapper fixed bottom-0 left-0 right-0 items-center justify-center">
      <ConsentManager
        bannerActionsBlock={!!consent_banner_show_actions && bannerActionsBlock}
        bannerBackgroundColor={consent_banner_background_color || `#000000`}
        bannerContent={bannerContent}
        bannerSubContent={consent_banner_sub_content || bannerSubContent}
        cancelDialogTitle={consent_cancel_dialog_title || cancelDialogTitle}
        cancelDialogContent={
          consent_cancel_dialog_content || cancelDialogContent
        }
        closeBehavior={() => ({
          ...initialPreferences,
          advertising: false,
        })}
        cookieName="lyraConsentManager"
        // cookieDomain={cookieDomain}
        defaultDestinationBehavior="imply"
        implyConsentOnInteraction
        initialPreferences={initialPreferences}
        preferencesDialogTitle={
          consent_preferences_dialog_title || preferencesDialogTitle
        }
        preferencesDialogContent={preferencesDialogContentOutput}
        // shouldRequireConsent={inEU}
        writeKey={writeKey}
        otherWriteKeys={consentOtherWriteKeys}
        onError={handleError}
      />
      {preferences && <ConsentPreferences />}
    </div>
  );
};

const CustomBanner = ({ data, hideBanner, setHideBanner, setCookie }) => {
  const { setAllowCookies } = useContext(HeaderContext);
  const { gdpr_copy, gdpr_button_text } = data.prismic.site_settings;
  const enable = () => {
    setCookie(
      "lyraAllowCookies",
      true,
      { maxAge: 60 * 60 * 24 * 365 } // expires in 1 year
    );
    setHideBanner(true);
    setAllowCookies(true);
    hubspot.showConsent();
  };

  return hideBanner ? (
    <></>
  ) : (
    <div className="fixed bottom-0 left-0 right-0 flex items-center justify-center py-4 md:py-6 bg-black">
      <Container className="flex items-center" variant="sm">
        <div className="flex-1 mr-4 text-white">
          <RichTextComponent content={gdpr_copy} />
        </div>
        <Button
          onClick={() => enable()}
          bgClass="bg-white"
          textClass="text-teal"
        >
          {gdpr_button_text}
        </Button>
      </Container>
    </div>
  );
};

// and export as a static query
export default React.memo(
  ({
    tracking,
    preferences,
    showConsentManager,
    setShowConsentManager,
    consentCookieExists,
    location,
  }) => {
    const [cookie, setCookie] = useCookies(["lyraAllowCookies"]);
    const showConsent = showConsentManager || consentCookieExists;
    const hideBanner =
      !!showConsentManager || cookie?.lyraAllowCookies === "true";
    const [showBanner, setShowBanner] = useState(!hideBanner);
    const [dnr] = useState(doNotTrack());
    // const [inEu] = useState(inEU());
    return (
      <StaticQuery
        query={`${query}`}
        render={data =>
          // eslint-disable-next-line no-nested-ternary
          showConsent ? (
            <ConsentBanner
              data={data}
              tracking={tracking}
              preferences={preferences}
              setShowConsentManager={setShowConsentManager}
              location={location}
              dnr={dnr}
              // inEu={inEu}
            />
          ) : (
            showBanner && (
              <CustomBanner
                cookie={cookie}
                setCookie={setCookie}
                data={data}
                setShowBanner={setShowBanner}
              />
            )
          )
        }
      />
    );
  }
);
