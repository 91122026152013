import React from "react";
// import { handleAuthentication } from "../auth/service";

const Callback = () => {
  // handleAuthentication();

  return <></>;
};

export default Callback;
