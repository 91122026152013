/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import ImgixClient from "imgix-core-js";
import PropTypes from "prop-types";
import { uid, useUID } from "react-uid";
import LazyImage from "./Image/LazyImage";
import Caption from "./Image/Caption";
import LQIP from "./Image/LQIP";
import { detect } from "@utils";

const Image = React.memo(
  ({
    src,
    fit,
    ixparams,
    background,
    caption,
    transparent,
    className,
    style,
    shadow,
    aspectratio,
    height: figureHeight,
  }) => {
    const hasWindow = detect.browser().window;
    const client = new ImgixClient({
      domain: process.env.GATSBY_IMGIX_DOMAIN,
      secureURLToken: process.env.GATSBY_IMGIX_TOKEN,
    });
    const defaultParams = {
      auto: "compress,format",
      fit: "max",
      crop: "faces,edges,entropy",
    };
    const imageUid = useUID();
    // eslint-disable-next-line no-unused-vars
    const [dimensions, setDimensions] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [HQI, setHQI] = useState([]);
    // const [cached, setCached] = useState(true);
    const figureRef = useRef({});
    const arm =
      aspectratio !== null
        ? aspectratio.split(":")[1] / aspectratio.split(":")[0]
        : null;
    // get pixel device ratio
    const defaultPdr = 1.33;
    const pdr = () => {
      if (typeof window !== `undefined`) {
        if (window.devicePixelRatio > defaultPdr) {
          return window.devicePixelRatio;
        }
        return defaultPdr;
      }
      return defaultPdr;
    };
    const resMultiplier = pdr();

    let timeout;
    let dur = 0;
    // eslint-disable-next-line no-unused-vars
    const maxWidth = 3000;

    // set up image attributes, including width/height from `dimensions` state obj
    const { width, height } = dimensions;
    const urlParams = { ...defaultParams, ...ixparams };

    // const handleLoad = () => {
    //   setLoaded(true);
    // };

    // get dominant colors from image
    const LQIPBackgroundColor = ref => {
      const LQIPBackgroundColorUrl = client.buildURL(src, {
        colors: 0,
        palette: "json",
      });
      // set background color to dominant dark color in image for better blur results
      fetch(LQIPBackgroundColorUrl)
        .then(response => response.json())
        .then(data => {
          if (figureRef.current != null && transparent !== true) {
            // eslint-disable-next-line no-param-reassign
            ref.current.style.backgroundColor =
              data.dominant_colors.muted_dark != null
                ? `${data.dominant_colors.muted_dark.hex}`
                : `${data.dominant_colors.muted.hex}`;
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.warn("Had to use default bg");
        });
    };

    // TODO: Better Resizing functionality

    // disable load-in transition if image loads in 1/10th of a second
    // useEffect(() => {
    //   setTimeout(() => {
    //     setCached(false);
    //   }, 100);
    // }, []);

    useEffect(() => {
      // get background color
      if (!transparent) {
        LQIPBackgroundColor(figureRef);
      }
      // get a new picture on resize
      const handleResize = () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          let w;
          let h;
          const lqip = document.getElementById(`lqip_${imageUid}`);
          if (lqip && hasWindow) {
            w = lqip.clientWidth;
            h = lqip.clientHeight;
            const ratio = width / height;
            if (width > maxWidth) {
              w = maxWidth;
              h = maxWidth / ratio;
            }
            if (height > maxWidth) {
              h = maxWidth;
              w = maxWidth * ratio;
            }
          } else {
            w = 2000;
            h = aspectratio !== null ? 2000 * arm : 2000;
          }

          if (w !== dimensions.width || h !== dimensions.height) {
            setHQI(prevState => [[{ w, h }], ...prevState]);
            setDimensions({ width: w, height: h });
          }
          dur = 250;
        }, dur);
      };
      // imgRef.current.addEventListener("load", handleLoad());
      handleResize();
      // TODO: Fix this
      window.addEventListener("resize", handleResize, false);
      // Return func in `useEffect` is called when the component unmounts
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    // build Low Quailty Image Placeholder

    // TODO: Until we sort out why the HQI array isn't populating correctly,
    // we're just getting the first item in that array if it exists.
    let ixurl;
    if (HQI[0]) {
      ixurl = client.buildURL(src, {
        ...urlParams,
        w: (HQI[0].w * resMultiplier).toFixed(0),
        h: (HQI[0].h * resMultiplier).toFixed(0),
      });
    }

    const noScriptUrl = client.buildURL(src, {
      ...urlParams,
      w: 2000,
      h: aspectratio !== null ? 2000 * arm : 2000,
    });

    const LQIPUrl = client.buildURL(src, {
      ...urlParams,
      w: 100,
      h: aspectratio !== null ? 100 * arm : 100,
      blur: 20,
      con: 25,
      colorquant: 32,
    });

    return (
      <figure
        className={`${className} ${figureHeight} ${classNames({
          loaded,
          "absolute inset-0 z-0": background,
          relative: !background,
          "overflow-hidden": !transparent,
          "w-full transition": true,
        })}`}
        style={style}
      >
        <div ref={figureRef} className="relative w-full h-full">
          {HQI.map((image, key) => {
            const imageUrl = client.buildURL(src, {
              ...urlParams,
              w: (image[0].w * resMultiplier).toFixed(0),
              h: (image[0].h * resMultiplier).toFixed(0),
            });
            return (
              <LazyImage
                key={uid(key) + 1}
                src={src}
                ixurl={imageUrl}
                fit={fit}
                shadow={shadow}
                loaded={loaded}
                onLoadImage={() => {
                  if (!loaded) {
                    setLoaded(true);
                  } else {
                    setHQI(() => [HQI[0]]);
                  }
                }}
              />
            );
          })}
          <noscript>
            <LazyImage
              key={uid(ixurl + 1)}
              src={src}
              ixurl={noScriptUrl}
              fit={fit}
              shadow={shadow}
              loaded
            />
          </noscript>
          <LQIP
            LQIPUrl={LQIPUrl}
            src={src}
            fit={fit}
            imageUid={imageUid}
            loaded={hasWindow ? loaded : true}
            shadow={shadow}
          />
        </div>
        <Caption content={caption} />
      </figure>
    );
  }
);

export default Image;

Image.propTypes = {
  transparent: PropTypes.bool,
  src: PropTypes.string.isRequired,
  fit: PropTypes.string,
  ixparams: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  // eslint-disable-next-line react/forbid-prop-types
  caption: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  background: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  shadow: PropTypes.bool,
  aspectratio: PropTypes.string,
  height: PropTypes.string,
};

Image.defaultProps = {
  fit: "contain",
  ixparams: {},
  caption: {},
  transparent: false,
  background: false,
  className: "",
  style: null,
  shadow: false,
  aspectratio: null,
  height: "h-full",
};
