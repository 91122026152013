/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import { useUID } from "react-uid";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import classNames from "classnames";
import { Previous } from "@svg";
import Item from "./Carousel/Item";

const ImageCarousel = React.memo(({ content }) => {
  const Carousel = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const onSlideChanged = e => {
    setCurrentSlide(e.item);
  };
  return (
    <>
      <div className="absolute top-0 bottom-0 left-0 flex items-center pl-3 z-10">
        <button
          type="button"
          className={classNames({
            "opacity-0": currentSlide === 0,
            "w-12 h-12 pr-px rounded-md hover:scale-1.25 cursor-pointer transition-fast text-white bg-black flex flex-col items-center justify-center text-6xl": true,
          })}
          onClick={() => {
            Carousel.current.slidePrev();
          }}
        >
          <Previous />
        </button>
      </div>
      <div className="image-carousel__container relative z-0">
        <div className="image-carousel">
          <AliceCarousel
            ref={Carousel}
            buttonsDisabled
            dotsDisabled
            duration={200}
            slideToIndex={currentSlide}
            infinite={false}
            onSlideChanged={e => onSlideChanged(e)}
          >
            {content.map(slide => {
              const uid = useUID();
              return <Item key={uid} slide={slide} />;
            })}
          </AliceCarousel>
        </div>
      </div>
      <div className="absolute top-0 bottom-0 right-0 flex items-center pr-3 z-10">
        <button
          type="button"
          className={classNames({
            "opacity-0": currentSlide === content.length - 1,
            "-scale-1 w-12 h-12 pr-px rounded-md hover:-scale-1.25 cursor-pointer transition-fast text-white bg-black flex flex-col items-center justify-center text-6xl": true,
          })}
          onClick={() => {
            Carousel.current.slideNext();
          }}
        >
          <Previous />
        </button>
      </div>
    </>
  );
});

export default ImageCarousel;
