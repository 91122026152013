import React from "react";
import PropTypes from "prop-types";
import { RichText } from "@ui";

import { Container } from "@layout";

const CopyBlock = ({ content, className, variant }) => {
  return (
    <Container variant={variant}>
      <div className={`rich-text leading-normal ${className}`}>
        <RichText content={content.primary.copy} />
      </div>
    </Container>
  );
};

CopyBlock.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

CopyBlock.defaultProps = {
  className: "",
  variant: "copy",
};

export default CopyBlock;
