// add non-breaking-space between last two words of text that is longer than
// 3 words and the last word is less than 13 characters and the second-to-last
// word is less than 11 characters. if the penultimate word is less than 5
// characters and the last word is less than 9, then the two words before the widow will be pulled down.

const unWidower = () => {
  if (typeof window !== "undefined") {
    document
      .querySelectorAll("h1,h2,h3,h4,h5,p,blockquote,li,ol,span")
      .forEach(el => {
        const element = el;
        if (element.innerHTML.length > 0) {
          const copy = element.innerHTML.split(" ");
          if (copy.length > 3) {
            const lastword = copy[copy.length - 1];
            const penultword = copy[copy.length - 2];
            const antepenultword = copy[copy.length - 3];
            if (
              lastword.length < 9 &&
              penultword.length < 4 &&
              antepenultword.length < 5
            ) {
              // replace last two breaking spaces with nbsps
              element.innerHTML = el.innerHTML.replace(
                / (?=[^ ]*$)/i,
                "\u00a0"
              );
              element.innerHTML = el.innerHTML.replace(
                / (?=[^ ]*$)/i,
                "\u00a0"
              );
            } else if (lastword.length < 13 && penultword.length < 11) {
              // replace last breaking space with a nbsp
              element.innerHTML = el.innerHTML.replace(
                / (?=[^ ]*$)/i,
                "\u00a0"
              );
            }
          }
        }
      });
  }
};

export default unWidower;
