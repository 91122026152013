/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from "react";
import { useUID } from "react-uid";
import classNames from "classnames";
// gatsby already requires this
// eslint-disable-next-line import/no-extraneous-dependencies
import { HeaderContext } from "@context";
import { HeroBlog, HeroSignup, Container, Slices } from "@layout";
import { ArrowLink, Icon } from "@ui";
import { PrismicLink } from "@utilities";
import { EopCta } from "@common";
import { BlogNav } from "@blog";
import { SearchBox } from "@search";
import BlogCard from "../../components/blog/BlogCard";
import { detect } from "@utils";

const BlogLayout = ({ blog, posts, pageContext }) => {
  // disable eslint because the content is so deep
  // eslint-disable-next-line
  const {
    currentCategory,
    allCategories,
    currentPage,
    totalPages,
  } = pageContext;
  const { setBlogMenu, setPostsMenu, setPostsShowBlogMenu } = useContext(
    HeaderContext
  );

  const uid = useUID();
  const hasWindow = detect.browser().window;
  const embedWebinars = blog?.webinars_embed_link;
  const showWebinars = blog?.show_webinars;
  const postsNav = blog?.show_blog_nav ? blog?.blog_nav : blog?.post_nav;

  const hasEmailSignup = blog.email_signup && blog.email_signup[0];
  const signupContent = {
    ...blog.email_signup,
    lightTheme: false,
  };
  const cats = [
    {
      name: "All",
      slug: "",
    },
    ...allCategories,
  ];

  let previousLink;
  if (currentPage > 1) {
    const page = currentPage - 1;
    const path = currentCategory ? `/blog/${currentCategory.slug}` : "/blog";
    previousLink = page === 1 ? path : `${path}/${page}`;
  }

  let nextLink;
  if (currentPage < totalPages) {
    const path = currentCategory ? `/blog/${currentCategory.slug}` : "/blog";
    nextLink = `${path}/${currentPage + 1}`;
  }

  const Category = ({ cat }) => {
    const { slug: currentSlug } = currentCategory || {};
    const isSelected =
      (!currentSlug && cat.slug === "") || currentSlug === cat.slug;

    const className = classNames({
      "font-sans font-bold uppercase text-sm text-blue transition xxs:tracking-1 xxs:text-xs tracking-2": true,
      "border-transparent": true,
      "underline-2": isSelected,
    });

    const icons = {
      insights: <Icon className="w-4 h-4" iconName="IconInsights" />,
      events: <Icon className="w-4 h-4" iconName="IconEvents" />,
      news: <Icon className="w-4 h-4" iconName="IconNews" />,
      webinars: <Icon className="w-4 h-4" iconName="IconWebinars" />,
      permitting: <Icon className="w-4 h-4" iconName="IconPermitting" />,
      design: <Icon className="w-4 h-4" iconName="IconDesign" />,
      "case-studies": <Icon className="w-4 h-4" iconName="IconCaseStudies" />,
      "": <Icon iconName="IconClock" className="w-4 h-4" />,
    };

    const key = `${useUID()}-${cat.slug}`;
    return (
      <li
        key={key}
        className="p-2 px-3 md:px-6 my-2 xs:my-0 md:mr-12 md:px-0 md:my-0"
      >
        <PrismicLink route={`/blog/${cat.slug}`} className="flex items-center">
          {icons[cat.slug] && (
            <span className="block text-blue w-5 h-5 mr-1">
              {icons[cat.slug]}
            </span>
          )}
          <span className={className}>{cat.name}</span>
        </PrismicLink>
      </li>
    );
  };

  const NavButton = ({ url, text, className: _className, arrowRotation }) => {
    const className = classNames(
      {
        "mx-4 flex items-center bg-transparent": true,
        "opacity-50 pointer-events-none": !url,
      },
      _className
    );

    const arrowClassName = classNames(
      {
        "block w-6 h-4 flex items-center justify-center": true,
      },
      arrowRotation
    );

    return (
      <ArrowLink
        key={uid}
        route={url}
        className={`${className} cursor-pointer py-2 `}
        activeclass="opacity-100"
        bgClass="bg-transparent"
        variant="sm"
        hoverState={["bg-white", "text-blue"]}
        textClass="text-black font-bold"
        arrow={false}
        inline={false}
        type="custom"
        button
      >
        <span className={arrowClassName}>
          <Icon className="w-5 h-5" iconName="Chevron" />
        </span>
        <span>{text}</span>
      </ArrowLink>
    );
  };

  useEffect(() => {
    setBlogMenu(blog?.blog_nav);
    setPostsMenu(postsNav);
    setPostsShowBlogMenu(blog?.show_blog_nav);
  }, []);

  return (
    <div className="bg-white">
      <Container variant="blog" noPadding className="gradient-black pt-24">
        <div className="flex items-top flex-wrap">
          <div className="hidden bg-gray-menu md:block md:w-2/7 lg:w-1/5 xl:w-1/5 md:px-0 xs:pl-6 sm:md-0 relative sticky top-0 z-20">
            <BlogNav
              active={posts.category}
              nav={blog?.blog_nav}
              limit={12}
              search
              searchSelector="blogSearch"
              searchPlaceHolder="Search..."
              width="w-full"
            />
            {blog.body && (
              <section className="mx-8">
                <Slices
                  content={blog.body}
                  className={
                    blog.hero[0].hero_theme === "white"
                      ? "bg-blue-offwhite"
                      : ""
                  }
                  uid={blog?._meta?.uid}
                  noPadding
                />
              </section>
            )}
            {showWebinars && embedWebinars && (
              <section className="mx-8 md:mb-4 lg:mb-6 xl:mb-12">
                <h3 className="text-xl font-bold my-4">Upcoming Webinars</h3>
                <iframe
                  src={embedWebinars}
                  allowFullScreen
                  title="embedWebinars"
                  security="restricted"
                  className="rounded-md border-transparent"
                  height="555px"
                  width="100%"
                  frameBorder="0"
                  aria-hidden="false"
                />
              </section>
            )}
          </div>

          <div className="w-full md:w-5/7 lg:w-4/5 xl:w-4/5 xs:pr-6 md:pr-0 mb-0 sm:md-0 bg-white">
            <HeroBlog
              content={blog.hero[0]}
              variant="light"
              signup={signupContent}
              title={currentCategory?.name}
              topPadding="pt-0"
              yPadding="py-0"
              xPadding="w-full"
              heroClass="hero--blog"
            />
            <Container
              variant="blog"
              className="flex flex-wrap bg-white shadow-inner md:mx-24"
            >
              <div className="mt-8 mx-6 w-full block md:hidden">
                <div id="mobileSearch" className="search--box" />
                {hasWindow && window && (
                  <SearchBox
                    className="block md:hidden"
                    selector="mobileSearch"
                    limit={108}
                    placeHolder="Search..."
                  />
                )}
              </div>
              <section className="bg-white w-full px-6 md:px-8 block md:hidden my-8">
                <ul className="xs:hidden flex w-full flex-wrap justify-center items-center">
                  {cats.map((cat, idx) => (
                    <Category key={`${uid}-${idx}`} cat={cat} />
                  ))}
                </ul>
              </section>
              <section className="w-full md:mx-4">
                <Container variant="full" className="md:py-16">
                  <ul className="grid xs:grid-cols-1 md:grid-cols-2 gap-5">
                    {posts.map(({ node }, idx) => {
                      if (!node.hide)
                        return (
                          <BlogCard content={node} key={`${uid}-${idx}`} />
                        );
                    })}
                  </ul>
                </Container>

                {(previousLink || nextLink) && (
                  <Container className="flex pb-12 justify-center">
                    <NavButton
                      text="previous"
                      url={previousLink}
                      arrowRotation="-rotate-90 -ml-2"
                    />
                    <NavButton
                      text="next"
                      url={nextLink}
                      className="flex-row-reverse"
                      arrowRotation="rotate-90 -mr-2"
                    />
                  </Container>
                )}
              </section>
              {hasEmailSignup && (
                <section className="relative h-full w-full m-auto">
                  <HeroSignup
                    signup={signupContent}
                    noPadding
                    className="w-3/4 m-auto"
                  />
                </section>
              )}
              {blog.cta_eop &&
                blog.cta_eop[0] &&
                (blog.cta_eop[0].subheading ||
                  (blog.cta_eop[0].heading[0] &&
                    blog.cta_eop[0].heading[0].text)) && (
                  <section className="bg-white pb-24">
                    <Container variant="lg">
                      <EopCta content={blog.cta_eop[0]} />
                    </Container>
                    <div className="bg-transparent h-24 relative z-0" />
                  </section>
                )}
            </Container>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BlogLayout;
