/**
 * Get the node object from a data query
 *
 * @param {*} data the returned query object from graphQL
 * @returns the edge node for easier handling of page data
 */
const getPrismicNode = data => {
  const { node } = data.prismic[Object.keys(data.prismic)[0]].edges[0];
  return node;
};

export default getPrismicNode;
