/* eslint-disable import/no-cycle */
export {
  analyticsUserToken,
  analyticsSessionId,
  analyticsSessionEmail,
  cookies,
  load,
  loadForms,
  identifyUser,
  identifyGroup,
  trackEvent,
  trackLink,
  trackForm,
  trackPage,
  resetAnalytics,
  segmentEnabled,
  gtmEnabled,
  analyticsActive,
  trackUtms,
} from "./analytics";
export { default as loadGTM } from "./loadGTM";
export { default as loadSegment } from "./loadSegment";

export const canUseDOM = typeof window !== "undefined";
export {
  enabled,
  active,
  hubspotId,
  load as loadHubspot,
  loadForms as loadHubspotForms,
  loadForm as loadHubspotForm,
  handleFormEvents,
  loadWidget,
  refresh,
  open,
  close,
  remove,
  clear,
  track,
  message,
  visitor,
  identify,
  page,
  refreshHandler,
  userToken,
  showConsent,
  doNotTrack,
  consentStatus,
  newsletterSignup,
  _hsq,
  _hsp,
  _hbspt,
} from "./hubspot";
export {
  huntVerify,
  huntEmail,
  huntDomain,
  huntCount,
  huntAccountCheck,
} from "./hunter";
