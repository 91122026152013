/* eslint-disable valid-typeof */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { Widget } from "@typeform/embed-react";
import { HeaderContext } from "@context";
import { HeroSimple, Container } from "@layout";
import {
  EopCta,
  PageIntro,
  CTALarge,
  SectionHeading,
  IconGrid,
  MetaData,
} from "@common";
import { ShapesPatternRepeat } from "@svg";
import { RandomShapes } from "@decoration";
import { PageTransition } from "@components";

const Manufacturers = props => {
  // disable eslint because the content is so deep
  // eslint-disable-next-line
  const { for_manufacturers } = props.data.prismic;
  const { setSunrisePage, setBlogLayout } = useContext(HeaderContext);
  const showCta =
    for_manufacturers.cta_eop &&
    !!for_manufacturers.cta_eop[0] &&
    (!!for_manufacturers.cta_eop[0].heading ||
      !!for_manufacturers.cta_eop[0].subheading) &&
    (!!for_manufacturers.cta_eop[0].arrow_link_text ||
      !!for_manufacturers.cta_eop[0].button_text);
  const embedForm =
    for_manufacturers.embed_form &&
    for_manufacturers.embed_form[0] &&
    for_manufacturers.embed_form[0].embed_url_or_code;
  const embedFormCode =
    for_manufacturers.embed_form &&
    for_manufacturers.embed_form[0] &&
    for_manufacturers.embed_form[0].embed_code;
  const embedTypeform =
    for_manufacturers.embed_form &&
    for_manufacturers.embed_form[0] &&
    for_manufacturers.embed_form[0].typeform;
  const embedHubspot =
    for_manufacturers.embed_form &&
    for_manufacturers.embed_form[0] &&
    for_manufacturers.embed_form[0].hubspot;
  useEffect(() => {
    setSunrisePage(false);
    setBlogLayout(false);
  }, []);

  const HubspotForm = form => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/shell.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        // @TS-ignore
        if (window?.hbspt) {
          // @TS-ignore
          window.hbspt.forms.create({
            region: form?.region || process.env.GATSBY_HUBSPOT_REGION || "na1",
            portalId:
              form?.portalId || process.env.GATSBY_HUBSPOT_HUB_ID || "43908954",
            formId:
              form?.id ||
              process.env.GATSBY_HUBSPOT_DEFAULT_FORM ||
              "cae62dff-35c8-426f-9d0c-7d1813a4b847",
            target: "#hubspotForm",
          });
        }
      });
    }, []);
    return (
      <>
        <div
          className="max-w-3xl xl:max-w-5xl px-6 md:px-3 lg:px-6 relative z-10 md:mx-auto lg:w-full "
          style={{
            width: form?.width || "100%",
            height: form?.height || "100%",
          }}
          id="hubspotForm"
        />
      </>
    );
  };

  const EmbedUrl = () => {
    if (embedForm && embedHubspot && typeof window !== "undefined") {
      return <HubspotForm formId={embedHubspot} />;
    }
    if (embedForm && embedTypeform && typeof window !== "undefined") {
      return (
        <Widget
          id={embedTypeform || "nMhM56If"}
          className="embed"
          autoFocus={false}
          shareGaInstance
          inlineOnMobile
          keepSession
          height="777"
        />
      );
    }
    if (embedForm && embedFormCode && typeof window !== "undefined") {
      return (
        <section className="relative pt-10 bg-blue-offwhite">
          <div className="justify-center items-center order-last">
            <Container>
              <div className="plyr__video-embed" id="form-embed">
                <iframe src={embedFormCode} allowFullScreen title="embedForm" />
              </div>
            </Container>
          </div>
        </section>
      );
    }
    return null;
  };

  return (
    <>
      <MetaData content={for_manufacturers} type="webpage" />
      <PageTransition theme="light">
        <article className="z-10 relative">
          <HeroSimple
            content={for_manufacturers.hero[0]}
            variant="light"
            gradient="light"
            centered
          />
          <PageIntro
            className="z-1 relative"
            content={for_manufacturers.intro[0]}
            curve={false}
            white
            float
          />
          <section className="pt-12">
            <Container variant="copy">
              <SectionHeading
                className="mt-12 md:my-12 sm:mt-24"
                content={for_manufacturers.product_details_heading[0]}
                variant="sm"
              />
            </Container>
            <Container>
              <IconGrid content={for_manufacturers.product_details} />
            </Container>
          </section>
          {embedForm && <EmbedUrl />}
          <section className="relative pt-10">
            <div className="absolute inset-0 flex items-center justify-center mt-8">
              <ShapesPatternRepeat
                className="opacity-3 text-blue stroke-current h-full w-full"
                dimensions={[10, 10]}
              />
            </div>
            <div className="relative flex w-full justify-center items-center order-last">
              <RandomShapes
                opacity=".1"
                shapes={[
                  { Circle: "coral" },
                  { Square: "blue" },
                  { FourthCircle: "coral" },
                  { FourthCircle: "blue" },
                ]}
              />
              <Container variant="heading">
                <CTALarge
                  color="blue"
                  content={for_manufacturers.cta_large[0]}
                />
              </Container>
            </div>
            {showCta && (
              <Container variant="lg">
                <EopCta content={for_manufacturers.cta_eop[0]} />
              </Container>
            )}
            <div className="bg-transparent h-24 relative z-0" />
          </section>
        </article>
      </PageTransition>
    </>
  );
};

export default Manufacturers;

export const query = graphql`
  query ManufacturersQuery {
    prismic {
      for_manufacturers(lang: "en-us", uid: "manufacturers") {
        title
        meta_title
        meta_image
        meta_description
        cta_eop {
          tracking_event
          heading
          subheading
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          button_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
        cta_large {
          background_image
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          copy
          icon
          heading
        }
        hero {
          heading
          subtitle
        }
        intro {
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          copy
          icon
          intro_image
          heading
          subheading
        }
        manufacturer_testimonials {
          company
          full_name
          logo_image
          logo_svg_code
          quote
        }
        product_details_heading {
          heading
          subheading
        }
        product_details {
          copy
          heading
          icon
          link_text
          pdf {
            _linkType
            ... on PRISMIC__FileLink {
              url
            }
          }
        }
        embed_form {
          embed_url_or_code
          embed_code
          typeform
          hubspot
          webhook_url
          form_embed_method
        }
      }
    }
  }
`;
