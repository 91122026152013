/* eslint-disable react/prop-types */
import React from "react";

export default React.memo(({ content }) => {
  if (content.subheading !== null) {
    return (
      <h3 className="text-blue uppercase font-bold tracking-1 text-xs sm:text-sm mb-2">
        {content.subheading[0].text}
      </h3>
    );
  }
  return null;
});
