/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ShapesPatternHero } from "@svg";

const HeroShapes = React.memo(
  ({ noShapes, simple, variant, posts, inverted, overflow }) => {
    const overflowType = `overflow-${overflow}`;
    if (!noShapes || typeof noShapes === "undefined") {
      return (
        <div
          className={classNames(overflowType, {
            "scale-x-1": inverted,
            "-scale-x-1 ": !inverted,
            "absolute text-blue-offwhite w-full h-full inset-0": true,
            "rounded-br-heroxs sm:rounded-br-herosm md:rounded-br-heromd":
              overflowType !== "overflow-hidden",
          })}
        >
          <div
            className="absolute flex flex-col items-start justify-center xl:ml-64"
            style={{ height: "200%", width: "200%", top: "-33%", left: "-40%" }}
          >
            <ShapesPatternHero
              className={classNames({
                "opacity-8": variant !== "light",
                "shapes-pattern-hero-posts": posts,
                "shapes-pattern-hero-simple": simple,
                "shapes-pattern-hero": !simple && !posts,
                "stroke-current": true,
              })}
              dimensions={[12, 20]}
              posts={posts}
              style={{
                marginLeft: "25%",
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  }
);

HeroShapes.defaultProps = {
  overflow: "hidden",
  variant: "dark",
};

HeroShapes.propTypes = {
  overflow: PropTypes.string,
  variant: PropTypes.string,
};

export default HeroShapes;
