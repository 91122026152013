/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */

/* common utilities */

const isDev =
  process.env.GATSBY_ACTIVE_ENV === "development" ||
  process.env.NODE_ENV === "development";
const isLive =
  process.env.GATSBY_ACTIVE_ENV === "production" ||
  process.env.NODE_ENV === "production";
const gatsbyLeadFormTest = process.env.GATSBY_LEAD_FORM_TEST_MODE;
const isTestLead = gatsbyLeadFormTest === "on" || gatsbyLeadFormTest === "true";
const isLocalLead =
  process.env.GATSBY_ZAPIER_MODE === "test" ||
  (process.env.GATSBY_ZAPIER_MODE === "local" && isTestLead);
const isDevLog = isDev && isTestLead;

const log = (location, name, data, additionalData) => {
  if (isDevLog) {
    return console.log(
      "Dev log: ",
      { location, name, data, additionalData },
      window
    );
  }
  if (isDev) {
    return console.log("Log:", { location, name, data });
  }
  if (isLive) {
    return null;
  }
  return console.log(name);
};

const error = (location, name, data, additionalData) => {
  if (isDevLog) {
    return console.error("Dev Error Log: ", {
      location,
      name,
      data,
      additionalData,
    });
  }
  if (isDev) {
    return console.error("Error:", { location, name, data });
  }
  if (isLive) {
    return null;
  }
  return console.error(name);
};

const dev = {
  log,
  error,
  isDev,
  isLive,
  isTestLead,
  isLocalLead,
};

export default dev;
