/* eslint-disable react/prop-types */
import React from "react";
import { Image } from "@ui";

export default React.memo(({ slide }) => {
  return (
    <Image
      src={slide.image.url}
      caption={slide.caption}
      ixparams={slide.image.params != null ? slide.image.params : null}
      transparent
    />
  );
});
