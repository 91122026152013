/* eslint-disable react/prop-types */
import React, { useState } from "react";

import classNames from "classnames";
import { useUID } from "react-uid";
import AnimateHeight from "react-animate-height";
import { RichText, Image } from "@ui";
import { Container } from "@layout";
import { Chevron } from "@svg";
import { RandomShapes } from "@decoration";

const AccordionWithImages = React.memo(({ content }) => {
  const [currentItem, setCurrentItem] = useState(0);
  return (
    <Container className="flex flex-col">
      {/* accordian */}
      <div className="flex flex-wrap -mx-6 my-auto min-h-hero-sm">
        {/* image column */}
        <div className="hidden md:block w-full hidden md:block md:w-4/7 relative px-6">
          <div className="relative w-full h-full">
            {content.map((item, index) => {
              const uid = useUID();
              return (
                <div
                  key={uid}
                  className={classNames({
                    "opacity-0": currentItem !== index,
                    "transition-accordion-delay absolute inset-0": true,
                  })}
                >
                  <Image
                    src={item.image.url}
                    className={classNames({
                      active: currentItem !== index,
                      "transition-accordion accordion-image": true,
                    })}
                    transparent
                  />
                </div>
              );
            })}
          </div>
          <RandomShapes opacity=".25" seed="1234" />
        </div>
        {/* accordian content */}
        <div className="hidden md:block w-full flex h-full relative flex-wrap md:w-3/7 px-6 my-auto">
          {content.map((item, index) => {
            const uid = useUID();
            return (
              <div
                key={uid}
                className={classNames({
                  "shadow rounded-xl bg-white": currentItem === index,
                  "p-6 w-full transition-slow-delay hover:translate-y-lift hover:shadow-lift hover:underline-2": true,
                })}
              >
                <button
                  type="button"
                  className={classNames({
                    "cursor-default": currentItem === index,
                    "cursor-pointer": currentItem !== index,
                    "font-bold display flex w-full justify-between items-center": true,
                  })}
                  onClick={() => setCurrentItem(() => index)}
                >
                  {item.heading[0].text}
                  <span
                    className={classNames({
                      "-rotate-180": currentItem !== index,
                      "text-teal w-6 h-6 ml-auto transition": true,
                    })}
                  >
                    <Chevron />
                  </span>
                </button>
                <AnimateHeight
                  duration={500}
                  height={currentItem === index ? "auto" : 0} // see props documentation below
                >
                  <div
                    className={classNames({
                      "sm:mt-0": currentItem !== index,
                      "sm:mt-4": currentItem === index,
                      "rich-text text-sm transition overflow-hidden mt-4": true,
                    })}
                  >
                    <RichText content={item.description} />
                  </div>
                </AnimateHeight>
              </div>
            );
          })}
        </div>
        {/* stacked */}
        <div className="w-full h-full relative md:hidden px-6 my-auto">
          {content.map(item => {
            const uid = useUID();
            return (
              <div
                key={uid}
                className={classNames({
                  "p-6 w-full relative": true,
                })}
              >
                <h4
                  className={classNames({
                    "font-bold display flex justify-between items-center mb-4": true,
                  })}
                >
                  {item.heading[0].text}
                </h4>
                <Image src={item.image.url} className="mt-4" />
                <div
                  className={classNames({
                    "rich-text text-sm transition overflow-hidden mt-4": true,
                  })}
                >
                  <RichText content={item.description} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
});

export default AccordionWithImages;
