/* eslint-disable */
import { navigate } from "@reach/router";
import { dev, LocalStorage, detect } from "@utils";
import { trackUtms, identifyUser } from "@analytics";

// setting a var to avoid loading the script multiple times
let loadTriggered = false;
let intercomBooted = false;
let refreshed = false;
const hasWindow = detect.browser().window;
export const intercomEnabled = !!(process.env.GATSBY_INTERCOM_MODE === "on" && (process.env.GATSBY_INTERCOM_APP_ID && process.env.GATSBY_INTERCOM_APP_DEV_ID));
export const intercomActive =
intercomEnabled && hasWindow && window?.Intercom;
export const intercomId = process.env.GATSBY_ANALYTICS_ENV === 'development' ? process.env.GATSBY_INTERCOM_APP_DEV_ID : process.env.GATSBY_INTERCOM_APP_ID;
const intercomIdCookie = `intercom-id-${intercomId}`;
const setStorageKeys = (value, domain) => {
  const validId = detect.isLyraUserId(value) ? value : null;
  if (validId) {
    LocalStorage.set(
      "ajs_anonymous_id",
      value,
      false,
      true
    );
    LocalStorage.set(
      "ajs_user_id",
      value,
      false,
      true
    );
    LocalStorage.setCookie("ajs_anonymous_id", value, domain);
    LocalStorage.setCookie("ajs_user_id", value, domain);
    LocalStorage.setCookie(intercomIdCookie, value, domain);
    intercomBooted = true;
    console.log("validId", value)
  }
}

export const loadIntercom = () => {
  loadTriggered = true;
  if (dev.isDevLog) {
    console.log("loadIntercom");
  }
  if (intercomEnabled && loadTriggered && hasWindow && window && !window.Intercom) {
    window.intercomSettings = {
      app_id: intercomId,
      // action_color: TailwindConfig.theme.extend.colors.blue,
      // background_color: TailwindConfig.theme.extend.colors.blue.default,
    };

    const w = window;
    const ic = w.Intercom;

    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
      console.log("Updated Intercom Instance");
    } else {
      const d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${intercomId}`;
      const x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      console.log("Loaded Intercom");
      return true;
    }
  }
  return false;
};

// Intercom Javascript API: Methods - https://developers.intercom.com/installing-intercom/docs/intercom-javascript
export const getVisitorId = (id) => {
  if (dev.isDevLog) {
    console.log("getVisitorId");
  }
  const existingId = id || LocalStorage.getSession("_crm_id", false, true) || LocalStorage.get("_crm_id", false, true) || LocalStorage.get("ajs_user_id", false, true) || LocalStorage.getCookie("ajs_user_id") || LocalStorage.getCookie(intercomIdCookie);
  let valid = detect.isLyraUserId(existingId);
  let freshUserId;
  if (existingId && valid) {
    // console.log("existingId", existingId);
    return existingId;
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    freshUserId = window.Intercom('getVisitorId');
    // console.log("freshUserId", freshUserId)
    freshUserId = detect.isLyraUserId(freshUserId) ? freshUserId : null;
    return freshUserId;
  } else if (intercomEnabled && hasWindow && window && !window.Intercom) {
    if (!intercomBooted) {
      loadIntercom();
      setTimeout(() => {
        freshUserId = window.Intercom('getVisitorId');
        // console.log("setTimeout:freshUserId", freshUserId);
        freshUserId = detect.isLyraUserId(freshUserId) ? freshUserId : null;
        return freshUserId;
      }, 1122);
    }
  }
}
export const shutdownMessenger = () => {
  if (dev.isDevLog) {
    console.log("shutdownMessenger");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('shutdown');
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window) {
        loadIntercom();
      }
    }, 2801);
  }
}

// Requires you to include the current globalHistory object from react/router
// This should always return a valid visitorId (intercomVisitorId)
export const bootMessenger = (location, intercomVisitorId, additionalDetails, lang) => {
  const { search, hostname } = location;
  const hasNavigator = detect.browser().navigator;
  const doNotTrack = hasNavigator && (navigator?.doNotTrack === 1 || window?.doNotTrack || navigator?.doNotTrack || navigator?.msDoNotTrack);
  const locale = lang?.slice(0, 2);
  if (dev.isDevLog) {
    console.log("Booting messenger");
  }
  if (intercomBooted) {
    return;
  }
  if (doNotTrack) {
    setStorageKeys("We will not track", hostname);
    return "We will not track";
  }
  const urlParams = new URLSearchParams(search);
  const refreshSessions = urlParams.get("refresh") === "tokens" || urlParams.get("refresh") === "user" || urlParams.get("reset") === "user";
  if (refreshSessions && !refreshed) {
    LocalStorage.remove("_crm_id");
    LocalStorage.remove("_submitted");
    LocalStorage.remove("_leadSubmission");
    LocalStorage.remove("_recaptcha");
    LocalStorage.remove("ajs_user_id");
    LocalStorage.remove("ajs_anonymous_id");
    LocalStorage.remove("_grecaptcha");
    // shutdownMessenger();
    console.log("Cleared session");
    refreshed = true;
  }
  const userId = urlParams.get("messages") || urlParams.get("uid");
  const validUserId = userId && detect.isLyraUserId(userId);
  const validIntercomVisitorId = intercomVisitorId && detect.isLyraUserId(intercomVisitorId);
  const validedIntercomVisitorId = validIntercomVisitorId ? intercomVisitorId : null;
  const combinedId = validUserId ? userId : validedIntercomVisitorId;
  const user_id = combinedId;
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    console.log("Booting Intercom");
    if (validUserId) {
      window.Intercom('boot', {
        app_id: intercomId,
        ...additionalDetails,
        user_id: user_id,
        language_override: locale,
      });
      setStorageKeys(user_id, hostname);
      identifyUser(user_id, additionalDetails);
      console.log(user_id);
      return user_id;
    }
    window.Intercom('boot', {
      app_id: intercomId,
      language_override: locale,
    });
    let visitorId = getVisitorId();
    visitorId = detect.isLyraUserId(visitorId) ? visitorId : null;
    intercomBooted = !!visitorId;
    if (visitorId) {
      console.log(visitorId);
      return visitorId;
    }
    setTimeout(() => {
      visitorId = getVisitorId();
      visitorId = detect.isLyraUserId(visitorId) ? visitorId : null;
      console.log(visitorId);
      return visitorId;
    }, 1899);
  } else {
    loadIntercom();
      setTimeout(() => {
        if (intercomEnabled && hasWindow && window && window.Intercom) {
          if (validUserId) {
            window.Intercom('boot', {
              app_id: intercomId,
              ...additionalDetails,
              user_id: user_id,
              language_override: locale,
            });
            setStorageKeys(user_id, hostname);
            identifyUser(user_id, additionalDetails);
            console.log(user_id);
            return user_id;
          }
          window.Intercom('boot', {
            app_id: intercomId,
            language_override: locale,
          });
          let visitorId = getVisitorId();
          visitorId = detect.isLyraUserId(visitorId) ? visitorId : null
          intercomBooted = !!visitorId;
          intercomBooted && console.log(visitorId);
          return visitorId;
        } else {
          setTimeout(() => {
            loadIntercom();
            if (intercomEnabled && hasWindow && window && window.Intercom) {
              if (validUserId) {
                window.Intercom('boot', {
                  app_id: intercomId,
                  ...additionalDetails,
                  user_id: user_id,
                  language_override: locale,
                });
                setStorageKeys(user_id, hostname);
                identifyUser(user_id, additionalDetails);
                console.log(user_id);
                return user_id;
              }
              window.Intercom('boot', {
                app_id: intercomId,
                language_override: locale,
              });
              let visitorId = getVisitorId();
              visitorId = detect.isLyraUserId(visitorId) ? visitorId : null
              intercomBooted = !!visitorId;
              intercomBooted && console.log(visitorId);
              return visitorId;
            }
          }, 1111);
        };
      }, 1111);
  }
}
export const updateMessenger = (location, additionalData) => {
  const { hostname } = location;
  if (dev.isDevLog) {
    console.log("updateMessenger", additionalData);
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    if (additionalData?.user_id && (additionalData?.user_id !== "undefined" || additionalData?.user_id !== undefined)) {
      window.Intercom('update', { ...additionalData, ...trackUtms?.track, ...trackUtms?.custom});
      setStorageKeys(additionalData?.user_id, hostname);
    } else {
      window.Intercom('update', { ...additionalData, ...trackUtms?.track, ...trackUtms?.custom});
    }
  } else {
    if (dev.isDevLog) {
      console.log("failed updateMessenger, now trying to boot", additionalData);
    }
    bootMessenger({ ...additionalData, ...trackUtms?.track, ...trackUtms?.custom});
  }
}
export const trackEvent = (event, additionalData) => {
  if (dev.isDevLog) {
    console.log("trackEvent", { event, additionalData });
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('trackEvent', event, { ...additionalData, ...trackUtms?.track, ...trackUtms?.custom});
  } else {
    bootMessenger({ ...additionalData, ...trackUtms?.track, ...trackUtms?.custom});
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('trackEvent', event, { ...additionalData, ...trackUtms?.track, ...trackUtms?.custom});
        if (dev.isDevLog) {
          console.log("2nd try after fail trackEvent", { event, additionalData });
        }
      } else {
        navigate("/contact");
      }
    }, 2801);
  }
}
export const showMessenger = () => {
  if (dev.isDevLog) {
    console.log("showMessenger");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('show');
  } else {
    bootMessenger();
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('show');
      } else {
        navigate("/contact");
      }
    }, 2801);
  }
}
export const hideMessenger = () => {
  if (dev.isDevLog) {
    console.log("hideMessenger");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('hide');
  } else {
    bootMessenger();
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('hide');
      } else {
        navigate("/contact");
      }
    }, 2801);
  }
}
export const showMessages = () => {
  if (dev.isDevLog) {
    console.log("showMessages");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('showMessages');
  } else if (intercomEnabled && hasWindow && window) {
    bootMessenger();
    setTimeout(() => {
      window.Intercom('showMessages');
    }, 2000);
  }
}
export const showNewMessage = () => {
  if (dev.isDevLog) {
    console.log("showNewMessage");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('showNewMessage');
  } else {
    bootMessenger();
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('showNewMessage');
      } else {
        navigate("/contact");
      }
    }, 2801);
  }
}
// https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomstarttour-tourid
export const startTour = id => {
  if (dev.isDevLog) {
    console.log("startTour");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('startTour', id);
  } else {
    bootMessenger();
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('startTour', id);
      } else {
        navigate("/contact");
      }
    }, 2801);
  }
}
export const showSpace = id => {
  if (dev.isDevLog) {
    console.log("showSpace");
  }
  /* 
  * Must be one of 'home', 'messages', 'help', 'news', 'tasks'
  */
  const space = id || "home";
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('showSpace', space);
  } else {
    bootMessenger();
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('showSpace', space);
      } else {
        hasWindow && window.location.replace("https://go.lyra.solar/newshq");
      }
    }, 2801);
  }
}
export const showArticle = id => {
  if (dev.isDevLog) {
    console.log("showArticle");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('showArticle', id);
  } else {
    bootMessenger();
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('showArticle', id);
      } else {
        navigate("/contact");
      }
    }, 2801);
  }
}
export const showNews = id => {
  if (dev.isDevLog) {
    console.log("showNews");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('showNews', id);
  } else {
    bootMessenger();
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('showNews', id);
      } else {
        // navigate("/contact");
        hasWindow && window.location.replace("https://go.lyra.solar/newshq");
      }
    }, 2801);
  }
}
export const startSurvey = id => {
  if (dev.isDevLog) {
    console.log("startSurvey");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('startSurvey', id);
  } else {
    bootMessenger();
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('startSurvey', id);
      } else {
        navigate("/contact");
      }
    }, 2801);
  }
}
export const startChecklist = id => {
  if (dev.isDevLog) {
    console.log("startChecklist");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom('startChecklist', id);
  } else {
    bootMessenger();
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom('startChecklist', id);
      } else {
        navigate("/contact");
      }
    }, 2801);
  }
}
export const MessengerApi = (additionalData) => {
  if (dev.isDevLog) {
    console.log("MessengerApi");
  }
  if (intercomEnabled && hasWindow && window && window.Intercom) {
    window.Intercom.apply(null, additionalData);
  } else {
    setTimeout(() => {
      if (intercomEnabled && hasWindow && window && window.Intercom) {
        window.Intercom.apply(null, additionalData);
      } else {
        navigate("/contact");
      }
    }, 2801);
  }
}
