/* eslint-disable global-require */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TimelineLite } from "gsap";
import { useCookies } from "react-cookie";
// eslint-disable-next-line import/no-extraneous-dependencies
import { globalHistory } from "@reach/router";
import { AppHeader, AppHeaderEs, Notifications, GDPR, GDPRES } from "@layout";
import TailwindConfig from "@tailwindConfig";
import { HeaderContext } from "@context";
import * as analytics from "@analytics";
import {
  loadSDT,
  allowHunting as huntingIsAllowed,
  LocalStorage,
  detect,
} from "@utils";

/* import {
  login,
  logout,
  isAuthenticated as isAuth,
  getProfile,
} from "../../auth/service"; */

const DefaultLayout = React.memo(({ children, pageContext }) => {
  const hasWindow = detect.browser().window;
  const windowPath = hasWindow && window && window.location?.pathname;
  const windowWidth = hasWindow && window && window.innerWidth;
  const previousPath = hasWindow && window && window.previousPath;
  const currentLocation = globalHistory.location;
  const currentPath = currentLocation.pathname;
  const { lang: contentLang, uid: page } = pageContext;
  const [lang, setLang] = useState(detect.language());
  const [switchLang] = useState(!!lang || !!contentLang);
  const [enforceLang, setEnforceLang] = useState(
    (lang === "es-pr" || contentLang === "es-pr") &&
      currentPath.includes("es-pr")
  );
  const [uid] = useState(page);
  const loadSDTSunset = loadSDT.sunset(currentLocation, previousPath);
  const loadSDTSunrise = loadSDT.sunset(loadSDTSunset, hasWindow);
  const hubspotAppIdCookie = analytics?.cookies?._hubspot_utk;
  const leadHasSubmitted =
    !!LocalStorage.get("_crm_id", false, true) ||
    !!LocalStorage.getSession("_crm_id") ||
    !!LocalStorage.getSession("_crm_email") ||
    !!LocalStorage.getSession("_submitted") ||
    !!LocalStorage.getSession("_leadSubmission") ||
    !!LocalStorage.getCookie("_crm_email") ||
    !!LocalStorage.getCookie("_crm_id") ||
    !!LocalStorage.getCookie("_submitted") ||
    !!LocalStorage.getCookie("_leadSubmission");
  const leadSubmissionDetails = LocalStorage.get(
    "_leadSubmission",
    false,
    true
  );
  const isBlogWindow = windowPath && windowPath.includes("blog");
  const isBlogPath = currentPath?.includes("blog");
  const isBlog =
    !!isBlogWindow ||
    !!isBlogPath ||
    pageContext?.type === "posts" ||
    pageContext?.type === "blog" ||
    pageContext?.totalPages;
  const isOnboardWindow = windowPath && windowPath.includes("blog");
  const isOnboardPath = currentPath?.includes("blog");
  const isOnboarding =
    !!isOnboardWindow ||
    !!isOnboardPath ||
    pageContext?.type === "onboard" ||
    pageContext?.type === "onboard" ||
    pageContext?.totalPages;
  // setup url param tracking
  const currentUtm = detect.currentUtm(currentLocation);
  const [cookies] = useCookies([
    "lyraAllowCookies",
    "lyraConsentManager",
    hubspotAppIdCookie,
  ]);
  const isFullPagePath = currentPath?.includes("stay-in-touch");
  const tl = new TimelineLite();
  tl.autoRemoveChildren = true;
  // State management
  const [utms] = useState(currentUtm);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [isBlogLayout, setBlogLayout] = useState(isBlog);
  const [isFullPage, setFullPage] = useState(isFullPagePath);
  const [theme, setTheme] = useState(null);
  const [navOpen, setNavOpen] = useState(false);
  const [sunrise, setSunrise] = useState(loadSDTSunset.sunrise);
  const [sunrisePage, setSunrisePage] = useState(loadSDTSunset.page);
  const [announcementShowing, setAnnouncementShowing] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [allowCookies, setAllowCookies] = useState(!!cookies.lyraAllowCookies);
  const [updateGDPRPreferences] = useState(
    currentPath === "/data-collection-policy" ||
      currentPath === "/es-pr/data-collection-policy" ||
      currentPath === "/privacy-policy" ||
      currentPath === "/es-pr/privacy-policy" ||
      currentPath === "/terms-of-use" ||
      currentPath === "/es-pr/terms-of-use"
  );
  const [showConsentManager, setShowConsentManager] = useState(
    analytics.segmentEnabled
  );
  const consentCookieExists = !!(cookies && cookies.lyraConsentManager);
  const funConsent =
    consentCookieExists && cookies.lyraConsentManager.custom.functional;
  const trackConsent =
    consentCookieExists && cookies.lyraConsentManager.custom.essential;
  const _allowTracking = !!funConsent && !!trackConsent;
  const [allowTracking, setAllowTracking] = useState(!!_allowTracking);
  const [appEnv] = useState(process.env.GATSBY_ACTIVE_ENV);
  const [appUrlBase] = useState(process.env.GATSBY_APP_URL);
  const appUrlBackup =
    appEnv === "production"
      ? "https://app.lyrasolar.com"
      : "https://dev.lyrasolarapp.com";
  const [appUrl, setAppUrl] = useState(appUrlBase || appUrlBackup);
  const [sdtAppUrl] = useState(loadSDTSunset.loginUrl);
  const [allowHunting, setAllowHunting] = useState(huntingIsAllowed);
  const [hubspotVisitorId, setHubspotVisitorId] = useState(
    analytics.analyticsSessionId()
  );
  const [hubspotUser, setHubspotUser] = useState(
    analytics.analyticsSessionId()
  );
  const [leadSubmitted, setLeadHasSubmitted] = useState(
    leadHasSubmitted || !!leadSubmissionDetails
  );
  const [leadSubmission, setLeadSubmission] = useState(
    leadSubmissionDetails && JSON.parse(leadSubmissionDetails)
  );
  const [mounted, setMounted] = useState(false);
  const [hubspotMounted, setHubspotMounted] = useState(false);
  const [postsShowBlogMenu, setPostsShowBlogMenu] = useState(null);
  const [postsMenu, setPostsMenu] = useState(null);
  const [blogMenu, setBlogMenu] = useState(null);
  const [blogCategories, setBlogCategories] = useState(null);
  const [blogPosts, setBlogPosts] = useState(null);
  const [blogCtaMenu, setBlogCtaMenu] = useState(null);
  const [blogActivePost, setBlogActivePost] = useState(null);
  const [width, setWidth] = useState(windowWidth);
  const isMobile = width <= 899;

  function handleWindowSizeChange() {
    setWidth(windowWidth);
  }
  // Set the appropriate layout
  useEffect(() => {
    setBlogLayout(isBlog);
    setFullPage(isFullPage);
    if (hasWindow && window) {
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }
    return null;
  }, []);
  // Set the appropriate menu language
  useEffect(() => {
    if (currentPath.includes("es-pr")) {
      setLang("es-pr");
      setEnforceLang(true);
    }
    return null;
  }, []);
  // Check to see if we can use the hunter api
  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
      if (allowHunting && !mounted) {
        analytics
          .huntAccountCheck()
          .then(status => {
            setAllowHunting(status?.allow);
          })
          .catch(error => {
            setAllowHunting(false);
            console.error("Error", error?.message);
          });
        console.log("Verification activated");
      }
    }, 777);
  }, [mounted]);
  // Manage utm state
  useEffect(() => {
    let { utms: storedUtms } = analytics.trackUtms;
    if (utms) {
      const utm_time = new Date().getTime();
      if (storedUtms) {
        storedUtms.push({ ...utms, utm_time });
      } else {
        storedUtms = [{ ...utms, utm_time }];
      }
      LocalStorage.setSession("_utm", JSON.stringify(storedUtms));
      LocalStorage.set("_utm", JSON.stringify(storedUtms), false);
    }
  }, []);

  // Show hubspot messenger on load
  useEffect(() => {
    if (analytics.segmentEnabled) {
      setTimeout(() => {
        analytics.load(
          true,
          allowCookies || consentCookieExists,
          currentLocation
        );
        analytics.loadForms();
      }, 222);
      setTimeout(() => {
        setHubspotMounted(true);
        setHubspotVisitorId(analytics.analyticsSessionId());
        setHubspotUser(analytics.identifyUser());
      }, 333);
    }
  }, [hubspotMounted]);

  // Effect used for TESTING - comment out when not in use
  /*
  useEffect(() => {
    // only used for testing cookies from lyra app
    const cookie = {
      firstName: "Joel",
      lastName: "Serino",
      picture:
        "https://s.gravatar.com/avatar/b0e61a7374f7d7e8f54da46906fb9bfa?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fjs.png",
      primaryUse: "PERMIT_PACKAGES",
      id: "auth0|6053edd5eaac940067f6e58c",
      accountAdmin: true,
      email: "joel@lyrasolar.com",
      account: {
        id: "6708ac31-24f3-4d04-8ad7-d7656e6598a6",
        type: "INSTALLATION_COMPANY",
      },
    };
    LocalStorage.setCookie("LYRA_USER_DATA", cookie);
    setTimeout(() => {
      console.log("cookies", analytics.cookies);
    }, 444);
  }, []);
  */

  return (
    <>
      <noscript>
        <style>
          {`#AppHeader {
                background: ${TailwindConfig.theme.extend.colors.blue.default};
                
              }
              .lqip {
                opacity:0;
              }`}
        </style>
      </noscript>
      <HeaderContext.Provider
        value={{
          appUrl,
          sdtAppUrl,
          hubspotUser,
          hubspotVisitorId,
          setHubspotVisitorId,
          leadSubmitted,
          setLeadHasSubmitted,
          leadSubmission,
          setLeadSubmission,
          hasWindow,
          announcementShowing,
          setAnnouncementShowing,
          theme,
          setTheme,
          navOpen,
          setNavOpen,
          notifications,
          setNotifications,
          tl,
          cookies,
          allowCookies,
          allowTracking,
          setAllowCookies,
          setAllowTracking,
          setAppUrl,
          disabledClientTracking: !showConsentManager,
          setShowConsentManager,
          // login,
          // logout,
          // isAuthenticated,
          // getProfile,
          allowHunting,
          setAllowHunting,
          currentPath,
          currentLocation,
          previousPath,
          sunrise,
          sunrisePage,
          setSunrise,
          setSunrisePage,
          loadSDTSunset,
          loadSDTSunrise,
          blogCtaMenu,
          setBlogCtaMenu,
          blogMenu,
          setBlogMenu,
          postsMenu,
          setPostsMenu,
          postsShowBlogMenu,
          setPostsShowBlogMenu,
          blogCategories,
          setBlogCategories,
          blogPosts,
          setBlogPosts,
          blogActivePost,
          setBlogActivePost,
          isMobile,
          isBlog,
          setBlogLayout,
          isBlogLayout,
          isOnboarding,
          isMaintenance,
          setIsMaintenance,
          utms,
          isFullPage,
          lang,
          setLang,
          switchLang,
          enforceLang,
          setEnforceLang,
          contentLang,
          uid,
        }}
      >
        {enforceLang ? <AppHeaderEs /> : <AppHeader />}
        <div id="layout-container">{children}</div>
        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
        <Notifications />
        {enforceLang ? (
          <GDPRES
            showConsentManager={showConsentManager}
            consentCookieExists={consentCookieExists}
            setShowConsentManager={setShowConsentManager}
            tracking={allowTracking}
            preferences={updateGDPRPreferences}
            location={currentLocation}
          />
        ) : (
          <GDPR
            showConsentManager={showConsentManager}
            consentCookieExists={consentCookieExists}
            setShowConsentManager={setShowConsentManager}
            tracking={allowTracking}
            preferences={updateGDPRPreferences}
            location={currentLocation}
          />
        )}
      </HeaderContext.Provider>
    </>
  );
});

DefaultLayout.defaultProps = {
  pageContext: {},
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.any])
  ),
};

export default DefaultLayout;
