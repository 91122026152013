/* eslint-disable react/prop-types */
import React from "react";
import { Image, RichText } from "@ui";

export default React.memo(({ slide }) => {
  return (
    <div className="px-6 carousel-item">
      <Image src={slide.image.url} />
      <div className="carousel-item__caption">
        <h4 className="font-bold text-xl mt-8 mb-6">{slide.heading[0].text}</h4>
        <div className="rich-text leading-normal">
          <RichText content={slide.description} />
        </div>
      </div>
    </div>
  );
});
