/* eslint-disable react/prop-types */
import React from "react";
import { useUID } from "react-uid";
import { RichText, PreviewLink, Icon } from "@ui";

const IconGrid = React.memo(({ content }) => {
  return (
    <div className="flex flex-wrap -mx-6 mb-12 sm:mb-0 px-6 md:px-0">
      {content.map(item => {
        const uid = useUID();
        if (item.pdf != null) {
          return (
            <div key={uid} className="w-full mt-12 sm:mt-0 sm:w-1/2 p-6">
              <h4 className="flex text-base font-bold items-center">
                <span className="mr-2 text-blue">
                  <Icon className="w-8 h-8" iconName={item.icon} />
                </span>
                {item.heading[0].text}
              </h4>
              <div className="rich-text leading-normal mt-4 mb-6">
                <RichText content={item.copy} />
              </div>
              <div>
                <PreviewLink className="text-sm" pdf={item.pdf.url}>
                  {item.link_text}
                </PreviewLink>
              </div>
            </div>
          );
        }
        return (
          <div key={uid} className="w-full mt-8 sm:mt-0 sm:w-1/2 p-6">
            <h4 className="flex text-base font-bold items-center">
              <span className="mr-2 text-blue">
                <Icon className="w-7 h-7" iconName={item.icon} />
              </span>
              {item.heading[0].text}
            </h4>
            <div className="rich-text leading-normal mt-4 mb-6">
              <RichText content={item.copy} />
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default IconGrid;
