/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useState, useContext } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import { RichText } from "prismic-reactjs";
import { Transition } from "@headlessui/react";
import validator from "validator";
import { trackEvent, identifyUser, cookies } from "@analytics";
import { userToken, newsletterSignup } from "@hubspot";
import { ArrowLink } from "@ui";
import { HeaderContext } from "@context";
import { detect, LocalStorage } from "@utils";

const EmailSignup = React.memo(({ content }) => {
  const { setNotifications, currentLocation } = useContext(HeaderContext);
  const [emailError, setEmailError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    id: cookies?._crm_id || cookies?._ajs_user_token,
    token: userToken(),
    portalId: detect.hubspotPortalId(),
    formId: detect.newsletter().formId, // https://app.hubspot.com/submissions/43908954/form/375e951b-1feb-4f76-8120-12479783c2db/
    location: currentLocation,
    webhook: detect.newsletter().webhook,
  });
  const { trackingEvent } = content;
  const clearForm = () => {
    setFormData({});
  };

  const handleSuccess = () => {
    setLoading(false);
    const uid = new Date().getTime();
    setNotifications(prevState => ({
      ...prevState,
      [`notification-${uid}`]: {
        type: "success",
        content: RichText.asText(content?.success_message),
      },
    }));
    LocalStorage.set("_leadSubmission", JSON.stringify(formData), false);
    identifyUser({ id: formData?.email, email: formData?.email });
    trackEvent(trackingEvent || "Website email signup", {
      uid,
      formData,
    });
    clearForm();
  };

  const handleError = error => {
    setLoading(false);
    const uid = new Date().getTime();
    setNotifications(prevState => ({
      ...prevState,
      [`notification-${uid}`]: {
        type: "error",
        content: RichText.asText(content?.failure_message),
      },
    }));
    Sentry.captureException(error);
  };

  const handleSubmit = async event => {
    if (formData?.email) {
      event.preventDefault();
      setLoading(true);
      const subscribed = newsletterSignup({ ...formData });
      if (subscribed) {
        handleSuccess();
      } else {
        handleError(subscribed?.error);
      }
    }
  };

  const handleEmail = email => {
    if (validator.isEmail(email)) {
      setEmailError(null);
      setFormData({
        ...formData,
        email,
      });
    } else {
      setEmailError(
        `<span role="img">⚠️</span> Please enter a valid email address`
      );
    }
  };

  const textColor = content.lightTheme ? "text-white" : "text-teal";
  const borderColor = content.lightTheme ? "border-white" : "border-gray";

  return (
    <form
      id="email-signup"
      name="email-signup"
      onSubmit={handleSubmit}
      className="relative"
    >
      <label
        htmlFor="email"
        className={`uppercase tracking-2 ${textColor} font-bold text-sm`}
      >
        {content.label}
      </label>
      <div className={`flex border-b-2 ${borderColor} pb-2 mt-4`}>
        <input
          disabled={loading}
          placeholder={content.placeholder}
          className={`${textColor} bg-transparent flex-grow`}
          type="email"
          id="email"
          name="email"
          required
          onChange={event => handleEmail(event.target.value)}
        />
        <ArrowLink
          type="submit"
          className="text-sm"
          textClass={textColor}
          disabled={!!emailError || loading}
          button
        >
          {content.cta}
        </ArrowLink>
      </div>
      {!!emailError && (
        <Transition
          show={!!emailError}
          as={Fragment}
          enter="transition ease-out duration-333"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-333"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <div className="flex-wrap">
            <div
              style={{
                marginBottom: 0,
                textShadow: "1px 1px #fff",
                fontSize: ".65rem",
              }}
              role="alert"
              className="text-left bg-red-100 border border-red-400 text-red-700 rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
            >
              <span dangerouslySetInnerHTML={{ __html: emailError }} />
            </div>
          </div>
        </Transition>
      )}
    </form>
  );
});

EmailSignup.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default EmailSignup;
