// TODO: Remove eslint-disable
/* eslint-disable no-unused-vars */

import React from "react";
import PropTypes from "prop-types";
import { Container } from "@layout";
import { RichText } from "@ui";

const LeadCopyBlock = ({ content, className, variant }) => {
  return (
    <Container variant={variant}>
      <div className={`rich-text leading-normal text-xl ${className}`}>
        <RichText content={content.primary.copy} />
      </div>
    </Container>
  );
};

LeadCopyBlock.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

LeadCopyBlock.defaultProps = {
  className: "",
  variant: "copy",
};

export default LeadCopyBlock;
