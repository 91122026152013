/* 

See AppHeader for layout logic.

*/

import React, { useState, useContext, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import { useCookies } from "react-cookie";
import classNames from "classnames";
import AnimateHeight from "react-animate-height";
import { Container } from "@layout";
import { ArrowLink, Icon } from "@ui";
import { HeaderContext } from "@context";

/* eslint react/prop-types: 0 */

const query = graphql`
  query AnnouncementQueryEs {
    prismic {
      site_settings(lang: "es-pr", uid: "site-settings") {
        _meta {
          lastPublicationDate
        }
        announcement {
          text
          icon
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_text
        }
      }
    }
  }
`;

const Announcement = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [cookies, setCookie] = useCookies(["lyraAnnouncementUpdatedAt"]);
  const { announcementShowing, setAnnouncementShowing, navOpen } = useContext(
    HeaderContext
  );

  const { _meta } = data.prismic.site_settings;
  const {
    text,
    link,
    link_text,
    icon,
  } = data.prismic.site_settings.announcement[0];

  const cookie = cookies.lyraAnnouncementUpdatedAt;
  const settingsUpdated =
    cookie && cookie < new Date(_meta.firstPublicationDate).getTime();

  const hide = () => {
    setVisible(false);
    setAnnouncementShowing(false);
    if (!cookie || settingsUpdated) {
      setCookie(
        "lyraAnnouncementUpdatedAt",
        new Date(_meta.firstPublicationDate).getTime(),
        { maxAge: 60 * 60 * 24 } // expires in 1 day
      );
    }
  };

  if (cookie && !settingsUpdated && visible) {
    hide();
  }

  // set cookie if announcement showing gets set to false
  useEffect(() => {
    if (announcementShowing === false) {
      setCookie(
        "lyraAnnouncementUpdatedAt",
        new Date(_meta.firstPublicationDate).getTime(),
        { maxAge: 60 * 60 * 24 } // expires in 1 day
      );
    }
  }, [announcementShowing]);

  // make sure showing state is `true` and we at least have announcement text before displaying
  if (announcementShowing && !cookie && text[0].text.length > 0) {
    setTimeout(() => {
      setVisible(true);
    }, 666);
  }

  return (
    <AnimateHeight
      duration={333}
      height={visible && text && announcementShowing && !navOpen ? "auto" : 0} // see props documentation below
    >
      <div
        id="AppAnnouncement"
        className={classNames({
          "relative transition py-4 sm:y-6 bg-black w-full z-60": true,
        })}
      >
        <Container
          className="flex text-sm sm:text-base flex-wrap items-center"
          noPadding
        >
          <div className="text-blue flex-shrink-0 px-3">
            <Icon className="w-6 h-6" iconName={icon} />
          </div>
          <div className="flex-1 font-sans font-bold text-white px-3 sm:pr-6 md:pr-12 justify-between flex flex-col sm:flex-row">
            <div>{text.length > 0 ? text[0].text : ""}</div>
            <div>
              <ArrowLink link={link} textClass="text-white xl:mr-0">
                <span className="font-sans font-bold text-white text-sm">
                  {link_text}
                </span>
              </ArrowLink>
            </div>
          </div>
          <button
            type="button"
            className="text-gray z-10 cursor-pointer flex-shrink-0 px-3"
            onClick={hide}
          >
            <Icon className="w-4 h-4" iconName="Close" />
          </button>
        </Container>
      </div>
    </AnimateHeight>
  );
};

// export default Announcement;

// and export as a static query
export default () => (
  <StaticQuery
    query={`${query}`}
    render={data => <Announcement data={data} />}
  />
);
