/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React from "react";
import { Headshot } from "@common";

const AuthorImage = React.memo(({ author, date }) => {
  const { url } = author?.photo;

  if (url != null) {
    const ShowHeadshot = () => {
      if (author?.linkedin_url) {
        return (
          <a
            href={author?.linkedin_url}
            target="_blank"
            className="transition duration-200 hover:grayscale-50 hover-grayscale"
          >
            <Headshot className="w-8 h-8 mr-3" src={url} />
          </a>
        );
      }
      return <Headshot className="w-8 h-8 mr-3" src={url} />;
    };
    const ShowName = () => {
      if (author?.linkedin_url) {
        return (
          <a
            href={author?.linkedin_url}
            target="_blank"
            className="inline-flex"
          >
            <span className="font-sans font-bold text-black hover:text-blue-dark hover:underline text-xs mr-1">
              {author.full_name}
            </span>
          </a>
        );
      }
      return (
        <span className="font-sans font-bold text-black text-xs mr-1">
          {author.full_name}
        </span>
      );
    };
    return (
      <div className="flex items-center mt-4 md:mt-8">
        <ShowHeadshot />
        <ShowName />
        <span className="font-sans text-black text-xs">{`on ${date}`}</span>
      </div>
    );
  }
  return null;
});

export default AuthorImage;
