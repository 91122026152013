/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";

// build lazy iamge
const LazyImage = React.memo(
  ({ src, ixurl, loaded, fit, cached, shadow, onLoadImage }) => {
    return (
      <img
        src={ixurl}
        className={classNames({
          "opacity-0": !loaded,
          "opacity-100": loaded,
          "object-cover": fit === "cover",
          "object-contain": fit === "contain",
          transition: !cached,
          shadow,
          "absolute block transition w-full h-full inset-0 z-10": true,
        })}
        onLoad={onLoadImage}
        alt={src.substring(src.lastIndexOf("/") + 1)}
      />
    );
  }
);

export default LazyImage;
