/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { Icon } from "@ui";
import { PrismicLink } from "@utilities";
import AuthorImage from "./AuthorImage";
import BlogImage from "./BlogImage";

const BlogCard = React.memo(({ content, tag: Tag, className: _className }) => {
  const {
    title,
    category,
    author,
    primary_image,
    summary,
    _meta,
    published_date,
  } = content;

  const className = classNames(
    {
      "bg-white p-8 hover:text-blue-dark": true,
    },
    _className
  );

  const displayDate = published_date
    ? moment(published_date).format("MMMM Do YYYY")
    : moment(_meta.firstPublicationDate).format("MMMM Do YYYY");

  return (
    <Tag className={`flex flex-col block ${className}`}>
      <div className="flex flex-wrap mb-4">
        <PrismicLink
          route={`/blog/${_meta.uid}`}
          className="w-full sm:w-1/2 md:w-3/4 lg:w-1/3 mb-4 sm:mb-0 sm:mr-4 md:mr-8 md:h-40 transition duration-200 hover-grayscale"
        >
          <BlogImage image={primary_image} />
        </PrismicLink>
        <div className="flex-1">
          <div className="flex items-center mb-4">
            <span className="block text-blue w-6 h-6 mr-2">
              <Icon
                className="w-4 h-4"
                iconName={`Icon${category.replace(" ", "")}`}
              />
            </span>
            <PrismicLink
              link={{
                url: `/blog/${category.replace(/\s+/g, "-").toLowerCase()}`,
              }}
              className="font-sans font-bold uppercase text-sm text-blue tracking-2 hover:text-black hover:underline-2-blue underline-2-blue"
              activeClass="underline-2-blue"
            >
              {category}
            </PrismicLink>
          </div>
          <PrismicLink
            route={`/blog/${_meta.uid}`}
            className="hover:text-blue-dark hover:underline"
            activeClass="underline-blue"
          >
            <h2 className="font-sans font-bold text-black text-2xl leading-tight hover:text-blue-dark">
              {title}
            </h2>
          </PrismicLink>
        </div>
      </div>
      <PrismicLink
        route={`/blog/${_meta.uid}`}
        className="hover:text-blue-dark"
        activeClass="underline-2"
      >
        <div className="font-sans text-black antialiased hover:text-blue-dark">
          {summary && summary[0].text}
        </div>
      </PrismicLink>
      <AuthorImage author={author} date={displayDate} />
    </Tag>
  );
});

const imageProps = PropTypes.shape({
  alt: PropTypes.string,
  url: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
});

BlogCard.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    published_date: PropTypes.string,
    author: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
      photo: imageProps,
    }),
    primary_image: imageProps,
    summary: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      })
    ),
    _meta: {
      uid: PropTypes.string.isRequired,
      firstPublicationDate: PropTypes.string.isRequired,
    }.isRequired,
  }).isRequired,
  tag: PropTypes.string,
  className: PropTypes.string,
};

BlogCard.defaultProps = {
  tag: "li",
  className: "",
};

export default BlogCard;
