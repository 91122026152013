/* eslint-disable global-require */
module.exports = {
  purge: false,
  theme: {
    fontSize: {
      xs: ".75rem",
      sm: ".875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.5rem",
      "2-5xl": "1.75rem",
      "3xl": "2rem",
      "4xl": "2.5rem",
      "5xl": "3rem",
      "6xl": "4rem",
      "7xl": "5rem",
    },
    screens: {
      xxs: { max: "400px" }, // for super small screens
      sm: "750px", // bigger than most phones
      md: "900px",
      lg: "1200px",
      xl: "1700px", // larger than 15" macbook pro
      xxl: "2000px",
    },
    fontFamily: {
      sans: ["Mukta", "sans-serif"],
    },
    // Transforms
    scale: {
      "-1.25": "-1.25",
      "-1": "-1",
      0: "0",
      "1.25": "1.25",
    },
    translate: {
      none: "0",
      lift: "-.25rem",
      "-1/4": "-25%",
      "1/4": "25%",
      "1/2": "50%",
      "3/4": "75%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "-50px": "-50px",
      "50px": "50px",
      full: "100%",
      "-full": "-100%",
    },
    rotate: {
      "-180": "-180deg",
      "-90": "-90deg",
      "-30": "-30deg",
      "30": "30deg",
      "60": "60deg",
      "90": "90deg",
      "135": "135deg",
      "180": "180deg",
      "270": "270deg",
      "3d": ["0", "1", "0.5", "45deg"],
    },
    maxWidth: {
      xs: "20rem",
      sm: "24rem",
      md: "28rem",
      lg: "32rem",
      xl: "36rem",
      "2xl": "44rem",
      "3xl": "52rem",
      "4xl": "60rem",
      "5xl": "68rem",
      "6xl": "76rem",
      "7xl": "84rem",
      "8xl": "92rem",
      full: "100%",
    },
    extend: {
      fill: theme => theme("colors"),
      transitionDuration: {
        "1700": "1700ms",
        "2000": "2000ms",
      },
      zIndex: {
        "-10": "-10",
        "60": "60",
      },
      boxShadow: {
        default: "0 0 10px rgba(0, 0, 0, .08)",
        // needed for manual transitions
        none: "0 0 10px rgba(0, 0, 0, 0)",
        lift: "0 0.25rem 15px rgba(0, 0, 0, .075)",
        nav: "0 0 10px rgba(0, 0, 0, .1)",
      },
      opacity: {
        3: "0.03",
        8: "0.08",
        10: ".1",
        15: ".15",
        33: ".33",
        90: ".9",
      },
      letterSpacing: {
        // calculated to px value of # @ 16px
        "-3": "-0.1875rem",
        "-2": "-0.125rem",
        "-1": "-0.0625rem",
        0: "0px",
        0.5: "0.03125rem",
        1: "0.0625rem",
        2: "0.125rem",
        3: "0.1875rem",
      },
      lineHeight: {
        // calculated to px value of # @ 16px
        normal: "1.76",
        "1": "1",
        "1.1": "1.1",
        "1.2": "1.2",
        tighter: 1.125,
        "1.3": "1.3",
        "1.4": "1.4",
        "1.5": "1.5",
        "1.6": "1.6",
        "1.7": "1.7",
        "1.8": "1.8",
        "1.9": "1.9",
        "2": "-2",
      },
      height: {
        "5/4": "125%",
        "hero-sm": "27rem",
        "min-h-hero-sm": "27rem",
      },
      minHeight: {
        "1/4-screen": "25vh",
        "1/3-screen": "33.333vh",
        "2/5-screen": "40vh",
        "3/7-screen": "43vh",
        "1/2-screen": "50vh",
        "4/7-screen": "57vh",
        "3/5-screen": "60vh",
        "2/3-screen": "66.666vh",
        "3/4-screen": "75vh",
        "hero-sm-mobile": "36rem",
        "hero-sm": "20rem",
        "min-h-hero-sm": "20rem",
        hero: "52rem",
      },
      width: {
        "7": "1.75rem",
        "1/7": "14%",
        "2/7": "28%",
        "3/7": "42%",
        "4/7": "58%",
        "5/7": "72%",
        "6/7": "86%",
      },
      borderWidth: {
        3: "3px",
      },
      borderRadius: {
        heroxs: "45px",
        herosm: "75px",
        hero: "150px",
        heromd: "300px",
      },
      colors: {
        white: "#ffffff",
        black: "#333038",
        disabled: "hsl(0, 0%, 95%)",
        gray: {
          default: "#757575",
          dark: "#757575",
          light: "#F9FAFB",
          offwhite: "#F6FAFF",
          alpha: "#f9fcff",
          beta: "#6B7280",
          delta: "#e3e7ea",
          app: "#f2f2f2",
          900: "#f9f9f9",
          menu: "#f5f5f5", // or #F9FAFB,
          form: "#f4f8fa",
        },
        blue: {
          default: "#2D80EF",
          light: "#F4F8FE",
          offwhite: "#F6FAFF",
          alpha: "#C0D8FA14",
          dark: "#133095",
        },
        purple: {
          default: "#8D8DE0",
          dark: "#381d52",
        },
        darkPurple: "#381d52",
        coral: "#FF736E",
        teal: "#00ACC1",
        darkBlue: "#133095",
        red: {
          default: "#C53030",
          100: "#fff5f5",
          200: "#fed7d7",
          600: "#e53e3e",
          700: "#C53030",
          900: "#600c0c",
        },
        green: "#09964F",
      },
      // spacing: {
      //   '96': '24rem',
      //   '128': '32rem',
      // }
    },
  },
  variants: {
    margin: ["hover", "group-hover", "responsive"],
    opacity: ["hover", "responsive", "disabled"],
    backgroundColor: ["responsive", "hover", "focus", "disabled"],
    translate: ["responsive", "hover", "group-hover"],
    scale: ["responsive", "hover"],
    cursor: ["responsive", "disabled"],
  },
  plugins: [
    require("tailwindcss-transition")({
      standard: "all .333s ease-in-out",
      transitions: {
        fast: "all .165s ease-in-out",
        md: "all .333s ease-in",
        slow: "all .666s ease-in-out",
        slower: "all 1s ease-in-out",
        slowest: "all 1.333s ease-in-out",
        "slow-delay": "all .75s ease-in-out .166s",
        "full-delay": "all .333s ease-in-out .333s",
        delay: "all .333s ease-in-out .25s",
        "accordion-delay": "all .001s linear .1665s",
        linear: "all .333s linear",
      },
    }),
    require("tailwindcss-transforms")({
      "3d": false, // defaults to false
    }),
  ],
};
