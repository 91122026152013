/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/button-has-type */
/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import classNames from "classnames";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { PrismicLink } from "@utilities";

export default React.memo(({ enforceLang, setLang, setEnforceLang }) => {
  const [shake, setShake] = useState(enforceLang);
  const resetLang = language => {
    setLang(language);
    setEnforceLang(!!language);
    setShake(false);
  };
  return (
    <>
      <div className="cursor-pointer text-white py-2 opacity-100 my-0 mr-4 md:mr-6 lg:mr-8 text-base hover:text-gray-delta ml-auto sm:flex transition">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button
              className={classNames({
                shaking: shake,
                "transition group rounded-md inline-flex items-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue hover:text-blue": true,
              })}
              onClick={() => setShake(false)}
            >
              {enforceLang ? (
                <span className="hover:opacity-50" role="img">
                  🇵🇷
                </span>
              ) : (
                <span className="hover:opacity-50" role="img">
                  🇺🇸
                </span>
              )}
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5 text-white group-hover:text-gray-offwhite hover:text-blue"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Menu.Items className="absolute mt-2 w-56 origin-top-right divide-y divide-gray rounded-md bg-white shadow-lg ring-1 ring-black focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <PrismicLink
                    route="/"
                    activeclass="bg-gray-light text-blue hover:text-purple"
                  >
                    <button
                      onClick={() => resetLang(null)}
                      className={`${
                        active || !enforceLang
                          ? "bg-gray-light text-blue"
                          : "text-black"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-gray-menu`}
                    >
                      <span role="img">🇺🇸</span> &nbsp; English
                    </button>
                  </PrismicLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <PrismicLink
                    route="/es-pr/home"
                    activeclass="bg-gray-light text-blue hover:text-purple"
                  >
                    <button
                      onClick={() => resetLang("es-pr")}
                      className={classNames({
                        "bg-gray-light text-blue": active || enforceLang,
                        "text-black group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-gray-menu": true,
                      })}
                    >
                      <div
                        className={classNames({
                          "shadow-animate": shake,
                        })}
                      >
                        <span role="img">🇵🇷</span> &nbsp; Español
                      </div>
                    </button>
                  </PrismicLink>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
    </>
  );
});
