/* eslint-disable react/prop-types */
import React from "react";
import { RichText } from "@ui";

import { Container } from "@layout";
import { RandomShapes } from "@decoration";
import { ShapesPatternRepeat } from "@svg";

const HighlightCard = React.memo(({ content, className }) => {
  return (
    <div
      className={`highlight-card bg-white relative py-12 md:pt-24 z-10 xl:pt-32 ${className}`}
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <ShapesPatternRepeat
          className="opacity-3 text-blue stroke-current h-full w-full"
          dimensions={[10, 10]}
        />
      </div>
      <Container className="-mb-24" variant="sm">
        <RandomShapes
          opacity=".1"
          shapes={[
            { Circle: "coral" },
            { Square: "blue" },
            { FourthCircle: "coral" },
            { FourthCircle: "blue" },
          ]}
        />
        <div className="bg-white rounded-xl text-center shadow p-12 md:py-16">
          <h3 className="text-center uppercase tracking-3 mb-4 text-sm sm:text-base font-bold text-blue">
            {content.subheading[0].text}
          </h3>
          <h2 className="text-center leading-tight text-2xl sm:text-4xl font-bold">
            {content.heading[0].text}
          </h2>
          <div className="rich-text text-lg md:text-xl sm:px-12 mt-8">
            <RichText content={content.copy} />
          </div>
        </div>
      </Container>
    </div>
  );
});

export default HighlightCard;
