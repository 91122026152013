import React from "react";
import PropTypes from "prop-types";
import * as SVG from "@svg";

const IconAndCaption = React.memo(({ content, color }) => {
  const Icon = SVG[content.icon];
  return (
    <div
      className={`flex flex-col items-center bg-white shadow pt-6 pb-4 px-6 xl:px-8 border-b-4 border-${color} h-full rounded-2xl`}
    >
      <div className="mb-6">
        <span className={`text-${color}`}>
          <Icon className="w-12 h-12 md:w-16 md:h-16 text-blue stroke-current fill-current flex items-center justify-center" />
        </span>
      </div>
      <div className="text-center font-bold text-sm sm:text-base xl:text-xl leading-tight">
        <span>{content.copy}</span>
      </div>
    </div>
  );
});

IconAndCaption.propTypes = {
  content: PropTypes.objectOf(PropTypes.string).isRequired,
  color: PropTypes.string.isRequired,
};

export default IconAndCaption;
