/* eslint-disable react/prop-types */
/* eslint-disable valid-typeof */
import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { HeaderContext } from "@context";
import { Hero, Slices, Container } from "@layout";
import { PageTransition } from "@components";
import { MetaDataEs, EopCta, EmbedUrl } from "@common";
import { BottomCurveGray } from "@svg";
import { detect } from "@utils";

const PageEs = props => {
  // eslint-disable-next-line react/destructuring-assignment
  const { data } = props;
  const { prismic } = data;
  const { pages } = prismic;
  const { loadSDTSunset, setSunrisePage, setBlogLayout } = useContext(
    HeaderContext
  );
  const { page: sunrise } = loadSDTSunset;
  const localPageSunrise = pages.sdt_sunset_page;
  const variant = pages.hero[0].hero_theme === "white" ? "light" : "dark";
  const sunriseVariant = sunrise || localPageSunrise ? "green" : variant;
  const sectionBgBlueColor =
    !sunrise &&
    !localPageSunrise &&
    pages &&
    pages.hero &&
    pages.hero[0] &&
    (pages.hero[0].hero_heading &&
      pages.hero[0].hero_heading[0] &&
      pages.hero[0].hero_heading[0].text === "") &&
    (pages.hero[0].hero_copy &&
      pages.hero[0].hero_copy[0] &&
      pages.hero[0].hero_copy[0].text === "");
  const noHeader =
    pages.hero[0].hero_heading &&
    pages.hero[0].hero_heading[0] &&
    pages.hero[0].hero_heading[0].text === "";
  const bgColor =
    // eslint-disable-next-line no-nested-ternary
    sunrise || localPageSunrise
      ? "bg-white"
      : sectionBgBlueColor
      ? "bg-blue"
      : "bg-white";
  const showCta =
    pages.cta_eop &&
    !!pages.cta_eop[0] &&
    (!!pages.cta_eop[0].heading || !!pages.cta_eop[0].subheading) &&
    (!!pages.cta_eop[0].arrow_link_text || !!pages.cta_eop[0].button_text);
  const embedForm = pages.embed_form;
  const embedUrl = !!embedForm;
  const hasWindow = detect.browser().window;

  useEffect(() => {
    setSunrisePage(localPageSunrise);
    setBlogLayout(false);
  }, [localPageSunrise]);

  const PageHero = () => {
    if (!sectionBgBlueColor || !noHeader) {
      return (
        <Hero
          content={pages.hero[0]}
          variant={sunriseVariant}
          gradient={pages.hero[0].hero_gradient}
          image={pages.hero_background_image}
          centered
        />
      );
    }
    return (
      <Hero
        content={pages.hero[0]}
        size="xs"
        variant="light"
        gradient={pages.hero[0].hero_gradient}
        image={pages.hero_background_image}
        centered
      />
    );
  };

  const PageEmbedSlices = () => {
    return embedForm.map((embed, index) => {
      const embedBeforeContent = embed?.show_before_content;
      return (
        <div key={index}>
          {embedUrl && embedBeforeContent && (
            <EmbedUrl
              hasWindow={hasWindow}
              embed_form={embed}
              key={`${pages._meta.uid}-1-${index}`}
            />
          )}
          <Slices
            key={`${pages._meta.uid}-2-${index}`}
            content={pages.body}
            uid={pages._meta.uid}
            gradient={pages.hero[0].hero_gradient}
            columns={Number(pages.columns)}
            xSpacing="xs:mx-auto md:mx-24"
          />
          {embedUrl && !embedBeforeContent && (
            <EmbedUrl
              hasWindow={hasWindow}
              embed_form={embed}
              key={`${pages._meta.uid}-3-${index}`}
            />
          )}
        </div>
      );
    });
  };

  return (
    <>
      <MetaDataEs content={pages} type="webpage" />
      <PageTransition theme={sunriseVariant}>
        <article className="min-h-3/4-screen relative">
          <PageHero />
          <section className={bgColor}>
            <PageEmbedSlices />
            {showCta && (
              <section className="bg-blue-offwhite mt-12 pb-24 pt-12">
                <BottomCurveGray className="w-full curve-cta" />
                <Container variant="copy">
                  <EopCta
                    content={pages.cta_eop[0]}
                    sunrise={sunrise || localPageSunrise}
                    gradient={pages.hero[0].hero_gradient}
                  />
                </Container>
                <div
                  className={`bg-blue-offwhite ${bgColor} h-24 relative z-0`}
                />
              </section>
            )}
          </section>
        </article>
      </PageTransition>
    </>
  );
};

export default PageEs;

export const query = graphql`
  query PageEsQuery($uid: String!) {
    prismic {
      pages(lang: "es-pr", uid: $uid) {
        _meta {
          uid
          lang
          alternateLanguages {
            uid
            lang
          }
        }
        path
        title
        hide
        sdt_sunset_page
        meta_title
        meta_image
        meta_description
        meta_no_index
        columns
        body {
          ... on PRISMIC_PagesBodyCopy_block {
            type
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_PagesBodyImage_block {
            type
            label
            primary {
              image_layout
              remove_white_space
            }
            fields {
              caption
              image
            }
          }
          ... on PRISMIC_PagesBodyPull_quote_block {
            type
            label
            primary {
              citation
              quote
            }
          }
          ... on PRISMIC_PagesBodyLead_copy_block {
            type
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_PagesBodyVideo_block {
            type
            label
            primary {
              video_caption
              video_url
            }
          }
          ... on PRISMIC_PagesBodyEmail_signup_block {
            type
            label
            primary {
              background_image
              failure_message
              heading
              submit_button_text
              submit_button_color
              icon
              subtitle
              success_message
              list_id
              promo_code
              promo_memo
              tracking_event
              form_collector
              form_id
              form_url
              webhook_url
              form_embed_method
              form_height
              upload_widget
              recaptcha_widget
              require_phone_number
              collect_volume
              show_messenger
              volume_low_title
              volume_low_subtitle
              volume_tooltip
              locations_tooltip
            }
            fields {
              label
              value
            }
          }
          ... on PRISMIC_PagesBodyCta_block {
            type
            label
            primary {
              background
              icon_image
              title
              paragraph
              button_label
              button_color
              button_intercom_show_space
              button_intercom_start_survey
              button_link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
                ... on PRISMIC_Design {
                  _meta {
                    uid
                    lang
                  }
                }
                ... on PRISMIC_Posts {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_For_manufacturers {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pages {
                  path
                  title
                  meta_title
                  _linkType
                  _meta {
                    uid
                    lang
                  }
                }
                ... on PRISMIC_Contact {
                  _meta {
                    uid
                    lang
                  }
                }
                ... on PRISMIC_Pricing {
                  _meta {
                    uid
                    lang
                  }
                }
                ... on PRISMIC_Permit_packages {
                  title
                  _meta {
                    uid
                    lang
                  }
                }
                ... on PRISMIC_About {
                  _meta {
                    uid
                    lang
                  }
                }
                ... on PRISMIC_Blog {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Home {
                  title
                  _meta {
                    uid
                    lang
                  }
                }
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
          }
        }
        title
        hero_background_image
        hero {
          hero_button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          hero_button_text
          hero_heading
          hero_copy
          hero_theme
          hero_gradient
          hero_slim
          hero_hide_patterns
          hero_dark_text
          hero_hide
          hero_button_color
        }
        cta_eop {
          tracking_event
          heading
          subheading
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
        }
        embed_form {
          embed_url_or_code
          add_embed_selection
          embed_code
          embed_code_2
          typeform
          hubspot
          calendly
          apply_blue_bg
          show_before_content
          locked_page
          require_uid_or_message_id
          height
          utm_campaign
          utm_term
          utm_content
          utm_source
          heading
          text
          link_text
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              meta_title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              meta_title
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              meta_title
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              meta_title
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              meta_title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              meta_title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              meta_title
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              meta_title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              meta_title
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
      }
    }
  }
`;
