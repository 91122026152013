import React from "react";
import PropTypes from "prop-types";
import { useUID } from "react-uid";
import { IconAndCaption } from "@common";

const CardGroup = React.memo(({ content, color }) => {
  return (
    <div className="flex flex-wrap">
      {content.map((value, index) => {
        const uid = useUID();
        const Placeholder = () => {
          if ((index + 4) % 4 === 0) {
            return <div className="hidden md:block md:w-1/7" />;
          }
          return null;
        };
        return (
          <React.Fragment key={`${index}card-${uid}`}>
            <Placeholder />
            <div className="p-3 xxs:w-full w-1/2 md:w-3/7">
              <IconAndCaption color={color} content={value} />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
});

CardGroup.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string.isRequired,
};

export default CardGroup;
