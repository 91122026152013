/* eslint-disable react/prop-types */
import React, { useState } from "react";

import PropTypes from "prop-types";
import Modal from "react-modal";
import classNames from "classnames";
import { RichText, Button, ArrowLink, Icon } from "@ui";
import { Container } from "@layout";

import { Close, BottomCurveGray } from "@svg";
import LeftColumn from "./LeftColumn";
import SubHeading from "./SubHeading";
import ModalContent from "./ModalContent";
import Heading from "./Heading";

// bind modal to app
if (typeof window !== "undefined") {
  Modal.setAppElement(document.getElementById("#App"));
}

const PageIntro = React.memo(
  ({ content, media, className, curve, white, float }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => {
      setModalIsOpen(true);
    };
    const afterOpenModal = () => {
      // setModalIsOpen(true);
    };
    const closeModal = () => {
      document.getElementsByClassName(
        "ReactModal__Overlay"
      )[0].style.opacity = 0;
      setTimeout(() => {
        setModalIsOpen(false);
      }, 333);
    };
    return (
      <>
        <div
          className={`${classNames({
            "xl:py-24 py-12": content.icon == null,
            "md:py-16": content.icon != null,
            "bg-white": white,
            "bg-blue-offwhite": !white,
            "py-12": true,
          })} ${className}`}
        >
          {curve && <BottomCurveGray className="w-full curve-intro" />}
          <Container>
            <div className="flex-wrap flex -mx-6">
              <div className="sm:w-1/2 xl:w-2/5 xl:mr-auto px-6">
                <div>
                  <Icon
                    className="text-purple w-20 h-20 mb-6"
                    iconName={content.icon}
                  />
                </div>
                <SubHeading content={content} />
                <Heading content={content} />
                <RichText content={content.copy} />
                <div
                  className={classNames({
                    hidden: content.button_link == null,
                    "my-6": true,
                  })}
                >
                  <Button
                    variant="md"
                    link={content.button_link}
                    className="bg-black py-3 rounded-3xl text-sm px-8 text-white font-bold hover:bg-white hover:border-blue hover:text-blue border-3 border-transparent hover:opacity-100 cursor-pointer transition ease-out duration-333 translate-y-1 font-bold"
                  >
                    {content.button_text}
                  </Button>
                </div>
                <div
                  className={classNames({
                    hidden: content.arrow_link == null,
                    "mt-4": true,
                  })}
                >
                  <ArrowLink className="text-sm" link={content.arrow_link}>
                    {content.arrow_text}
                  </ArrowLink>
                </div>
              </div>
              <div className="w-full sm:w-1/2 xl:w-4/7 px-6 relative">
                {content.intro_image && (
                  <LeftColumn
                    image={content.intro_image}
                    media={media}
                    openModal={openModal}
                    lessOffset={content.icon != null}
                    float={float}
                  />
                )}
              </div>
            </div>
          </Container>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          // false positive
          ariaHideApp={false}
          className={classNames({
            "h-full": typeof media !== "undefined" && media.embed_code !== null,
            "w-full": true,
          })}
          style={{}}
        >
          <button
            type="button"
            className="p-4 fixed top-0 right-0"
            onClick={closeModal}
          >
            <Close />
          </button>
          <ModalContent media={media} />
        </Modal>
      </>
    );
  }
);

PageIntro.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
  ).isRequired,
  curve: PropTypes.bool,
  float: PropTypes.bool,
};

PageIntro.defaultProps = {
  curve: true,
  float: false,
};

export default PageIntro;
