/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { ExtendedEmailSignup } from "@contact";

const EmailSignupBlock = React.memo(
  ({ uid, content, hideForm, gradient, volume_tooltip, locations_tooltip }) => {
    const hideFormCollector = content.primary.form_collector;
    const collectBySignupForm = hideFormCollector || hideForm;
    return collectBySignupForm ? (
      <></>
    ) : (
      <ExtendedEmailSignup
        formId={content.primary.form_id}
        formUrl={content.primary.form_url}
        webhookUrl={content.primary.webhook_url}
        embedMethod={content.primary.form_embed_method}
        formHeight={content.primary.form_height}
        icon={content.primary.icon}
        heading={content.primary.heading[0].text}
        subtitle={content.primary.subtitle}
        buttonText={content.primary.submit_button_text}
        buttonColor={content.primary.submit_button_color}
        orientation={content.primary.orientation}
        successMessage={content.primary.success_message}
        failureMessage={content.primary.failure_message}
        backgroundImageUrl={content.primary.background_image.url}
        listId={content.primary.list_id}
        trackingEvent={content.primary.tracking_event}
        promoCode={content.primary.promo_code}
        promoMemo={content.primary.promo_memo}
        promoReferral={uid}
        requirePhoneNumber={content.primary.require_phone_number}
        showPhoneNumber={content.primary.show_phone_number}
        collectVolume={content.primary.collect_volume}
        volumeLowTitle={content.primary.volume_low_title}
        volumeLowSubtitle={content.primary.volume_low_subtitle}
        volumeTooltip={content?.primary?.volume_tooltip || volume_tooltip}
        locationsTooltip={
          content?.primary?.locations_tooltip || locations_tooltip
        }
        showMessengerButton={content?.primary?.show_messenger}
        fields={content.fields}
        uid={uid}
        gradient={gradient}
        noShapes
      />
    );
  }
);

EmailSignupBlock.defaultProps = {
  hideForm: false,
  volume_tooltip: "Minimum annual commitment is 20/year on the Lyra platform",
  locations_tooltip:
    "Select the markets that your company operates in that Lyra currently supports",
};

EmailSignupBlock.propTypes = {
  hideForm: PropTypes.bool,
  volume_tooltip: PropTypes.string,
  locations_tooltip: PropTypes.string,
};

export default EmailSignupBlock;
