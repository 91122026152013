/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import { EmailSignup } from "@contact";

export default React.memo(({ signup, noPadding, className }) => {
  const emailSignup = signup != null && signup[0];
  const defaultListId = process.env.GATSBY_MAILCHIMP_LIST_ID;
  const classes = classNames(className, {
    "rich-text text-lg lg:text-xl my-8 lg:my-10 antialiased": true,
    "md:pr-24 lg:pr-12": !noPadding,
  });
  if (emailSignup) {
    const signupFormatData = emailSignup && {
      list_id: emailSignup.list_id || defaultListId,
      label: emailSignup.heading[0].text || "",
      placeholder: emailSignup.subtitle[0].text || "Sign up for our newsletter",
      cta: emailSignup.submit_button_text || "Sign Up",
      failure_message:
        emailSignup.failure_message ||
        "Oops. Looks like something went wrong. Double check your email address and try again.",
      success_message:
        emailSignup.success_message ||
        "Signup successful. Stay tuned for the latest news.",
      tracking_event: emailSignup.tracking_event || "Blog newsletter signup",
      lightTheme: signup?.lightTheme,
    };
    return (
      <div className={classes}>
        <EmailSignup content={signupFormatData} />
      </div>
    );
  }
  return null;
});
