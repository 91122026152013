/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// import styles index
const prismicGraphql = require("gatsby-source-prismic-graphql");
const linkResolver = require("./src/utils/previewLinkResolver");
const pcss = require("./src/pcss/index.pcss");

exports.onClientEntry = () => {
  // from https://prismic.io/docs/reactjs/getting-started/prismic-gatsby
  // When working with field types such as Link or Rich Text, the prismic.io react.js kit will need to generate links to documents within your website.
  const { registerLinkResolver } = prismicGraphql;
  const previewLinkResolver = linkResolver.resolver;
  registerLinkResolver(previewLinkResolver);
  return pcss;
};

// Logs when the client route changes
exports.onRouteUpdate = ({ prevLocation }) => {
  // console.log("new pathname", location.pathname);
  // console.log("old pathname", prevLocation ? prevLocation.pathname : "sunrise");
  const previousPath = prevLocation ? prevLocation.pathname : null;
  window.previousPath = previousPath;
};
