/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";

const LQIP = React.memo(({ src, shadow, fit, loaded, LQIPUrl, imageUid }) => {
  const LQIPStyles = {
    filter: "blur(20px)",
  };
  return (
    <img
      id={`lqip_${imageUid}`}
      className={classNames({
        shadow,
        "object-cover": fit === "cover",
        "object-contain": fit === "contain",
        "opacity-0": loaded,
        "lqip block relative transition w-full h-full z-0": true,
      })}
      src={`${LQIPUrl}`}
      alt={`LQIP_${src.substring(src.lastIndexOf("/") + 1)}`}
      style={LQIPStyles}
    />
  );
});

export default LQIP;
