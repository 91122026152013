/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import { useUID } from "react-uid";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import classNames from "classnames";
import { Container } from "@layout";
import { Previous } from "@svg";
import Item from "./CarouselWithPreviews/Item";

const CarouselWithPreviews = React.memo(({ content }) => {
  const Carousel = useRef();
  // eslint-disable-next-line react/prop-types
  return (
    <Container
      noPadding
      variant="md"
      className="relative carousel-with-previews"
    >
      <div className="carousel-button absolute left-0 flex items-center pl-3 z-10">
        <button
          type="button"
          className={classNames({
            "w-12 h-12 rounded-md pr-px opacity-100 hover:scale-1.25 cursor-pointer transition-fast text-white bg-black flex flex-col items-center justify-center text-6xl": true,
          })}
          onClick={() => Carousel.current.slidePrev()}
        >
          <Previous />
        </button>
      </div>
      <div className="relative z-0">
        <Container variant="copy">
          <div style={{ width: "300%", transform: "translateX(-33.33%)" }}>
            <AliceCarousel
              ref={Carousel}
              buttonsDisabled
              dotsDisabled
              duration={200}
              responsive={{ 0: { items: 3 } }}
            >
              {content.map(slide => {
                const uid = useUID();
                return <Item key={uid} slide={slide} />;
              })}
            </AliceCarousel>
          </div>
        </Container>
      </div>
      <div className="carousel-button absolute right-0 flex items-center pr-3 z-10">
        <button
          type="button"
          className={classNames({
            "-scale-1 w-12 h-12 pr-px rounded-md opacity-100 hover:-scale-1.25 cursor-pointer transition-fast text-white bg-black flex flex-col items-center justify-center text-6xl": true,
          })}
          onClick={() => Carousel.current.slideNext()}
        >
          <Previous />
        </button>
      </div>
    </Container>
  );
});

export default CarouselWithPreviews;
