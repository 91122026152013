/* eslint-disable react/prop-types */
import React from "react";
import { ArrowLink } from "@ui";

const CTAArrowLInk = React.memo(({ content }) => {
  if (content.arrow_link !== null) {
    return (
      <div className="mt-6">
        <ArrowLink
          className="text-sm"
          link={content.arrow_link}
          textClass="text-white"
        >
          {content.arrow_link_text}
        </ArrowLink>
      </div>
    );
  }
  return null;
});

export default CTAArrowLInk;
