/* eslint-disable no-console */
import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, RichText } from "@ui";
import { Container } from "@layout";
import { PrismicLink } from "@utilities";
import { open } from "@hubspot";

const CtaBlock = ({ content, className, darkTheme, variant, noPadding }) => {
  const [gradient] = useState(content?.primary?.background);
  const link = content?.primary?.button_link;
  const readyKey = `${content?.primary?.button_label
    .replace(" ", "-")
    .replace(" ", "-")
    .replace(" ", "-")
    .replace("→", "-")}-cta`;
  const key = readyKey.replace(" ", "");
  const gradientColor = gradient ? `gradient-${gradient}` : "gradient-light";
  const bgColor = darkTheme ? "bg-blue-offwhite" : gradientColor;
  const buttonColor = content?.primary?.button_color
    ? `bg-${content?.primary?.button_color}`
    : "bg-black";
  const padding = noPadding ? "py-5 md:py-3 lg:py-6" : "p-5 md:p-3 lg:p-6";
  const hasopen = () =>
    content?.primary?.button_intercom_show_space
      ? open(content?.primary?.button_intercom_show_space)
      : null;
  const hasOnClick = () => hasopen();

  const ActiveLink = () => (
    <PrismicLink
      link={link}
      className={`items-center text-center block text-center transition ${className} ${padding}`}
      id={key}
    >
      {content?.primary?.title && (
        <div
          className={classNames({
            "my-4": !content?.primary?.title,
            "lg:text-xl": !noPadding,
            "lg:text-md mb-4": noPadding,
            "items-center text-center rich-text max-w-xl mx-auto xs:text-xs": true,
          })}
        >
          <RichText content={content?.primary?.title} />
        </div>
      )}
      {content?.primary?.paragraph && (
        <div
          className={classNames({
            "my-4": !content?.primary?.paragraph,
            "lg:text-xl": !noPadding,
            "lg:text-md": noPadding,
            "items-center text-center rich-text max-w-xl mx-auto xs:text-xs": true,
          })}
        >
          <RichText content={content?.primary?.paragraph} />
        </div>
      )}
      <div className="items-center text-center mt-10">
        <Button
          bgClass={buttonColor}
          textClass="text-white"
          hoverState={["opacity-75"]}
          rounded="rounded-lg lg:rounded-3xl"
        >
          {content?.primary?.button_label || "Get in touch"}
        </Button>
      </div>
    </PrismicLink>
  );
  const ClickButton = () => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid
    <a
      className={`cursor-pointer items-center text-center block text-center transition ${className} ${padding}`}
      key={key}
      id={key}
      onClick={() => hasOnClick()}
    >
      <div className="items-center text-center rich-text max-w-xl mx-auto text-xl my-4">
        <RichText content={content?.primary?.title} />
      </div>
      <div className="items-center text-center rich-text max-w-xl mx-auto text-xl my-4">
        <RichText content={content?.primary?.paragraph} />
      </div>
      <div className="items-center text-center mt-10">
        <Button
          bgClass={buttonColor}
          textClass="text-white"
          hoverState={["opacity-75"]}
          rounded="rounded-lg lg:rounded-3xl"
        >
          {content?.primary?.button_label || "Get in touch"}
        </Button>
      </div>
    </a>
  );

  return (
    <Container
      variant={variant}
      className={classNames(`${bgColor}`, {
        "text-white": gradient,
        "text-black": !gradient,
        "m-8 lg:mx-auto relative z-10 max-w-3xl items-center text-center block text-center border-white border-l-4 border-r-4 lg:rounded-xl transition shadow-lg hover:translate-y-lift hover:shadow-lift": true,
      })}
    >
      {link ? <ActiveLink /> : <ClickButton />}
    </Container>
  );
};

CtaBlock.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  className: PropTypes.string,
  darkTheme: PropTypes.bool,
  variant: PropTypes.string,
  noPadding: PropTypes.bool,
};

CtaBlock.defaultProps = {
  className: "",
  darkTheme: false,
  variant: "copy",
  noPadding: false,
};

export default CtaBlock;
