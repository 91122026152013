/* eslint-disable no-console */
/* 
  This is the core analytics service with built-in support
  for installed providers. Current (6/24) providers are:
    Primary:
      - Segment (https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/)
      - Hubspot (https://developers.hubspot.com/docs/api/events/tracking-code)
    Secondary:
      - Google Tag Manager (https://developers.google.com/tag-platform/tag-manager/web)
*/
import { globalHistory } from "@reach/router";
import { loadGTM, loadSegment } from "@analytics";
import * as hubspot from "@hubspot";
import { detect, LocalStorage } from "@utils";

const hasWindow = detect.browser().window;
const currentLocation = globalHistory.location;
const { search, hostname } = currentLocation;
const getParam = name => {
  const match = RegExp(`[?&]${name}=([^&]*)`).exec(
    hasWindow && (search || window?.location?.search)
  );
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};
const getGUID = (cookie, position) => {
  let uid = cookie?.split("[.]");
  uid = uid && uid[position || 1];
  return uid;
};
const lyra_user = { ...LocalStorage.getCookie("LYRA_USER_DATA") } || {
  ...LocalStorage.get("LYRA_USER_DATA", true, true),
};

// detect and use known user id or token
export const analyticsUserToken = () => {
  const ajs_user_id = LocalStorage.getCookie("ajs_user_id"); // Segment User Token - Contains an opaque GUID to represent the current visitor.
  const _anonymous_id = LocalStorage.getCookie("ajs_anonymous_id"); // Segment Anon Token - Contains an opaque GUID to represent the current visitor.
  const token = ajs_user_id || _anonymous_id;
  return token;
};
// detect and use known user email
export const analyticsSessionEmail = email => {
  const _crm_email =
    LocalStorage.get("_crm_email") ||
    LocalStorage.getCookie("_crm_email") ||
    lyra_user?.email;
  const providedEmail = getParam("email") || getParam("e");
  const validEmail = providedEmail || email || _crm_email;

  // Return and thus, correctly identify, app user and crm account - reducing duplicates even when multiple
  // emails used along a unified journey
  if (providedEmail) {
    LocalStorage.set("_crm_email", providedEmail);
    LocalStorage.setCookie("_crm_email", providedEmail);
    return providedEmail;
  }
  return validEmail;
};
/* Analytics and Hubspot (perhaps move them?) cookies */
export const cookies = {
  domain: hostname,
  lyra_user, // from lyra app user cookie or local aes encrypted storage,
  _crm_id:
    LocalStorage.getCookie("_crm_id") ||
    LocalStorage.getSession("_crm_id", false) ||
    LocalStorage.get("_crm_id", false) ||
    lyra_user?.id,
  _crm_email: analyticsSessionEmail(),
  _crm_token: LocalStorage.get("_crm_token"),
  _ajs_user_token: analyticsUserToken(), // segment user token,
  messagesUtk: LocalStorage.getCookie("messagesUtk"), // conversation cookie
  hubspotutk: LocalStorage.getCookie("hubspotutk"), // hub (_hsq) user cookie
  _hubspot_utk:
    LocalStorage.getCookie("hubspotutk") ||
    getGUID(LocalStorage.getCookie("__hstc"), 1),
  __hstc: getGUID(LocalStorage.getCookie("__hstc"), 1), // / Main cookie for tracking visitors - domain, hubspotutk, initial timestamp, last timestamp, current timestamp, session number
  _hsq: getGUID(LocalStorage.getCookie("_hsq"), 1), // / Main cookie for tracking visitors - domain, hubspotutk, initial timestamp, last timestamp, current timestamp, session number,
  _tracking_consent: LocalStorage.getCookie("_tracking_consent"),
  lyraAllowCookies: LocalStorage.getCookie("lyraAllowCookies"),
  lyraConsentManager: LocalStorage.getCookie("lyraConsentManager"),
};
export const segmentEnabled =
  process.env.GATSBY_ANALYTICS_TRACKING === "true" &&
  (process.env.GATSBY_SEGMENT_DEV_ID && process.env.GATSBY_SEGMENT_ID) &&
  process.env.GATSBY_ANALYTICS_PROVIDER === "segment";
export const gtmEnabled =
  process.env.GATSBY_GTM_MODE === "on" &&
  (process.env.GATSBY_GTM_DEV_ID && process.env.GATSBY_GTM_ID) &&
  process.env.GATSBY_ANALYTICS_PROVIDER === "gtm";

// eslint-disable-next-line no-multi-assign, prettier/prettier
const analytics = segmentEnabled && hasWindow && window?.analytics || [];
let segmentInitialized = analytics.initialize;

export const load = (canUseDOM, allowCookies, location) => {
  if (segmentInitialized) {
    return;
  }
  if (canUseDOM && allowCookies && segmentEnabled) {
    segmentInitialized = true;
    loadSegment();
    console.log("Segment has been initialized...");
    hubspot.load(location || currentLocation);
  }
  if (
    canUseDOM &&
    allowCookies &&
    hubspot.enabled &&
    !window.HubSpotConversations
  ) {
    hubspot.load(location || currentLocation);
    console.log("Hubspot has been initialized...");
  }
  if (canUseDOM && allowCookies && gtmEnabled && !window.gtm) {
    loadGTM();
    console.log("GTM has been initialized...");
  }
};

export const loadForms = () => {
  hubspot.loadForms();
};

export const analyticsActive = segmentInitialized;
// detect and use utm parameters
const { utms, first, last, track, custom } = detect.utm();
const utm = track;
export const trackUtms = { utms, first, last, track, custom };

// This is a custom method to be sure that Hubspot, and the messenger, are tied to the best available crm record
// https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser
export const analyticsSessionId = id => {
  const userToken = analyticsUserToken();
  const providedUid = getParam("uid") || getParam("messages");
  const validUid = providedUid || id || userToken;

  // Return and thus, correctly identify, app user and crm account - reducing duplicates even when multiple
  // emails used along a unified journey
  if (providedUid) {
    LocalStorage.set("_crm_id", providedUid);
    LocalStorage.set("ajs_anonymous_id", providedUid);
    LocalStorage.set("ajs_user_id", providedUid);
    LocalStorage.setCookie("_crm_id", providedUid);
    LocalStorage.setCookie("ajs_user_id", providedUid);
    return providedUid;
  }
  return validUid;
};

/* Segment Methods */
// Segment tracking - https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
// analytics.identify([userId], [traits], [options], [callback]);
export const identifyUser = (userID, traits, options, callback) => {
  const id = analyticsSessionId(userID);
  const email = getParam("email") || traits?.email;
  const defaultOptions = {
    ...options,
  };
  const payload = {
    ...traits,
    ...utm,
    ...custom,
    email,
    id,
  };
  setTimeout(() => {
    if (segmentEnabled && segmentInitialized) {
      if (id) {
        window.analytics.identify(id, payload, defaultOptions, callback);
      } else {
        window.analytics.identify(payload, defaultOptions, callback);
      }
    } else {
      hubspot.identify(payload);
    }
    return payload;
  }, 222);
};

// analytics.group(groupId, [traits], [options], [callback]);
export const identifyGroup = (groupId, traits, options, callback) => {
  const id = analyticsSessionId(groupId);
  const email = getParam("email") || traits?.email;
  const defaultOptions = {
    ...options,
  };
  const payload = {
    ...traits,
    ...utm,
    ...custom,
    email,
    id,
  };
  if (segmentEnabled && segmentInitialized) {
    window.analytics.group(groupId, traits, defaultOptions, callback);
  } else {
    hubspot.identify(payload);
  }
  return payload;
};

// analytics.track(event, [properties], [options], [callback]);
// Track spec: https://segment.com/docs/connections/spec/track/
// Pass empty objects for properties, options, or null callback
// ie - analytics.track('User finished something', {}, {}, null)
export const trackEvent = (event, properties) => {
  const payload = { ...properties, ...utm, ...custom };
  if (segmentEnabled && segmentInitialized) {
    window.analytics.track(event, payload);
    // console.log(event, { ...properties, ...utm, ...custom });
  } else {
    hubspot.track(event, payload);
  }
};
// analytics.trackLink(element, event, [properties])
// attaches the track call as a handler to a link
export const trackLink = (element, event, properties) => {
  const payload = {
    ...properties,
    ...utm,
    ...custom,
  };
  if (segmentEnabled && segmentInitialized) {
    window.analytics.trackLink(element, event, payload);
  }
};

// const form = document.getElementById('nameof-form');
// analytics.trackForm(form, event, [properties])
// binds a track call to a form submission
export const trackForm = (element, event, properties) => {
  const payload = {
    ...properties,
    ...utm,
    ...custom,
  };
  if (segmentEnabled && segmentInitialized) {
    window.analytics.trackForm(element, event, payload);
  } else {
    hubspot.handleFormSubmission(event, payload);
  }
};

/* analytics.page([category], [name], [properties], [options], [callback]);
  Default page properties: 
  {
    title: 'Page title',
    url: 'https://example.com/example',
    path: '/example',
    referrer: 'https://example.com/example-referrer'
  }
  See: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#default-page-properties
*/
export const trackPage = (category, name, properties, options, callback) => {
  const defaultOptions = {
    ...options,
  };
  if (segmentEnabled && segmentInitialized) {
    window.analytics.page(category, name, properties, defaultOptions, callback);
  } else {
    hubspot.page();
  }
};

// analytics.reset();
export const resetAnalytics = () => {
  if (segmentEnabled && segmentInitialized) {
    window.analytics.reset();
    hubspot.clear();
    hubspot.refresh();
  }
};
