/* eslint-disable react/prop-types */
import React from "react";
import { Image } from "@ui";

export default React.memo(({ content }) => {
  if (content.headshot !== null) {
    return (
      <div className="rounded-full overflow-hidden h-16 w-16">
        <Image class="rounded-full" src={content.headshot.url} />
      </div>
    );
  }
  return null;
});
