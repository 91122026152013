import React from "react";
import PropTypes from "prop-types";
import { RichText } from "@ui";

import Headshot from "./Testimonial/Headshot";

const Testimonial = React.memo(({ content }) => {
  return (
    <blockquote className="px-6 py-12 sm:p-12 bg-black rounded-3xl text-white md:py-24 md:px-24 lg:px-32 xl:px-48">
      <div className="quote text-center text-xl md:text-2-5xl italic">
        <RichText content={content.quote} />
      </div>
      <div className="flex items-center justify-center mt-12">
        <Headshot content={content} />
        <div className="ml-8">
          <span className="block font-bold text-lg">{content.full_name}</span>
          <span className="block">{content.description}</span>
        </div>
      </div>
    </blockquote>
  );
});

Testimonial.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
  ).isRequired,
};

export default Testimonial;
