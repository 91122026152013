/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { HeaderContext } from "@context";
import { HeroHomepage, Container } from "@layout";
import {
  SectionHeading,
  HeadingTextColumn,
  CardGroup,
  CTALarge,
  Testimonial,
  LargeCardGroup,
  EopCta,
  MetaData,
} from "@common";
import { RandomShapes } from "@decoration";
import { ShapesPattern, ShapesPatternRepeat } from "@svg";
import { Image } from "@ui";
import { PageTransition } from "@components";

const Index = props => {
  // disable eslint because the content is so deep
  // eslint-disable-next-line
  const { home } = props.data.prismic;
  const { hero, overview_heading } = home;
  const { cards_per_column } =
    home.collaborate_heading && home.collaborate_heading[0];
  const { setBlogLayout } = useContext(HeaderContext);
  useEffect(() => {
    setBlogLayout(false);
  }, []);

  return (
    <>
      <MetaData content={home} />
      <PageTransition>
        <div>
          {/* background for the menu when it's at the top of the page */}
          <div
            className={classNames({
              "bg-blue absolute top-0 left-0 right-0 h-24 -translate-y-full": true,
            })}
          />
          <HeroHomepage
            heading={hero[0].hero_heading[0].text}
            subheading={hero[0].hero_copy}
            buttonText={hero[0].hero_button_text}
            buttonLink={hero[0].hero_button_link}
            content={hero[0]}
            size="lg"
            variant="blue"
            textclassName="text-black"
          />
          {/* need 1px padding for margins to work */}
          <section className="home flex flex-col py-px flex-shrink-0 bg-blue-offwhite">
            {/*
        // ! These are out of order! Use flex-order classes to put them in the proper order!
            This was done so that the z-stacking context could be overriden. 
         */}
            {/* Benefits Second Item */}
            <Container className="order-2 py-6 md:py-12" variant="md">
              <div className="text-blue absolute right-0 md:top-0 bottom-0 -z-10">
                <ShapesPattern
                  className="opacity-3 stroke-current"
                  dimensions={[22, 12]}
                />
              </div>
              <div className="flex flex-wrap -mx-6 items-center">
                <div className="w-full sm:w-4/5 md:w-2/5 px-12 md:px-6">
                  <HeadingTextColumn
                    color="blue"
                    content={home.benefits_description[0]}
                  />
                </div>
                <div className="w-full md:w-3/5 relative px-6">
                  <RandomShapes
                    opacity=".1"
                    seed="2"
                    shapes={[
                      { Circle: "coral" },
                      { Square: "blue" },
                      { FourthCircle: "coral" },
                      { FourthCircle: "blue" },
                    ]}
                  />
                  <CardGroup color="blue" content={home.benefits_cards} />
                </div>
              </div>
            </Container>
            {/* Section Heading, First Item */}
            <Container className="order-1 pt-3 md:py-12" variant="heading">
              <SectionHeading
                className="mt-12 md:my-12 sm:mt-24"
                content={overview_heading[0]}
              />
            </Container>
            {/* design section, third item */}
            <Container className="order-2 py-6 md:py-12" variant="md">
              <div className="flex flex-wrap -mx-6 items-center">
                <div className="w-full md:w-3/5 relative px-6 order-2 md:order-1">
                  <RandomShapes
                    opacity=".1"
                    seed="3"
                    shapes={[
                      { Circle: "coral" },
                      { Square: "blue" },
                      { FourthCircle: "coral" },
                      { FourthCircle: "blue" },
                    ]}
                  />
                  <Image src={home.design_image[0].image.url} transparent />
                </div>
                <div className="w-full sm:w-4/5 md:w-2/5 px-12 md:px-6 order-1 md:order-2">
                  <HeadingTextColumn
                    color="blue"
                    content={home.design_description[0]}
                  />
                </div>
              </div>
            </Container>
            {/* permits section, fourth item */}
            <Container className="order-2 py-6 md:py-12" variant="md">
              <div className="flex flex-wrap -mx-6 items-center">
                <div className="w-full sm:w-4/5 md:w-2/5 px-12 md:px-6">
                  <HeadingTextColumn
                    color="blue"
                    content={home.permits_description[0]}
                  />
                </div>
                <div className="w-full md:w-3/5 relative p-6 md:p-12">
                  <div className="relative translate-x-1/4 md:ml-0 -ml-24">
                    <Image
                      className="shadow"
                      src={home.permits_image[0].foreground_image.url}
                      transparent
                    />
                    <RandomShapes
                      opacity=".1"
                      seed="4"
                      shapes={[
                        { Circle: "coral" },
                        { Square: "blue" },
                        { FourthCircle: "coral" },
                        { FourthCircle: "blue" },
                      ]}
                    />
                  </div>
                  <div className="absolute left-0 opacity-75 bottom-0 right-0 -z-10">
                    <Image
                      src={home.permits_image[0].background_image.url}
                      transparent
                    />
                  </div>
                </div>
              </div>
            </Container>
          </section>
          <section className="relative bg-blue-offwhite">
            <div className="absolute inset-0 -z-10 bg-white" />
            <div className="absolute inset-0 flex items-center justify-center">
              <ShapesPatternRepeat
                className="opacity-3 text-blue stroke-current h-full w-full"
                dimensions={[10, 10]}
              />
            </div>
            <div className="relative flex w-full justify-center items-center order-last">
              <div className="absolute w-3/7 mt-12 translate-x-1/4 right-0 top-0 bottom-0 -z-10 flex opacity-25">
                <Image
                  src={home.cta_large[0].background_image.url}
                  transparent
                />
              </div>
              <div
                className="bg-blue-offwhite absolute -mt-48 inset-0 z-0"
                style={{
                  borderRadius: "100%",
                  width: "125%",
                  height: "200px",
                  top: "100px",
                  left: "-5%",
                }}
              />
              <Container variant="heading">
                <CTALarge color="black" content={home.cta_large[0]} />
                <RandomShapes
                  opacity=".1"
                  seed="1"
                  shapes={[
                    { Circle: "coral" },
                    { Square: "blue" },
                    { FourthCircle: "coral" },
                    { FourthCircle: "blue" },
                  ]}
                />
              </Container>
            </div>
            <Container className="mt-6" variant="lg">
              <Testimonial content={home.testimonial[0]} />
            </Container>
            {/* Collaborate */}
            <Container className="order-1 pt-3 md:py-12" variant="md">
              <SectionHeading
                className="mt-8 mb-4"
                content={home.collaborate_heading[0]}
              />
            </Container>
            <Container className="sm:px-12 mt-12 md:mt-0 md:px-6 lg:px-12">
              <LargeCardGroup
                content={home.collaborate_cards}
                cards={cards_per_column}
                color="purple"
              />
            </Container>
            <Container variant="lg" className="pb-24">
              <EopCta content={home.cta_eop[0]} gradient="black" />
            </Container>
            <div className="bg-transparent h-24 relative z-0" />
          </section>
        </div>
      </PageTransition>
    </>
  );
};

export default React.memo(Index);

export const query = graphql`
  query HomeQuery {
    prismic {
      home(lang: "en-us", uid: "home") {
        _meta {
          uid
          lang
          alternateLanguages {
            uid
            lang
          }
        }
        title
        meta_title
        meta_image
        meta_description
        overview_heading {
          heading
          subheading
        }
        hero {
          hero_button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          hero_button_text
          hero_copy
          hero_heading
          embed_animation
          embed_code
        }
        design_description {
          column_copy
          column_heading
          column_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          column_link_cta
          icon
        }
        design_image {
          image
        }
        benefits_description {
          icon
          column_copy
          column_heading
          column_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          column_link_cta
        }
        benefits_cards {
          copy
          icon
        }
        permits_description {
          column_copy
          column_heading
          column_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          column_link_cta
          icon
        }
        permits_image {
          background_image
          foreground_image
        }
        cta_large {
          background_image
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          copy
          button_text
          heading
          icon
        }
        testimonial {
          description
          full_name
          headshot
          quote
        }
        collaborate_heading {
          heading
          subheading
          cards_per_column
        }
        collaborate_cards {
          copy
          heading
          icon
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_cta
        }
        cta_eop {
          tracking_event
          heading
          subheading
          arrow_link {
            ... on PRISMIC__ExternalLink {
              _linkType
              url
              target
            }
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
        }
      }
    }
  }
`;
