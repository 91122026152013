/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { HeaderContext } from "@context";
import BlogLayout from "./layout";
import { PageTransition } from "@components";
import { MetaData } from "@common";

const BlogCategory = ({ data, pageContext }) => {
  // disable eslint because the content is so deep
  // eslint-disable-next-line
  const { blog, allPostss } = data.prismic;
  const { currentCategoryName } = pageContext;
  const { currentCategory } = pageContext;
  const { setSunrisePage, setBlogLayout } = useContext(HeaderContext);
  useEffect(() => {
    setSunrisePage(false);
    setBlogLayout(true);
  }, []);

  return (
    <>
      <MetaData
        content={blog}
        title={currentCategoryName || currentCategory?.name}
        type="article"
      />
      <PageTransition>
        <BlogLayout
          blog={blog}
          posts={allPostss.edges}
          pageContext={pageContext}
          slices={blog?.body}
        />
      </PageTransition>
    </>
  );
};

export default BlogCategory;

export const query = graphql`
  query BlogCategoryQuery(
    $first: Int!
    $after: String!
    $currentCategoryName: String!
  ) {
    prismic {
      allPostss(
        lang: "en-us"
        first: $first
        after: $after
        where: { category: $currentCategoryName }
        sortBy: published_date_DESC
      ) {
        edges {
          node {
            _meta {
              uid
              lastPublicationDate
              firstPublicationDate
            }
            category
            primary_image
            hero_background_image
            summary
            title
            hide
            published_date
            author {
              ... on PRISMIC_People {
                full_name
                photo
                linkedin_url
              }
            }
          }
        }
      }
      blog(lang: "en-us", uid: "blog") {
        title
        meta_title
        meta_image
        meta_description
        hero {
          hero_heading
          hero_subheading
          show_newsletter_signup
        }
        cta_eop {
          tracking_event
          heading
          subheading
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
        }
        email_signup {
          background_image
          heading
          icon
          submit_button_text
          submit_button_color
          subtitle
          success_message
          failure_message
          tracking_event
          list_id
          promo_code
          promo_memo
        }
        webinars_embed_link
        show_webinars
        show_webinars_posts
        body {
          ... on PRISMIC_BlogBodyCopy_block {
            type
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_BlogBodyImage_block {
            type
            label
            primary {
              image_layout
              remove_white_space
            }
            fields {
              caption
              link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
                ... on PRISMIC_Design {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Posts {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_For_manufacturers {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pages {
                  path
                  title
                  meta_title
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Contact {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pricing {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Permit_packages {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_About {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Blog {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Home {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__ImageLink {
                  url
                }
              }
              image
            }
          }
          ... on PRISMIC_BlogBodyPull_quote_block {
            type
            label
            primary {
              citation
              quote
            }
          }
          ... on PRISMIC_BlogBodyLead_copy_block {
            type
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_BlogBodyVideo_block {
            type
            label
            primary {
              video_caption
              video_url
            }
          }
          ... on PRISMIC_BlogBodyEmail_signup_block {
            type
            label
            primary {
              background_image
              failure_message
              heading
              submit_button_text
              submit_button_color
              icon
              subtitle
              success_message
              list_id
              promo_code
              promo_memo
              tracking_event
              form_collector
              form_id
              upload_widget
              recaptcha_widget
              require_phone_number
              show_phone_number
              show_messenger
              volume_low_title
              volume_low_subtitle
            }
            fields {
              label
              value
            }
          }
          ... on PRISMIC_BlogBodyCta_block {
            type
            label
            primary {
              background
              icon_image
              title
              paragraph
              button_label
              button_link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
                ... on PRISMIC_Design {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Posts {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_For_manufacturers {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pages {
                  path
                  title
                  meta_title
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Contact {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pricing {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Permit_packages {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_About {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Blog {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Home {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
