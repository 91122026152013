/* eslint-disable react/no-array-index-key */
/**
 * Random Shapes
 *
 * @param {*} { children, shapes }
 * @param { shapes } = and object of [shape]: [color] values that
 *                     this will iterate through to generate randomly
 *                     placed shapes.
 * @returns html with svgs
 */

import React from "react";
import PropTypes from "prop-types";
// these are referenced dynamically
// eslint-disable-next-line no-unused-vars
import { useUID } from "react-uid";
import Seedrandom from "seedrandom";
import * as ImportedShapes from "@svg";

const RandomShapes = React.memo(({ shapes, opacity, hero, seed }) => {
  const rn = new Seedrandom(seed);
  const randomStyles = (index, opac) => {
    const duration = rn() * 50 + 100;
    const delay = rn() * 250;
    // iterate through sides with some random placement
    let randomY;
    let randomX;
    if (index === 0) {
      // top side
      randomY = { top: `-1.5rem` };
      randomX = { left: `${rn() * 50 + 25}%` };
    } else if (index === 1) {
      // bottom side
      randomY = { bottom: `-1.5rem` };
      randomX = { left: `${rn() * 50 + 25}%` };
    } else if (index === 2) {
      // right side
      randomY = { right: `-1.5rem` };
      randomX = { top: `${rn() * 50 + 25}%` };
    } else if (index === 3) {
      // left side
      randomY = { left: `-1.5rem` };
      randomX = { top: `${rn() * 50 + 25}%` };
    }
    const styles = {
      animationDuration: `${duration}s`,
      animationDelay: `-${delay}s`,
      zIndex: "-10",
      opacity: opac,
      ...randomY,
      ...randomX,
    };
    return styles;
  };
  const padding = hero
    ? { top: "-2.5%", left: "-2.5%", bottom: "-2.5%", right: "-2.5%" }
    : null;
  return (
    <div className="random-shapes absolute inset-0 -z-10" style={padding}>
      {shapes.map((obj, index) => {
        const uid = useUID();
        const key = Object.keys(obj).pop();
        const value = obj[key];
        const Shape = ImportedShapes[key];
        return (
          <div
            className={`text-${value} random-shape absolute w-12 h-12 md:w-24 md:h-24`}
            style={randomStyles(index, opacity)}
            key={`shape-${key}-${uid}`}
          >
            <Shape style={{ transform: `scale(${rn() * 0.25 + 0.9})` }} />
          </div>
        );
      })}
    </div>
  );
});

RandomShapes.defaultProps = {
  shapes: [{ Circle: "coral" }, { Square: "coral" }, { FourthCircle: "teal" }],
  opacity: 1,
  hero: false,
  seed: 9,
};

RandomShapes.propTypes = {
  shapes: PropTypes.arrayOf(PropTypes.object),
  opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hero: PropTypes.bool,
  seed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RandomShapes;
