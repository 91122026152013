export default (img, callback) => {
  if (!img || !callback) {
    return;
  }

  if (img.complete) {
    callback();
    return;
  }

  const onLoad = () => {
    img.removeEventListener("load", onLoad);
    callback();
  };

  img.addEventListener("load", onLoad);
};
