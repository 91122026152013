/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState } from "react";

import math from "mathjs";
import CountUp from "react-countup";
import { Range, getTrackBackground } from "react-range";
import { RichText, Button } from "@ui";
import { PrismicLink } from "@utilities";
import { RandomShapes } from "@decoration";
import { Container } from "@layout";
import * as SVG from "@svg";

export default ({ content }) => {
  // const higherVolumePrice = Number(content.higher_volume_price) || 49;
  const lowerVolumePrice = Number(content.lower_volume_price) || 125;
  const higherEquationThreshold =
    Number(content.higher_equation_threshold) || 100;
  const lowerEquationThreshold = Number(content.lower_equation_threshold) || 10;
  const maxEquationThreshold = Number(content.highest_volume_price) || 120;
  const equation = content.pricing_equation || "-12.75 * log(y) + 128.04"; // og: -12.75 * log(y) + 107.04
  const [showPricing] = useState(content.show_pricing);
  const [permitNo, setPermitNo] = useState(1);
  const [oldCost, setOldCost] = useState(lowerVolumePrice);
  const [newCost, setNewCost] = useState(lowerVolumePrice);
  const [contactSales, setContactSales] = useState(0);
  const [sliderValues, setSliderValues] = useState([0]);
  const handleInput = value => {
    setSliderValues(value);
    if (value !== "") {
      const inputValue = Number(value[0]);
      setPermitNo(inputValue);
      setNewCost(Number(inputValue));
      setOldCost(Number(newCost));
      if (
        inputValue >= lowerEquationThreshold &&
        inputValue <= higherEquationThreshold &&
        inputValue !== "" &&
        inputValue != null
      ) {
        setContactSales(0);
        setNewCost(
          Number(math.eval(equation.replace("y", inputValue)).toFixed(0))
        );
      } else if (inputValue >= higherEquationThreshold) {
        setContactSales(1);
        setNewCost(
          Number(math.eval(equation.replace("y", inputValue)).toFixed(0))
        );
      } else {
        setNewCost(Number(lowerVolumePrice));
        setContactSales(0);
      }
    }
  };
  const Icon = SVG[content.icon];
  const GraphIcon = SVG.IconGraph;

  const Slider = () => {
    return (
      <Range
        step={10}
        min={0}
        max={maxEquationThreshold}
        values={sliderValues}
        onChange={value => handleInput(value)}
        // onFinalChange={value => handleInput(value)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "16px",
              width: "5px",
              backgroundColor:
                index * 10 < sliderValues[0] ? "#548BF4" : "#ccc",
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  min: lowerEquationThreshold,
                  max: maxEquationThreshold,
                  values: sliderValues,
                  colors: ["#548BF4", "#ccc"],
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "42px",
              width: "42px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "-44px",
                color: "#fff",
                fontWeight: "bold",
                fontSize: "14px",
                fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                padding: "4px",
                borderRadius: "4px",
                backgroundColor: "#548BF4",
              }}
            >
              {permitNo >= 1 ? permitNo : 1}
              {permitNo === maxEquationThreshold && "+"}
            </div>
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#CCC" : "#548BF4",
              }}
            />
          </div>
        )}
      />
    );
  };
  return (
    <div className="bg-white py-12 md:py-16">
      <Container>
        <div className="text-center">
          <div className="text-blue mx-auto w-10 h-10 mb-6">
            <Icon />
          </div>
          <h2 className="text-blue text-2xl md:text-3xl font-bold">
            {content.heading[0].text}
          </h2>
        </div>
        <div className="flex flex-wrap items-center">
          <div className="py-6 sm:p-12 w-full md:w-1/2">
            <div className="text-xl leading-normal rich-text mb-6">
              <RichText content={content.lead_copy} />
            </div>
            <div className="rich-text leading-normal font-bold">
              <RichText content={content.copy} />
            </div>
          </div>
          <div className="py-6 sm:p-12 w-full md:w-1/2 relative">
            <div className="bg-white shadow p-12 rounded-2xl">
              <div className="text-blue mx-auto flex justify-center mb-6">
                <GraphIcon />
              </div>
              <h4 className="w-full text-center text-blue uppercase text-sm tracking-1 font-bold mb-8">
                {content.calculator_heading[0].text}
              </h4>{" "}
              <div className="flex flex-wrap -mx-2">
                <div className="w-full text-sm px-2 mb-4 text-center">
                  {content.permit_quantity_question}
                </div>
                <div className="w-1/3 px-2 mb-4 hidden">
                  <input
                    id="permitNo"
                    name="permitNo"
                    type="number"
                    pattern="[0-9]*"
                    className="w-full border-b-2 border-black font-bold text-center pb-1 text-xl"
                    onInput={event => handleInput(event.target.value)}
                    // needed to supress console warning
                    onChange={() => {
                      return null;
                    }}
                    value={permitNo}
                    hidden
                  />
                </div>
                {showPricing && (
                  <>
                    <div className="w-2/3 text-sm px-2 mb-4 h-8 flex flex-row-reverse items-center text-right">
                      {content.calculator_result_label}
                    </div>
                    <div className="w-1/3 px-2 mb-4 h-8 items-left text-left">
                      <span className="block text-center pb-1 w-full text-blue text-xl w-full font-bold">
                        <span className="inline text-left text-black text-md font-normal">
                          {permitNo === maxEquationThreshold && "< "}
                        </span>
                        $
                        <CountUp
                          start={oldCost}
                          end={newCost}
                          decimals={0}
                          duration={0.333}
                        />
                        {permitNo < lowerEquationThreshold && "+"}
                      </span>
                    </div>
                  </>
                )}
                <div className="w-full px-2 my-4 h-8">
                  <Slider />
                </div>
              </div>
            </div>
            <RandomShapes opacity=".25" />
          </div>
        </div>
        <div className="text-center">
          {contactSales ? (
            <PrismicLink
              route="/schedule-a-demo"
              className="text-center transition hover:translate-y-lift m-auto"
            >
              <div className="">
                <Button
                  bgClass="bg-blue"
                  textClass="text-white"
                  hoverState={["opacity-75"]}
                  className="hover:bg-blue hover:border-white border-3 border-transparent hover:opacity-100"
                >
                  Contact Sales
                </Button>
              </div>
            </PrismicLink>
          ) : (
            <Button
              link={content.button_link}
              className="hover:bg-blue hover:border-white border-3 border-transparent hover:opacity-100"
            >
              {content.button_text}
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};
