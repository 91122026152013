/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect, Fragment, useRef } from "react";
import classNames from "classnames";
import { Popover } from "@headlessui/react";
import { PrismicLink } from "@utilities";
import { Icon } from "@ui";

export default React.memo(({ theme, nav, label, recentPosts }) => {
  return (
    <Popover>
      <Popover.Panel
        static
        className="z-10 -ml-4 -mt-6 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
      >
        {nav.map((item, i) => {
          return (
            <PrismicLink
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              link={item.link}
              activeclass={classNames({
                "text-black hover:text-black": true,
              })}
              className={classNames({
                "text-black hover:text-blue -m-3 p-3 flex items-start rounded-lg": true,
              })}
            >
              {item.hero_icon && (
                <Icon
                  className="flex-shrink-0 h-6 w-6 text-blue hover:text-gray"
                  aria-hidden="true"
                  icon={item.hero_icon}
                />
              )}
              <div className="ml-4">
                <p className="text-base font-medium">{item.link_text}</p>
                <p
                  className={classNames({
                    "text-gray-beta hover:text-purple text-xs font-light": true,
                    [item.link_text]: true,
                  })}
                >
                  {item.link_description}
                </p>
              </div>
            </PrismicLink>
          );
        })}
      </Popover.Panel>
    </Popover>
  );
});
