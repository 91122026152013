import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Image } from "@ui";
import { Carousel } from "@common";
import { Container } from "@layout";
import { PrismicLink } from "@utilities";

const ImageBlock = ({ content, variant }) => {
  let containerVariant;
  let fullBleed = false;

  if (content.primary.image_layout === "Fit Layout Container") {
    containerVariant = "site";
  } else if (content.primary.image_layout === "Fit Copy Container") {
    containerVariant = "copy";
  } else if (content.primary.image_layout === "Small") {
    containerVariant = "xs";
  } else {
    containerVariant = "fullBleed";
    fullBleed = true;
  }

  if (content.fields.length === 1) {
    return (
      <Container variant={variant || containerVariant} noPadding={fullBleed}>
        {content.fields[0]?.link ? (
          <PrismicLink
            link={content.fields[0]?.link}
            className={classNames({
              "items-center text-center block text-center transition": true,
              "p-0": fullBleed,
              "p-5 md:p-3 lg:p-6": !fullBleed,
            })}
          >
            <Image
              src={content?.fields[0]?.image?.url}
              fit="contain"
              caption={content?.fields[0]?.caption}
              ixparams={{
                crop: "faces,edges,entropy",
                fit: "max",
              }}
            />
          </PrismicLink>
        ) : (
          <Image
            src={content.fields[0]?.image?.url}
            fit="contain"
            caption={content.fields[0]?.caption}
            ixparams={{
              crop: "faces,edges,entropy",
              fit: "max",
            }}
          />
        )}
      </Container>
    );
  }
  if (content.fields.length > 1) {
    return (
      <Container variant={containerVariant} noPadding={fullBleed}>
        <Carousel content={content.fields} />
      </Container>
    );
  }
  return null;
};

ImageBlock.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
  ),
  variant: PropTypes.string,
};

ImageBlock.defaultProps = {
  content: {},
  variant: null,
};

export default ImageBlock;
