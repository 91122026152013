/* eslint-disable no-console */
import { ZapierSubscribe, ZapierSubscribeResponse } from "@json";

// eslint-disable-next-line func-names
const zap = (mode, type) => {
  const data = ZapierSubscribe;
  const { error } = ZapierSubscribeResponse;
  const success = ZapierSubscribeResponse[type];
  const exists = type === "email_exists";

  const handleError = () => {
    return {
      statusCode: error.statusCode,
      body: error,
      error,
      errorObject: error,
    };
  };

  const handleSuccess = () => {
    return {
      statusCode: success.status,
      body: success,
      statusText: success.statusText,
      uid: !exists && data.userId,
      data,
    };
  };

  if (mode === "success") {
    return handleSuccess();
  }
  return handleError();
};

export default zap;
