/* eslint-disable no-console */
import SecureLS from "secure-ls";
import { Cookies } from "react-cookie";

const USER_DATA_SECRET =
  "09AJ4Tk-7JeW_1nPeaJi7lbULrns8dc5ja2KMoMIqVJYsDhO2bP1Nmi7DQ_-LRPOwz5Are06mZoB7EcB4fgEjsjqzqnZheXLpuxA0";
const APP_USER_DATA_SECRET = "LYRA_THE_BEST";
const isBrowser = typeof window !== "undefined";
const cookieOptions = {
  path: "/",
  sameSite: "None",
  maxAge: 60 * 60 * 24 * 30, // expires in 30 days,
  secure: false,
};
const cookies = new Cookies();

const set = (key, data, secure, app) => {
  let isSecure = false;
  isSecure = secure;
  let isApp = false;
  isApp = app;
  if (isBrowser) {
    if (isSecure) {
      // Default to secure mode using aes
      const storage = new SecureLS({
        encodingType: "aes",
        encryptionSecret: isApp ? APP_USER_DATA_SECRET : USER_DATA_SECRET,
      });
      if (key && storage) {
        let store = storage.get(key);
        if (store) {
          // console.log("LocalStorage:aes:get", store);
          return store;
        }
        storage.set(key, data);
        store = storage.get(key);
        // console.log("LocalStorage:aes:set", store);
        return store;
      }
    }
    // If we asked for it specifically not to be secure, use localStorage
    if (key) {
      let store = localStorage.setItem(key, data);
      if (store) {
        // console.log("LocalStorage:get", store);
        return store;
      }
      store = localStorage.setItem(key, data);
      store = localStorage.getItem(key);
      // console.log("LocalStorage:set", store);
      return store;
    }
  }
  return null;
};

const get = (key, secure, app) => {
  let isSecure = false;
  isSecure = secure;
  let isApp = false;
  isApp = app;
  if (isBrowser) {
    if (isSecure) {
      // Default to secure mode using aes
      const storage = new SecureLS({
        encodingType: "aes",
        encryptionSecret: isApp ? APP_USER_DATA_SECRET : USER_DATA_SECRET,
      });
      if (key && storage) {
        const store = storage.get(key);
        if (store) {
          // console.log("LocalStorage:aes:get", store);
          return store;
        }
        return null;
      }
    }
    if (key) {
      const store = localStorage.getItem(key);
      if (store) {
        // console.log("LocalStorage:get", store);
        return store;
      }
      return null;
    }
  }
  return null;
};

const remove = key => {
  localStorage.removeItem(key);
};

const setCookie = (key, data, domain) => {
  const cookie = cookies.set(
    key,
    data,
    domain && {
      ...cookieOptions,
      domain: `.${domain}`,
    }
  );
  return cookie;
};

const getCookie = key => {
  const cookie = cookies.get(key);
  return cookie;
};

const removeCookie = key => {
  cookies.remove(key);
};

const setSession = (key, data) => {
  if (isBrowser) {
    // Use sessionStorage
    if (key) {
      let store = sessionStorage.setItem(key, data);
      if (store) {
        // console.log("LocalStorage:get", store);
        return store;
      }
      store = sessionStorage.setItem(key, data);
      store = sessionStorage.getItem(key);
      // console.log("LocalStorage:set", store);
      return store;
    }
  }
  return null;
};

const getSession = (key, secure, app) => {
  let isSecure = false;
  isSecure = secure;
  let isApp = false;
  isApp = app;
  if (isBrowser) {
    if (isSecure) {
      // Default to secure mode using aes
      const storage = new SecureLS({
        encodingType: "aes",
        encryptionSecret: isApp ? APP_USER_DATA_SECRET : USER_DATA_SECRET,
      });
      if (key && storage) {
        const store = storage.get(key);
        if (store) {
          // console.log("LocalStorage:aes:get", store);
          return store;
        }
        return null;
      }
    }
    if (key) {
      const store = sessionStorage.getItem(key);
      if (store) {
        // console.log("LocalStorage:get", store);
        return store;
      }
      return null;
    }
  }
  return null;
};

const removeSession = key => {
  sessionStorage.removeItem(key);
};

const LocalStorage = {
  set,
  get,
  remove,
  setSession,
  getSession,
  removeSession,
  setCookie,
  getCookie,
  removeCookie,
};

export default LocalStorage;
