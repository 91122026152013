// TODO: Figure out how to use this dynamically, for now, just copy-paste
// place in a basic link query to dry up graphql queries that include a link
export default `
_linkType
... on PRISMIC__ExternalLink {
  url
  target
}
... on PRISMIC_Design {
  _meta {
    uid
  }
}
... on PRISMIC_Posts {
  _meta {
    uid
  }
}
... on PRISMIC_For_manufacturers {
  _meta {
    uid
  }
}
... on PRISMIC_Pages {
  path
  title
  meta_title
  _linkType
  _meta {
    uid
  }
}
... on PRISMIC_Contact {
  _meta {
    uid
  }
}
... on PRISMIC_Pricing {
  _meta {
    uid
  }
}
... on PRISMIC_Permit_packages {
  title
  _meta {
    uid
  }
}
... on PRISMIC_About {
  _meta {
    uid
  }
}
... on PRISMIC_Blog {
  _linkType
  _meta {
    alternateLanguages {
      uid
    }
  }
}
... on PRISMIC_Home {
  title
  _meta {
    uid
  }
}
... on PRISMIC__FileLink {
  url
}
... on PRISMIC__ImageLink {
  url
}
`;
