/* eslint-disable react/prop-types */
import React from "react";
import { VideoPlayer } from "@ui";
import { Container } from "@layout";

export default ({ content, variant }) => {
  return (
    <Container variant={variant || "copy"}>
      <VideoPlayer url={content.primary.video_url} />
      <p className="text-xs">{content.primary.caption}</p>
    </Container>
  );
};
