import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CTAArrowLink from "./EopCta/CTAArrowLink";
import CTAButton from "./EopCta/CTAButton";
import { RichText } from "@ui";

const EopCta = React.memo(({ content, background, sunrise, gradient }) => {
  const gradientColor = gradient && `gradient-${gradient}`;
  const customTitleText =
    background === "bg-green" ||
    background === "bg-blue" ||
    background === "bg-black" ||
    background === "bg-gray"
      ? "text-white"
      : "";

  return (
    <div
      className={classNames(`${gradientColor}`, {
        "bg-green text-white": sunrise || background === "bg-green",
        "bg-white": background === "bg-white",
        "bg-blue text-white":
          !sunrise && background !== "bg-green" && background !== "bg-white",
        "text-white block p-6 md:p-5  mt-12 -mb-24 text-center border-white border-l-4 border-r-4 rounded-xl transition shadow-lg hover:translate-y-lift hover:shadow-lift": true,
      })}
    >
      {content.heading && (
        <h2 className="text-2xl sm:text-3xl font-bold my-4">
          {content.heading[0].text}
        </h2>
      )}
      {content.subheading && (
        <div className={`${customTitleText} rich-text leading-normal my-6`}>
          <RichText content={content.subheading} />
        </div>
      )}
      <CTAButton content={content} />
      <CTAArrowLink content={content} />
    </div>
  );
});

EopCta.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
  ).isRequired,
  background: PropTypes.string,
  gradient: PropTypes.string,
  sunrise: PropTypes.bool,
};

EopCta.defaultProps = {
  background: "bg-blue",
  gradient: null,
  sunrise: false,
};

export default EopCta;
