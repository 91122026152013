/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import * as Sentry from "@sentry/browser";

Sentry.setContext("platform", {
  name: "Gatsby",
  env: process.env.GATSBY_ACTIVE_ENV,
  url: process.env.GATSBY_SITE_URL,
});

export class ErrorBoundary extends React.Component {
  state = {
    error: null,
    enabled: process.env.GATSBY_SENTRY_ENABLED === "true",
  };

  componentDidCatch(error, errorInfo) {
    console.log(
      "[Sentry][ErrorBoundary] - componentDidCatch - error: ",
      error,
      "errorInfo",
      errorInfo
    );
    this.setState({ error });
    if (this.state.enabled || process.env.GATSBY_SENTRY_ENABLED === "true") {
      Sentry.configureScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
      });
      Sentry.captureException(error);
    }
  }

  render() {
    // Without this check => possible infinite loop
    return this.props.children;
  }
}
