import React from "react";
import PropTypes from "prop-types";
import * as SVG from "@svg";

const Icon = React.memo(({ iconName, className, id, heroIcon }) => {
  if (iconName != null && !heroIcon) {
    const Content = SVG[iconName];
    return (
      <div
        id={id}
        className={`icon block fill-current stroke-current ${className}`}
      >
        <Content />
      </div>
    );
  }
  if (heroIcon && iconName === null) {
    return (
      <svg
        className={className}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          fillRule="evenodd"
          d={heroIcon}
        />
      </svg>
    );
  }
  return null;
});

Icon.defaultProps = {
  className: "w-16 h-16",
  iconName: null,
  heroIcon: null,
  id: "",
};

Icon.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
  heroIcon: PropTypes.string,
  id: PropTypes.string,
};

export default Icon;
