/* eslint-disable react/prop-types */
import React from "react";
import { useUID } from "react-uid";
import { Button, Image, RichText } from "@ui";
import { RandomShapes } from "@decoration";

const ThreeColumns = React.memo(({ content, columns }) => {
  return (
    <div className="p-6 sm:px-12 md:p-0">
      <h3 className="text-center uppercase max-w-xl mx-auto tracking-3 mb-2 text-sm sm:text-base font-bold text-blue">
        {content.subheading[0].text}
      </h3>
      <h2 className="font-bold text-xl md:text-2-5xl max-w-xl mx-auto text-center mb-20">
        {content.heading[0].text}
      </h2>
      <div className="flex flex-wrap -mx-6 relative">
        <div className="absolute inset-0">
          <RandomShapes
            opacity=".15"
            seed="122345"
            shapes={[
              { Circle: "blue" },
              { Square: "blue" },
              { FourthCircle: "blue" },
            ]}
          />
        </div>
        {columns.map(column => {
          const uid = useUID();
          return (
            <div key={uid} className="md:w-1/3 px-6 mb-8">
              <div className="shadow">
                <Image
                  src={column.image.url}
                  aspectratio="4:3"
                  fit="cover"
                  ixparams={{ fit: "crop" }}
                />
              </div>
              <h4 className="mt-8 mb-4 font-bold">{column.heading[0].text}</h4>
              <div className="rich-text leading-normal text-sm">
                <RichText content={column.copy} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="text-center mt-12">
        <Button link={content.button_link}>{content.button_text}</Button>
      </div>
    </div>
  );
});

export default ThreeColumns;
