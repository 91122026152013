/* eslint-disable react/no-array-index-key */

import React from "react";
import PropTypes from "prop-types";

// create camelCase function
// ? should move to utility script?
const camelCase = str => {
  const string = str.replace(/_/g, " ");
  return string
    .replace(/(\W+(.)|^.)/g, (match, chr) => {
      return chr.toUpperCase();
    })
    .replace(/ /g, "");
};

const Slices = ({
  uid,
  content,
  columns,
  ySpacing,
  xSpacing,
  sliceMarginClass,
  className,
  darkTheme,
  variant,
  noPadding,
  gradient,
}) => {
  // only render slices if passed any
  if (content != null && content.length > 0) {
    const sliceMargins = columns > 1 ? null : sliceMarginClass;
    const columnClasses =
      columns > 1 &&
      `grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-${columns} gap-5 m-auto max-w-7xl`;
    return (
      <section
        className={`${ySpacing} ${xSpacing} ${className} ${columnClasses}`}
      >
        {content.map((slice, i) => {
          const SliceComponent = camelCase(slice.type);
          const SliceComponentRef =
            // eslint-disable-next-line
            require(`@slices/${SliceComponent}`).default;
          const sliceMarginStyle =
            slice.primary && slice.primary.remove_white_space
              ? {
                  marginTop: "-3rem",
                }
              : null;
          // const ySpacingBlock = slice.primary.remove_white_space ? "py-0" : "md:py-4 lg:py-6 xl:py-12";
          return (
            <div
              key={`slice-${i}`}
              id={`slice--${i}`}
              className={`slice--${slice.type.replace(
                /_/g,
                "-"
              )} ${sliceMargins}`}
              style={sliceMarginStyle}
            >
              <SliceComponentRef
                key={`slice-${i}`}
                uid={uid}
                content={slice}
                className={className}
                darkTheme={darkTheme}
                variant={variant}
                noPadding={noPadding}
                gradient={gradient}
              />
            </div>
          );
        })}
      </section>
    );
  }
  // return nothing if array is empty
  return null;
};

Slices.defaultProps = {
  content: [],
  columns: 1,
  ySpacing: "md:py-4 lg:py-6 xl:py-12",
  xSpacing: null,
  sliceMarginClass: "my-12",
  className: "",
  gradient: "",
  uid: "",
  darkTheme: false,
  variant: "copy",
  noPadding: false,
};

Slices.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.number,
  uid: PropTypes.string,
  ySpacing: PropTypes.string,
  xSpacing: PropTypes.string,
  sliceMarginClass: PropTypes.string,
  className: PropTypes.string,
  gradient: PropTypes.string,
  variant: PropTypes.string,
  darkTheme: PropTypes.bool,
  noPadding: PropTypes.bool,
};

export default Slices;
