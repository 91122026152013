/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { Container } from "@layout";
import { SectionHeading, EopCta, MetaData } from "@common";
import { ShapesPatternHero, Lyra404 } from "@svg";
import { HeaderContext } from "@context";
import { RichText } from "@ui";
import { PageTransition } from "@components";

const NotFoundPage = ({ data }) => {
  const { setTheme } = useContext(HeaderContext);
  setTheme("light");

  const { error_404 } = data.prismic;

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "bg-blue-offwhite",
        }}
      />
      <MetaData content={error_404} />
      <PageTransition>
        <div className="flex-1 flex flex-col" style={{ minHeight: "100vh" }}>
          <section className="flex-1 flex items-center justify-center xxs:pt-20 pt-24 sm:pt-32">
            <Container className="relative z-10">
              <div className="flex justify-center mb-6 lg:mb-12 px-12 max-w-md mx-auto">
                <Lyra404 />
              </div>
              <SectionHeading content={error_404.message[0]} />
              <div className="rich-text text-lg max-w-xl mx-auto mt-6 lg:mt-12 text-center antialiased">
                <RichText content={error_404.message[0].copy} />
              </div>
            </Container>
            <ShapesPatternHero
              className="absolute text-blue shapes-pattern-hero opacity-3 stroke-current bg-green-500"
              dimensions={[16, 24]}
              style={{
                transform: "rotate(200deg) scale(1.25) translate(-75%, 20%)",
              }}
            />
          </section>
          <section>
            <Container>
              <EopCta content={error_404.cta_eop[0]} />
            </Container>
            <div className="bg-black h-24 relative z-0" />
          </section>
        </div>
      </PageTransition>
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundQuery {
    prismic {
      error_404(lang: "en-us", uid: "404") {
        title
        meta_title
        meta_image
        meta_description
        message {
          copy
          heading
          subheading
        }
        cta_eop {
          tracking_event
          heading
          subheading
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
        }
      }
    }
  }
`;
