/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useUID } from "react-uid";
import { RichText, Button, Icon } from "@ui";

import { Container } from "@layout";
import HeroShapes from "../layout/Hero/HeroShapes";

const HeroPricing = ({ content, details, variant, size }) => {
  return (
    <section
      className={classNames({
        "bg-blue text-white": variant !== "light",
        "hero--pricing gradient-teal min-h-hero pt-32 pb-16 flex flex-col justify-center transition items-center relative z-1": true,
      })}
    >
      <HeroShapes variant={variant} invert />
      <Container size={size}>
        <div className="flex flex-wrap items-center">
          <div className="xxs:w-full text-center w-6/7 sm:w-5/7 md:w-2/3 xl:w-3/5 xxs:px-0 py-6 xl:py-12 sm:px-6 sm:p-0 order-2 sm:order-1 z-10 mx-auto relative">
            <div className="text-white flex justify-center mb-4">
              <Icon className="w-10 h-10" iconName="LyraSun" />
            </div>
            <h1
              className={classNames({
                "text-blue": variant === "light",
                "text-white": variant !== "light",
                "leading-tight font-bold antialiased text-3xl md:text-5xl xl:text-6xl mb-6 lg:mb-10": true,
              })}
            >
              {content.heading[0].text}
            </h1>
            <div className="text-xl">
              <RichText content={content.copy} />
            </div>
            <div className="text-xl my-12">
              <Button
                className="cursor-pointer transition ease-out duration-333 translate-y-1 font-bold hover:bg-blue hover:border-white border-3 border-transparent hover:opacity-100"
                link={content.cta_link}
              >
                {content.cta_text}
              </Button>
            </div>
            <div className="flex text-left leading-normal flex-wrap -mx-3">
              {details.map(detail => {
                const uid = useUID();
                return (
                  <div
                    key={uid}
                    className="w-full sm:w-1/2 px-3 rich-text--bold-links text-white mb-6 sm:mb-0 text-center sm:text-left"
                  >
                    <RichText content={detail.copy} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

HeroPricing.defaultProps = {
  size: "md",
  variant: "light",
};

HeroPricing.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
};

export default HeroPricing;
