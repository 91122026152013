/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
/* eslint-disable no-console */
import axios from "axios";
import { LocalStorage } from "@utils";

const hasWindow = typeof window !== "undefined";
const hasNavigator = typeof navigator !== "undefined";
const hasDocument = typeof document !== "undefined";
const segmentId =
  process.env.GATSBY_ANALYTICS_ENV === "development"
    ? process.env.GATSBY_SEGMENT_DEV_ID
    : process.env.GATSBY_SEGMENT_ID;

const environment = () => {
  if (process.env.GATSBY_ACTIVE_ENV === process.env.NODE_ENV) {
    return process.env.GATSBY_ACTIVE_ENV;
  }
  return process.env.NODE_ENV;
};
const leadFormMode = () => {
  return (
    process.env.GATSBY_LEAD_FORM_TEST_MODE === "on" ||
    process.env.GATSBY_LEAD_FORM_TEST_MODE === "true"
  );
};
const zapierOn = () => {
  if (process.env.GATSBY_ZAPIER_MODE === "on") {
    return true;
  }
  if (process.env.GATSBY_ZAPIER_MODE === "test") {
    return true;
  }
  if (process.env.GATSBY_ZAPIER_MODE === "local") {
    return true;
  }
  return false;
};
const zapierUrl = webhookUrl => {
  if (process.env.GATSBY_ZAPIER_MODE === "local") {
    return process.env.GATSBY_ZAPIER_LOCAL_WEBHOOK_URL;
  }
  if (process.env.GATSBY_ZAPIER_MODE === "test") {
    return (
      process.env.GATSBY_ZAPIER_DEV_WEBHOOK_URL ||
      webhookUrl ||
      process.env.GATSBY_ZAPIER_WEBHOOK_URL
    );
  }
  return webhookUrl || process.env.GATSBY_ZAPIER_WEBHOOK_URL;
};
const newsletter = () => {
  return {
    webhook:
      process.env.ZAPIER_NEWSLETTER_WEBHOOK_URL ||
      "https://hooks.zapier.com/hooks/catch/12254467/3vhwgrx/",
    formId:
      process.env.GATSBY_HUBSPOT_NEWSLETTER_FORM ||
      "7bd4a4d9-ab10-4880-b226-7682ecde5fc0",
    function: `${process.env.GATSBY_ZAPIER_WEBHOOK_URL}/hubspot-subscribe`,
  };
};
const recaptchaKey = () => {
  return process.env.GATSBY_ACTIVE_ENV === "development"
    ? process.env.GATSBY_DEV_RECAPTCHA_KEY
    : process.env.GATSBY_RECAPTCHA_KEY;
};
const segmentEnabled = () => {
  return !!(
    process.env.GATSBY_SEGMENT_TRACKING === "true" ||
    process.env.GATSBY_SEGMENT_TRACKING === "on"
  );
};
const hubspotEnabled = () => {
  return !!(
    process.env.GATSBY_HUBSPOT_MODE === "on" &&
    process.env.GATSBY_HUBSPOT_HUB_ID
  );
};
const hubspotRegion = () => {
  return process.env.GATSBY_HUBSPOT_REGION || "na1";
};
const hubspotPortalId = () => {
  return process.env.GATSBY_HUBSPOT_HUB_ID || "43908954";
};
const hubsportDefaultForm = () => {
  return (
    process.env.GATSBY_HUBSPOT_DEFAULT_FORM ||
    "cae62dff-35c8-426f-9d0c-7d1813a4b847"
  );
};
const textAd = () => {
  if (hasWindow) {
    const className = [
      "pub_300x250",
      "pub_300x250m",
      "pub_728x90",
      "text-ad",
      "textAd",
      "text_ad",
      "text_ads",
      "text-ads",
    ];
    let blockedElement = document?.querySelector(`.${className.join(".")}`);
    if (!blockedElement) {
      blockedElement = document?.createElement("div");
      blockedElement.className = className.join(" ");
      blockedElement.setAttribute(
        "style",
        "position: absolute; top: -10px; left: -10px; width: 1px; height: 1px;"
      );
      document?.body?.appendChild(blockedElement);
    }

    return (
      window?.document?.body?.getAttribute("abp") != null ||
      blockedElement.offsetParent === null ||
      blockedElement.offsetHeight === 0 ||
      blockedElement.offsetLeft === 0 ||
      blockedElement.offsetTop === 0 ||
      blockedElement.offsetWidth === 0 ||
      blockedElement.clientHeight === 0 ||
      blockedElement.clientWidth === 0
    );
  }
  return false;
};
// Test if ads loaded on page
const adSense = () => {
  if (hasWindow) {
    const ad = document?.querySelector("ins.adsbygoogle");
    if (ad && ad.innerHTML.replace(/\s/g, "").length === 0) {
      return true;
    }
    return false;
  }
  return false;
};
// Test Segment availability
const segment = () => {
  const segmentPromise = () => {
    const endPoint = `https://cdn.segment.com/analytics.js/v1/${segmentId}/analytics.min.js`;
    return axios
      .get(endPoint)
      .then(() => {
        return "Success on Segment";
      })
      .catch(() => {
        return "Error on Segment";
      });
  };

  segmentPromise().then(result => {
    const response = result;
    return response;
  });
};
// setup UTM tracking
const currentUtm = location => {
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const utm_campaign = urlParams.get("utm_campaign");
  const utm_term = urlParams.get("utm_term");
  const utm_content = urlParams.get("utm_content");
  const utm_medium = urlParams.get("utm_medium");
  const utm_source = urlParams.get("utm_source");
  const utm_id = urlParams.get("utm_id");
  const utm_source_platform = urlParams.get("utm_source_platform");
  const utm_creative_format = urlParams.get("utm_creative_format");
  const utm_marketing_tactic = urlParams.get("utm_marketing_tactic");
  const hasUtms =
    utm_campaign ||
    utm_term ||
    utm_content ||
    utm_medium ||
    utm_source ||
    utm_id ||
    utm_source_platform ||
    utm_creative_format ||
    utm_marketing_tactic;
  const utm = hasUtms && {
    utm_campaign: !!utm_campaign && decodeURIComponent(utm_campaign),
    utm_term: !!utm_term && decodeURIComponent(utm_term),
    utm_content: !!utm_content && decodeURIComponent(utm_content),
    utm_medium: !!utm_medium && decodeURIComponent(utm_medium),
    utm_source: !!utm_source && decodeURIComponent(utm_source),
    utm_id: !!utm_id && decodeURIComponent(utm_id),
    utm_source_platform:
      !!utm_source_platform && decodeURIComponent(utm_source_platform),
    utm_creative_format:
      !!utm_creative_format && decodeURIComponent(utm_creative_format),
    utm_marketing_tactic:
      !!utm_marketing_tactic && decodeURIComponent(utm_marketing_tactic),
  };
  return utm;
};
const utm = () => {
  const storedUtms =
    LocalStorage.getSession("_utm", false) || LocalStorage.get("_utm", false);
  const utms = !!storedUtms && JSON.parse(storedUtms);
  const first = utms && utms[0];
  const last = utms && utms[utms.length - 1];
  const track = last && {
    utm_campaign: last?.utm_campaign,
    utm_term: last?.utm_term,
    utm_content: last?.utm_content,
    utm_medium: last?.utm_medium,
    utm_source: last?.utm_source,
  };
  const custom = last && {
    utm_campaign: last?.utm_campaign,
    utm_content: last?.utm_content,
    utm_source: last?.utm_source,
    utm_id: last?.utm_id,
    utm_source_platform: last?.utm_source_platform,
    utm_creative_format: last?.utm_creative_format,
    utm_marketing_tactic: last?.utm_marketing_tactic,
    "UTM Content": last?.utm_content,
    "UTM Source": last?.utm_source,
    "UTM Campaign": last?.utm_campaign,
    "UTM Medium": last?.utm_medium,
    "UTM Term": last?.utm_term,
  };
  return { utms, first, last, track, custom };
};
// load hubspot items by url params
const article = location => {
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const articleItem = urlParams.get("show_article");
  let itemValid = articleItem && Number(articleItem);
  itemValid = Number.isInteger(itemValid);
  if (itemValid) {
    return articleItem;
  }
  return null;
};
const news = location => {
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const newsItem = urlParams.get("show_news");
  let itemValid = newsItem && Number(newsItem);
  itemValid = Number.isInteger(itemValid);
  if (itemValid) {
    return newsItem;
  }
  return null;
};
const space = location => {
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const showSpace = urlParams.get("show_space");
  const spaces = [
    { name: "home" },
    { name: "messages" },
    { name: "help" },
    { name: "news" },
    { name: "tasks" },
  ];
  const checkSpace = !!spaces.find(s => s.name === showSpace);
  if (checkSpace) {
    return showSpace;
  }
  return null;
};

const isLyraUserId = s => {
  if (s) {
    const auth0 = s.includes("auth0|");
    const uuid = s.match(
      "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
    ); // uuid v1-5 regex
    return auth0 || !!uuid?.length;
  }
  return null;
};

const LanguageDetector = {
  name: "navigator",
  detect() {
    const found = [];
    if (hasNavigator) {
      const { languages, userLanguage, language } = navigator;
      if (language) {
        found.push(language);
        return language;
      }
      if (userLanguage) {
        found.push(userLanguage);
        return userLanguage;
      }
      if (languages) {
        // chrome only; not an array, so can't use .push.apply instead of iterating
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < languages.length; i++) {
          found.push(languages[i]);
        }
        return found[0];
      }
    }
    return found?.length > 0 ? found && found[0] : "en-US";
  },
};

// Test supported browser lang for spanish
const supportedLanguages = [
  "es",
  "es-419",
  "es-AR",
  "es-BO",
  "es-BR",
  "es-BZ",
  "es-CL",
  "es-CO",
  "es-CR",
  "es-CU",
  "es-DO",
  "es-EA",
  "es-EC",
  "es-ES",
  "es-GQ",
  "es-GT",
  "es-HN",
  "es-IC",
  "es-MX",
  "es-NI",
  "es-PA",
  "es-PE",
  "es-PH",
  "es-PR",
  "es-pr",
  "es-PY",
  "es-SV",
  "es-US",
  "es-UY",
  "es-VE",
  "ast-ES",
  "ca-ES",
  "gl-ES",
];
// Test for browser language
const language = () => {
  const detectedLanguage = LanguageDetector.detect();
  const lang =
    detectedLanguage && supportedLanguages.includes(detectedLanguage)
      ? "es-pr"
      : detectedLanguage;
  return lang;
};

const browser = () => {
  return {
    navigator: hasNavigator,
    window: hasWindow,
    document: hasDocument,
    language: LanguageDetector.detect(),
  };
};

const detect = {
  browser,
  environment,
  leadFormMode,
  newsletter,
  zapierOn,
  zapierUrl,
  recaptchaKey,
  segmentEnabled,
  hubspotEnabled,
  hubspotRegion,
  hubspotPortalId,
  hubsportDefaultForm,
  textAd,
  adSense,
  segment,
  currentUtm,
  utm,
  isLyraUserId,
  article,
  news,
  space,
  language,
};

export default detect;
