/* eslint-disable react/prop-types */

import React, { Suspense } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TransitionState } from "gatsby-plugin-transition-link";
import { Container } from "@layout";
import { RichText } from "@ui";
import { RandomShapes } from "@decoration";
import HeroButton from "./Hero/HeroButton";
import HeroShapesHome from "./Hero/HeroShapesHome";

const HeroAnimationComponent = React.lazy(() =>
  import("./HeroHomepage/HeroAnimationComponent")
);

const HeroHomepage = ({
  heading,
  content,
  transitionStatus,
  size,
  variant,
}) => {
  const remoteAnimation =
    content && content.embed_animation && content.embed_code;
  return (
    <section
      className={classNames({
        "bg-green": variant === "green",
        "bg-blue": variant === "blue",
        "hero--homepage gradient-blue min-h-hero pt-32 pb-16 flex flex-col justify-center xxs:pt-20 pt-24 sm:pt-32 items-center relative": true,
      })}
    >
      <div className="absolute w-full h-full inset-0 z-0 text-white text-center sm:text-left rounded-br-heroxs sm:rounded-br-herosm md:rounded-br-heromd overflow-hidden">
        <HeroShapesHome />
      </div>
      <Container size={size}>
        <div className="flex flex-wrap">
          <div className="xxs:w-full text-center sm:text-left w-6/7 mx-auto sm:w-4/7 lg:pl-0 md:w-1/2 xl:w-3/7 xxs:px-0 py-6 sm:px-10 sm:p-0 z-10 relative mt-0 sm:mt-0">
            {content.hero_subheading && (
              <h2 className="leading-tight text-sm font-bold antialiased text-white uppercase mb-2 tracking-2">
                {content.hero_subheading[0].text}
              </h2>
            )}
            <h1 className="leading-tighter font-bold antialiased text-white text-3xl md:text-4xl xl:text-5xl mb-4">
              {heading}
            </h1>
            <div className="rich-text text-white text-xl md:text-2xl">
              <RichText content={content.hero_copy} />
            </div>
            <div className="mt-8">
              <HeroButton content={content} color="bg-black" />
            </div>
          </div>
          <div className="w-full h-24 sm:h-auto mt-24 mb-20 sm:my-0 mx-auto xl:mr-0 sm:w-3/7 md:w-1/2 xl:w-4/7 xxs:px-0 px-6 xl:px-12 relative flex-col flex items-center justify-center">
            <div className="absolute inset-0 px-12 sm:p-6">
              <div className="h-full w-full relative">
                <RandomShapes hero />
              </div>
            </div>
            {typeof window !== "undefined" && (
              <Suspense fallback={<div />}>
                <HeroAnimationComponent
                  transitionStatus={transitionStatus}
                  animation={remoteAnimation}
                />
              </Suspense>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

HeroHomepage.defaultProps = {
  size: "md",
  variant: "blue",
};

HeroHomepage.propTypes = {
  heading: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
};

export default ({ heading, content, variant }) => {
  return (
    <TransitionState>
      {({ transitionStatus }) => {
        return (
          <HeroHomepage
            heading={heading}
            content={content}
            transitionStatus={transitionStatus}
            variant={variant}
          />
        );
      }}
    </TransitionState>
  );
};
