/* eslint-disable react/prop-types */
import * as React from "react";
import { Highlight, Snippet } from "react-instantsearch-dom";
import { PrismicLink } from "@utilities";

const PageHit = clickHandler => ({ hit }) => (
  <div>
    <PrismicLink to={hit?.slug} onClick={clickHandler}>
      <div>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </div>
    </PrismicLink>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
);

export default PageHit;
