/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React from "react";

export default React.memo(({ url }) => {
  const Player = () => {
    if (typeof window !== "undefined") {
      // need to import here since plyr needs dom
      // eslint-disable-next-line global-require
      const Plyr = require("react-plyr").default;
      if (url.match("http(s)?://(www.)?youtube|youtu.be")) {
        if (url.match("embed")) {
          return (
            <div className="plyr__video-embed" id="player">
              <iframe
                src={`https://www.youtube.com/embed/${
                  url.split(/embed\//)[1].split('"')[0]
                }`}
                allowFullScreen
                allow="autoplay"
                title="embed"
              />
            </div>
          );
        }
        return (
          <Plyr
            type="youtube"
            videoId={url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0]}
            id={url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0]}
          />
        );
      }
      if (url.match(/vimeo\.com\/(\d+)/)) {
        return (
          <Plyr
            type="vimeo"
            videoId={url.split("/").pop()}
            id={url.split("/").pop()}
          />
        );
      }
    }
    return null;
  };
  return (
    <div>
      <Player />
    </div>
  );
});
