export default val => {
  switch (val) {
    case "Left":
    case "Top":
      return (1 / 6).toString();
    case "Center":
      return (0.5).toString();
    case "Right":
    case "Bottom":
      return (5 / 6).toString();
    default:
      return (0.5).toString();
  }
};
