// add non-breaking-space between last two words of text that is longer than
// 3 words and the last word is less than 13 characters and the second-to-last
// word is less than 11 characters. if the penultimate word is less than 5
// characters and the last word is less than 9, then the two words before the widow will be pulled down.

const unWidower = doc => {
  if (typeof doc !== "undefined") {
    // eslint-disable-next-line no-console
    // console.log("Removing widows...");
    let processedDoc = doc;
    // match all text strings in stringified doc supplied by gatsby
    const regex = new RegExp("(?<=>)(?!<).*?(?=<)", "g");
    const matches = doc.replace(/(\(|\)|\\|\/|\.)/g, "").match(regex);
    if (matches != null) {
      matches.map(string => {
        let newstring;
        const splitstring = string.split(" ");
        if (splitstring.length > 3) {
          const lastword = splitstring[splitstring.length - 1];
          const penultword = splitstring[splitstring.length - 2];
          const antepenultword = splitstring[splitstring.length - 3];
          if (
            lastword.length < 9 &&
            penultword.length < 4 &&
            antepenultword.length < 5
          ) {
            // replace last two breaking spaces with nbsps
            newstring = string.replace(/ (?=[^ ]*$)/i, "&nbsp;");
            newstring = string.replace(/ (?=[^ ]*$)/i, "&nbsp;");
          } else if (lastword.length < 13 && penultword.length < 11) {
            // replace last breaking space with a nbsp
            newstring = string.replace(/ (?=[^ ]*$)/i, "&nbsp;");
          }
          const stringMatch = new RegExp(`(?<=>)(?!<)${string}(?=</)`, "g");
          processedDoc = processedDoc.replace(stringMatch, newstring);
          return newstring;
        }
        return null;
      });
      return processedDoc;
    }
  }
  return doc;
};

export default unWidower;
