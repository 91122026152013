/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { HeaderContext } from "@context";
import { HeroContact, Container } from "@layout";
import { EopCta, MetaData } from "@common";
import { ShapesPatternRepeat } from "@svg";
// import ContactButtons from "../components/contact/ContactButtons";
import { ContactSalesButtons } from "@contact";
import { PageTransition } from "@components";

const Onboard = props => {
  // disable eslint because the content is so deep
  // eslint-disable-next-line
  const { onboard } = props.data.prismic;
  const { setSunrisePage, setBlogLayout } = useContext(HeaderContext);
  useEffect(() => {
    setSunrisePage(false);
    setBlogLayout(false);
  }, []);
  // const onboardOptions = onboard.onboard_options[0];
  // const showSlices = onboardOptions.show_form;
  // const showSalesButtons = onboardOptions.display_link;
  return (
    <>
      <MetaData
        content={{ ...onboard, hide: true, meta_no_index: true }}
        type="webpage"
      />
      <PageTransition theme="light">
        <article className="z-10 relative">
          <HeroContact
            contactOptions={onboard.onboard_options[0]}
            content={onboard.hero[0]}
            variant="light"
            split={false}
            curve
            inverted
          />
          <section className="relative pb-24">
            <div className="absolute inset-0 flex items-center justify-center mt-8">
              <ShapesPatternRepeat
                className="opacity-3 text-blue stroke-current h-full w-full"
                dimensions={[10, 10]}
              />
            </div>
            <Container className="-mt-8 pt-24">
              <ContactSalesButtons
                form={onboard.body[0]}
                uid={onboard._meta.uid}
                content={onboard.onboard_options[0]}
                variant="light"
                split={false}
                onboarding
              />
            </Container>
            <Container>
              <EopCta content={onboard.cta_eop[0]} gradient="blue" />
            </Container>
            <div className="bg-transparent h-24 relative z-0" />
          </section>
        </article>
      </PageTransition>
    </>
  );
};

export default Onboard;

export const query = graphql`
  query OnboardQuery {
    prismic {
      onboard(lang: "en-us", uid: "onboard") {
        _meta {
          uid
        }
        title
        meta_title
        meta_image
        meta_description
        onboard_options {
          show_form
          chat_button_text
          chat_heading
          display_link
          content
          email_address
          email_heading
          link_heading
          link_label
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
        hero {
          heading
          subtitle
          button_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
        cta_eop {
          tracking_event
          heading
          subheading
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
        }
        body {
          ... on PRISMIC_OnboardBodyEmail_signup_block {
            type
            label
            primary {
              background_image
              failure_message
              heading
              submit_button_text
              submit_button_color
              icon
              subtitle
              success_message
              list_id
              promo_code
              promo_memo
              tracking_event
              form_collector
              form_id
              webhook_url
              upload_widget
              recaptcha_widget
              require_phone_number
              show_phone_number
              collect_volume
            }
            fields {
              label
              value
            }
          }
        }
      }
    }
  }
`;
