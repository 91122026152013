/* eslint-disable react/prop-types */
import React from "react";

export default React.memo(({ position }) => {
  // convert position to translate offset
  const offset = `${position[0] * 40}, ${position[1] * 40}`;
  return (
    <rect
      x="3"
      y="3"
      width="40"
      height="40"
      transform={`translate(${offset})`}
    />
  );
});
