import React, { useContext } from "react";
import { uid } from "react-uid";
import { Container } from "@layout";
import { HeaderContext } from "@context";
import Notification from "./Notifications/Notification";

export default () => {
  const { notifications } = useContext(HeaderContext);
  return (
    <div id="AppNotifications" className="fixed top-0 left-0 right-0 z-60">
      <Container>
        {Object.keys(notifications).map(notification => (
          <Notification
            key={uid(notification)}
            notification={notifications[notification]}
          />
        ))}
      </Container>
    </div>
  );
};
