import React from "react";
import PropTypes from "prop-types";
import { useUID } from "react-uid";
import { LargeCard } from "@common";

const LargeCardGroup = React.memo(({ content, color, cards }) => {
  const cardsPerColumn = cards ? `md:w-1/${cards}` : "md:w-1/2";
  return (
    <div className="flex flex-wrap -mx-3">
      {content.map(obj => {
        const uid = useUID();
        return (
          <div key={uid} className={`w-full ${cardsPerColumn} px-3 mb-12`}>
            <LargeCard content={obj} color={color} />
          </div>
        );
      })}
    </div>
  );
});

LargeCardGroup.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string.isRequired,
  cards: PropTypes.number,
};

LargeCardGroup.defaultProps = {
  cards: 2,
};

export default LargeCardGroup;
