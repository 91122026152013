/* eslint-disable react/prop-types */
import React, { Suspense } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TransitionState } from "gatsby-plugin-transition-link";
import { Image } from "@ui";
import { RandomShapes } from "@decoration";

const AnimationComponent = React.lazy(() =>
  import("../../layout/HeroHomepage/HeroAnimationComponent")
);

const LeftColumn = React.memo(
  ({ image, media, openModal, lessOffset, transitionStatus, float }) => {
    const embed_code =
      media &&
      media.embed_code &&
      media.embed_code[0] &&
      media.embed_code[0].text;
    if (media != null) {
      return (
        <div className="flex flex-wrap sm:flex-no-wrap items-center justify-center h-full items-end">
          <div
            className={classNames({
              "-mb-12 sm:my-0 md:-my-12 lg:-my-10 xl:-my-6": !float,
              floating: float,
              "intro-image-container w-full mt-6": true,
            })}
          >
            {typeof window !== "undefined" &&
            media.embed_animation &&
            embed_code ? (
              <Suspense fallback={<div />}>
                <AnimationComponent
                  transitionStatus={transitionStatus}
                  animation={embed_code}
                />
              </Suspense>
            ) : (
              <Image
                className={classNames({
                  "sm:-mt-4 md:-mt-6 sm:-mt-12": lessOffset && !float,
                  "sm:-mt-12 md:-mt-24 sm:-mt-12": !lessOffset && !float,
                  "mt-12 floating--image": float,
                  "w-full sm:flex-shrink-1 sm:absolute inset-0": true,
                })}
                src={image.url}
                transparent
              />
            )}
          </div>
          {media.media_button_text && (
            <button
              type="button"
              className={`${media.color ||
                "bg-black"} transition px-12 mt-auto sm:mr-auto py-6 md:px-24 md:py-8 text-center mt-auto sm:-mr-6 md:-mr-12 lg:-mr-24 font-bold md:-mb-6 xl:-mb-12 text-xl text-white relative z-20 hover:translate-y-lift shadow hover:shadow-lift`}
              onClick={() => {
                openModal();
              }}
            >
              <div className="mb-4">{media.media_description}</div>
              <div>
                <span className="bg-white py-3 px-8 text-teal transition font-bold text-sm rounded-md inline-block">
                  {media.media_button_text}
                </span>
              </div>
            </button>
          )}
          <RandomShapes opacity=".25" />
        </div>
      );
    }
    return (
      <div className="flex flex-wrap sm:flex-no-wrap items-center justify-center h-full items-end">
        <div
          className={classNames({
            "-mb-12 sm:my-0 md:-my-12 lg:-my-10 xl:-my-6": !float,
            floating: float,
            "intro-image-container w-full mt-6": true,
          })}
        >
          {image.url && (
            <Image
              className={classNames({
                "sm:-mt-4 md:-mt-6 sm:-mt-12": lessOffset && !float,
                "sm:-mt-12 md:-mt-24 sm:-mt-12": !lessOffset && !float,
                "mt-12 floating--image-2": float,
                "w-full sm:flex-shrink-1 sm:absolute inset-0": true,
              })}
              src={image.url}
              transparent
            />
          )}
        </div>
      </div>
    );
  }
);

LeftColumn.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string.isRequired,
    dimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  // media: PropTypes.shape({
  //   embed_code: PropTypes.string,
  //   media_button_text: PropTypes.string,
  //   media_description: PropTypes.string,
  //   media_url: PropTypes.string,
  // }).isRequired,
  openModal: PropTypes.func.isRequired,
  float: PropTypes.bool,
};

LeftColumn.defaultProps = {
  float: false,
};

export default ({ image, media, content, openModal, lessOffset, float }) => {
  return (
    <TransitionState>
      {({ transitionStatus }) => {
        return (
          <LeftColumn
            lessOffset={lessOffset}
            image={image}
            media={media}
            content={content}
            transitionStatus={transitionStatus}
            openModal={openModal}
            float={float}
          />
        );
      }}
    </TransitionState>
  );
};
