/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon, Button, RichText } from "@ui";
import { Container } from "@layout";
import HeroShapes from "./Hero/HeroShapes";

const HeroContact = React.memo(
  ({
    content,
    contactOptions,
    variant,
    centered,
    split,
    noShapes,
    inverted,
  }) => {
    return (
      <section
        className={classNames({
          "text-center": centered,
          "hero--contact gradient-light flex flex-col justify-center min-h-hero-mobile sm:min-h-hero-sm pt-48 pb-32 items-center relative": true,
        })}
      >
        <HeroShapes noShapes={noShapes} inverted={inverted} variant={variant} />
        <Container>
          <div
            className={classNames({
              "justify-center": centered,
              "flex flex-wrap": true,
            })}
          >
            <div
              className={classNames({
                "w-1/2": split,
                "w-1/3": !split,
                "z-10 relative pr-4": true,
              })}
            >
              <h1
                className={classNames({
                  "text-gray": variant === "light",
                  "text-white": variant !== "light",
                  "leading-tight font-bold antialiased text-3xl md:text-5xl xl:text-6xl mb-6 lg:mb-10": true,
                })}
              >
                {content.heading[0].text}
              </h1>
              <div
                className={classNames({
                  "text-white": variant !== "light",
                  "rich-text text-xl": true,
                })}
              >
                <RichText content={content.subtitle} />
              </div>
            </div>
            <div
              className={classNames({
                "w-1/2": split,
                "w-2/3": !split,
                "z-10 relative": true,
              })}
            >
              {!contactOptions.content && (
                <div className="flex justify-center">
                  <Icon iconName="IconPermit" />
                </div>
              )}
              <h1
                className={classNames({
                  "text-blue": variant === "light",
                  "text-white": variant !== "light",
                  "leading-tight font-bold antialiased text-3xl md:text-5xl xl:text-6xl mb-6 lg:mb-10": true,
                })}
              >
                {contactOptions.link_heading[0].text}
              </h1>
              <div className="rich-text text-xl">
                <RichText content={contactOptions.content} />
              </div>
              <div className="mt-auto">
                {contactOptions.link && (
                  <Button
                    bgClass="bg-white"
                    textClass="text-blue"
                    link={contactOptions.link}
                  >
                    {contactOptions.link_label}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
);

HeroContact.defaultProps = {
  variant: "light",
  centered: false,
  split: true,
};

HeroContact.propTypes = {
  variant: PropTypes.string,
  centered: PropTypes.bool,
  split: PropTypes.bool,
};

export default HeroContact;
