import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Icon, RichText } from "@ui";
import { PrismicLink } from "@utilities";

const CTALarge = React.memo(({ content, color }) => {
  return (
    <PrismicLink
      link={content.button_link}
      className="block p-6 md:px-12 md:py-12 bg-white shadow my-12 text-center transition rounded-xl hover:translate-y-lift hover:shadow-lift"
    >
      <div
        className={`${classNames({
          "mx-auto flex justify-center my-4 w-12 h-12": content.icon != null,
        })} text-${color}`}
      >
        <Icon iconName={content.icon} />
      </div>
      <h2 className="text-2xl sm:text-3xl font-bold my-4">
        {content.heading[0].text}
      </h2>
      <div className="rich-text max-w-xl mx-auto text-xl my-4">
        <RichText content={content.copy} />
      </div>
      <div className="mt-10">
        <Button
          bgClass="bg-black"
          textClass="text-white"
          hoverState={["opacity-75"]}
        >
          {content.button_text}
        </Button>
      </div>
    </PrismicLink>
  );
});

CTALarge.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
  ).isRequired,
  color: PropTypes.string.isRequired,
};

export default CTALarge;
