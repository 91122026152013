/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/react-in-jsx-scope */
import React, { Fragment, useRef, useContext } from "react";
import classNames from "classnames";
import { useUID } from "react-uid";
import { Popover, Transition } from "@headlessui/react";
import { SunIcon, SearchIcon } from "@heroicons/react/solid";
import { HeaderContext } from "@context";
import { PrismicLink } from "@utilities";
import { SearchBox } from "@search";
import { detect } from "@utils";
import { Icon } from "@ui";

/*
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
*/

export default React.memo(
  ({
    categories,
    posts,
    active,
    buttonLabel,
    noPadding,
    hover,
    header,
    limit,
    width,
    search,
    searchSelector,
    searchPlaceHolder,
    nav,
    cta,
  }) => {
    const hasWindow = detect.browser().window;
    const uid = useUID();
    const { blogPosts, blogCategories, currentPath } = useContext(
      HeaderContext
    );
    const hoverMenu = hover || header;
    const useHover = true;
    const selector = searchSelector || `search_${uid}`;
    const placeHolder = searchPlaceHolder || "Search...";
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);
    const timeoutDuration = 222;
    let timeout;
    const toggleMenu = () => buttonRef?.current?.click();
    const onMouseHover = open => {
      clearTimeout(timeout);
      open && (timeout = setTimeout(() => toggleMenu(), timeoutDuration));
    };
    const icons = {
      insights: <Icon className="w-4 h-4" iconName="IconInsights" />,
      events: <Icon className="w-4 h-4" iconName="IconEvents" />,
      news: <Icon className="w-4 h-4" iconName="IconNews" />,
      webinars: <Icon className="w-4 h-4" iconName="IconWebinars" />,
      permitting: <Icon className="w-4 h-4" iconName="IconPermitting" />,
      design: <Icon className="w-4 h-4" iconName="IconDesign" />,
      "case-studies": <Icon className="w-4 h-4" iconName="IconCaseStudies" />,
      "": <Icon iconName="IconClock" className="w-4 h-4" />,
    };

    const activeCategories = categories || blogCategories;
    const recentPosts = posts || blogPosts;
    const limitedPosts = limit || 5;
    // Limit how many posts to show in the menu
    const menuPosts =
      recentPosts && recentPosts.filter((val, i) => i < limitedPosts);

    return (
      <Popover className="relative top-0 pt-1" style={{ width: "100%" }}>
        <>
          <div className="max-w-7xl mx-auto" style={{ width: "100%" }}>
            <div
              className={classNames({
                "flex justify-between items-center md:justify-start": true,
                "p-0": noPadding,
                "py-2": !noPadding,
              })}
            >
              <Popover.Group
                as="nav"
                className={classNames({
                  "flex flex-auto m-auto": true,
                })}
              >
                <Popover className={`relative ${width || "w-full"}`}>
                  {// eslint-disable-next-line no-shadow
                  ({ open }) => (
                    <>
                      {hoverMenu && (
                        <Popover.Button
                          onClick={() => useHover && onMouseHover(!open)}
                          ref={buttonRef}
                          className={classNames({
                            "mx-5 text-blue transition duration-1000 group rounded-lg inline-flex items-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue": true,
                            "bg-gray-light border-blue hover:text-white hover:bg-blue hover:gradient-blue p-2 px-4 m-auto": hoverMenu,
                            "hidden no-outline": !hoverMenu,
                          })}
                        >
                          <span>{buttonLabel || "Explore the Blog"}</span>
                          {hoverMenu ? (
                            <SearchIcon
                              className={classNames({
                                "ml-2 h-5 w-5 group-hover:text-gray-offwhite": true,
                              })}
                              aria-hidden="true"
                            />
                          ) : (
                            <SunIcon
                              className={classNames({
                                "ml-2 h-5 w-5 group-hover:text-gray-offwhite": true,
                              })}
                              aria-hidden="true"
                            />
                          )}
                        </Popover.Button>
                      )}

                      <Transition
                        show={hoverMenu ? open : true}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          onClick={() => onMouseHover(open)}
                          onMouseLeave={() => onMouseHover(open)}
                          ref={dropdownRef}
                          static
                          className={classNames({
                            "z-20 transform px-2 xs:max-w-xs xl:max-full sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2": true,
                            "-ml-40 lg:ml-0": noPadding,
                            "inline-grid mt-2": !hoverMenu,
                            "absolute mt-8 blog-menu": hoverMenu,
                            relative: !hoverMenu,
                            [width || "w-full"]: true,
                          })}
                        >
                          <div
                            className={classNames({
                              "ring-1 ring-black ring-opacity-5 overflow-hidden": true,
                              "rounded-tr-sm": !hoverMenu,
                              "rounded-bl-xl rounded-br-xl shadow-lg": hoverMenu,
                            })}
                          >
                            <div
                              className={classNames({
                                "relative grid gap-6 px-5 py-6 sm:gap-6 sm:p-6 text-left": true,
                                "bg-white": hoverMenu,
                              })}
                            >
                              {search && (
                                <div className="site--search relative top-0 -m-4 p-2 mb-1">
                                  <div id={selector} className="search--box" />
                                  {hasWindow && window && (
                                    <SearchBox
                                      selector={selector}
                                      limit={108}
                                      placeHolder={placeHolder}
                                    />
                                  )}
                                </div>
                              )}
                              <PrismicLink
                                // eslint-disable-next-line react/no-array-index-key
                                route="/blog"
                                className={classNames({
                                  "-m-3 p-2 flex items-start rounded-lg text-black hover:text-blue": true,
                                  "hover:bg-gray-menu": hoverMenu,
                                  "hover:bg-white": !hoverMenu,
                                  "bg-gray-light text-blue hover:text-purple":
                                    currentPath === "/blog" && hoverMenu,
                                  "bg-white text-blue hover:text-purple":
                                    currentPath === "/blog" && !hoverMenu,
                                })}
                                activeclass={classNames({
                                  "text-blue hover:text-purple": true,
                                  "bg-gray-light hover:bg-gray-menu": hoverMenu,
                                  "bg-white hover:bg-white": !hoverMenu,
                                })}
                              >
                                <span className="block text-blue w-5 h-5 mr-1 hover:text-purple">
                                  <Icon
                                    className="flex-shrink-0 h-5 w-5 text-blue hover:text-purple"
                                    aria-hidden="true"
                                    heroIcon="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                                  />
                                </span>
                                <div className="ml-4">
                                  <p className="text-base font-medium">
                                    All Articles
                                  </p>
                                </div>
                              </PrismicLink>
                              {activeCategories &&
                                activeCategories.map((item, i) => {
                                  const link = `/blog/${item.slug}`;
                                  const isActive = item.name === active;
                                  return (
                                    <PrismicLink
                                      // eslint-disable-next-line react/no-array-index-key
                                      key={i}
                                      route={link}
                                      className={classNames({
                                        "-m-3 p-2 flex items-start rounded-lg text-black hover:text-blue": true,
                                        "bg-gray-light text-blue hover:text-purple": isActive,
                                        "hover:bg-gray-menu": hoverMenu,
                                        "hover:bg-white": !hoverMenu,
                                      })}
                                      activeclass={classNames({
                                        "text-blue hover:text-purple": true,
                                        "bg-gray-light hover:bg-gray-menu": hoverMenu,
                                        "bg-white hover:bg-white": !hoverMenu,
                                        [isActive]: true,
                                      })}
                                    >
                                      {icons[item.slug] && (
                                        <span
                                          className={classNames({
                                            "block text-blue w-5 h-5 mr-1 hover:text-purple": true,
                                            "text-black": isActive,
                                          })}
                                        >
                                          {icons[item.slug]}
                                        </span>
                                      )}
                                      <div className="ml-4">
                                        <p className="text-base font-medium">
                                          {item.name}
                                        </p>
                                      </div>
                                    </PrismicLink>
                                  );
                                })}
                              {nav &&
                                nav.map((item, i) => {
                                  const link_lang = item.link;
                                  return (
                                    <PrismicLink
                                      // eslint-disable-next-line react/no-array-index-key
                                      key={i}
                                      link={link_lang}
                                      className={classNames({
                                        "-m-3 p-2 flex items-start rounded-lg text-black hover:text-blue cursor-pointer": true,
                                        "hover:bg-gray-menu": hoverMenu,
                                        "hover:bg-white": !hoverMenu,
                                      })}
                                    >
                                      {item?.hero_icon && (
                                        <span className="block text-blue w-5 h-5 mr-1 hover:text-purple">
                                          <Icon
                                            className="flex-shrink-0 h-5 w-5 text-blue hover:text-purple"
                                            aria-hidden="true"
                                            heroIcon={item?.hero_icon}
                                          />
                                        </span>
                                      )}
                                      <div className="ml-4">
                                        <p className="text-base font-medium">
                                          {item?.link_text}
                                        </p>
                                      </div>
                                    </PrismicLink>
                                  );
                                })}
                            </div>
                            {menuPosts ? (
                              <div className="px-5 py-5 bg-gray-menu sm:px-6 sm:py-6">
                                <div>
                                  <h3 className="text-sm tracking-wide font-medium text-gray-beta uppercase">
                                    Recent Posts
                                  </h3>
                                  <ul className="mt-4 space-y-4">
                                    {menuPosts.map((post, i) => {
                                      const route = `/blog/${post?.node?._meta?.uid}`;
                                      return (
                                        <li
                                          key={post?.node?.title}
                                          className="text-sm truncate"
                                        >
                                          <PrismicLink
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={i}
                                            route={route}
                                            className="recent-posts pre-underline-2 block transition text-black hover:text-base"
                                            activeclass={classNames({
                                              "text-black hover:text-purple underline-2": true,
                                            })}
                                          >
                                            {post?.node?.title}
                                          </PrismicLink>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                                <div className="mt-5 text-sm">
                                  <PrismicLink
                                    // eslint-disable-next-line react/no-array-index-key
                                    route="/blog"
                                    className="view-all-posts block transition hover:underline text-blue hover:text-purple"
                                  >
                                    {" "}
                                    View all posts{" "}
                                    <span aria-hidden="true">&rarr;</span>
                                  </PrismicLink>
                                </div>
                              </div>
                            ) : (
                              <div className="px-5 py-3 bg-gray-menu space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                {cta &&
                                  cta?.map((item, i) => {
                                    const link_lang = item.link;
                                    return (
                                      <div key={i} className="flow-root">
                                        <PrismicLink
                                          // eslint-disable-next-line react/no-array-index-key
                                          link={link_lang}
                                          className="contact-sales cursor-pointer -m-3 p-3 flex items-center rounded-md text-sm font-medium text-gray hover:text-purple"
                                        >
                                          <span className="block text-blue w-5 h-5 mr-1 hover:text-purple">
                                            <Icon
                                              className="flex-shrink-0 h-5 w-5 text-blue hover:text-purple"
                                              aria-hidden="true"
                                              heroIcon={item?.hero_icon}
                                            />
                                          </span>
                                          <span className="ml-3">
                                            {item?.text}
                                          </span>
                                        </PrismicLink>
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </Popover.Group>
            </div>
          </div>
        </>
      </Popover>
    );
  }
);
