/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from "react";
import classNames from "classnames";
import { useUID } from "react-uid";
import { Button } from "@ui";
import { PrismicLink } from "@utilities";
import { HeaderContext } from "@context";
import { BlogNav } from "@blog";
import Menu from "./Popover";
import LanguageMenu from "./LanguageMenu";

export default React.memo(
  ({
    cta_link,
    cta_link_text,
    primary_nav,
    learn_nav,
    learn_nav_label,
    learn_cta_nav,
    solutions_nav,
    solutions_nav_label,
    features_nav,
    features_nav_label,
    show_sign_in,
    recentPosts,
    blog,
    hide,
    full,
  }) => {
    const {
      theme,
      appUrl,
      sdtAppUrl,
      sunrisePage,
      isMobile,
      isBlog,
      postsShowBlogMenu,
      blogMenu,
      postsMenu,
      isOnboarding,
      lang,
      setLang,
      switchLang,
      enforceLang,
      setEnforceLang,
    } = useContext(HeaderContext);
    const [showSignIn] = useState(!!show_sign_in);
    const [hasCta] = useState(!!cta_link_text);
    const [loginNavKey, setLoginNavKey] = useState(useUID());
    const [loginurl, setLoginUrl] = useState(
      `${appUrl}?src=web_nav&key=${loginNavKey}`
    );
    const sunrising = sunrisePage;
    const showBlogMenu = blog || isBlog;
    useEffect(() => {
      setTimeout(() => {
        let sessionId = loginNavKey;
        if (sessionId) {
          setLoginNavKey(sessionId);
          setLoginUrl(`${appUrl}?src=web_nav&key=${sessionId}`);
        } else {
          sessionId = useUID();
          setTimeout(() => {
            sessionId = useUID();
            setLoginNavKey(sessionId);
            setLoginUrl(`${appUrl}?src=web_nav&key=${sessionId}`);
          }, 1222);
        }
      }, 1888);
    }, []);

    const LoginLink = () => {
      if (showSignIn && !isMobile) {
        return (
          <a
            activeclass={classNames({
              "sm:text-blue": theme === "light",
              "underline-2": true,
            })}
            className={classNames({
              "text-white": theme === "green" || theme !== "light",
              "text-black": theme === "light",
              "transition py-2 opacity-100 md:text-sm my-0 mr-4 md:mr-6 lg:mr-8 ml-6 pre-underline-2 xl:text-base hover:text-gray-delta hover-underline-2": true,
            })}
            style={{ paddingTop: ".65rem", cursor: "pointer" }}
            href={sunrising ? sdtAppUrl : loginurl}
          >
            {sunrising ? "LOGIN" : "Sign in"}
          </a>
        );
      }
      return null;
    };

    const CTAButton = () => {
      if (!showBlogMenu && hasCta) {
        return (
          <Button
            bgClass={theme !== "light" ? "bg-white" : "bg-blue"}
            textClass="text-white"
            className={classNames({
              "border-3 border-transparent hover:opacity-100 cursor-pointer transition ease-out duration-333 translate-y-1 font-bold": true,
              "border-white text-blue hover:bg-blue hover:border-white hover:text-white":
                theme !== "light",
              "text-white hover:bg-white hover:border-blue hover:text-blue":
                theme === "light",
            })}
            style={{ paddingTop: "11px !important" }}
            activeclass="opacity-100"
            link={cta_link}
            variant={isMobile ? "xs" : "sm"}
          >
            {cta_link_text}
          </Button>
        );
      }
      return null;
    };

    const BlogMenu = () => {
      if (showBlogMenu && !full) {
        return (
          <BlogNav
            nav={postsShowBlogMenu ? blogMenu : postsMenu}
            theme={theme}
            limit={isMobile ? 6 : 3}
            buttonLabel={isMobile ? "Blog" : "Explore Blog"}
            cta={learn_cta_nav}
            width="w-xs max-w-xs"
            searchSelector="desktopSearch"
            searchPlaceHolder="Search..."
            search
            noPadding
            hover
            header
          />
        );
      }
      return null;
    };

    return (
      <>
        <div
          className={classNames({
            green: theme === "green",
            dark: theme !== "light",
            light: theme === "light",
            "lg:ml-16": !hide || !blog,
            "md:ml-24 lg:ml-32 xl:ml-40": hide || blog,
            "hidden ml-4 md:flex transition": true,
          })}
        >
          <nav
            id="AppDesktopNav"
            className={classNames({
              green: theme === "green",
              dark: theme !== "light",
              light: theme === "light",
              "hidden ml-auto sm:flex py-0 transition": true,
            })}
          >
            {(!hide || !blog) && features_nav && features_nav_label && (
              <Menu
                theme={theme}
                label={features_nav_label}
                nav={features_nav}
                cta={learn_cta_nav}
              />
            )}
            {(!hide || !blog) && solutions_nav && solutions_nav_label && (
              <Menu
                theme={theme}
                label={solutions_nav_label}
                nav={solutions_nav}
                cta={learn_cta_nav}
              />
            )}
            {!full && learn_nav && learn_nav_label && (
              <Menu
                theme={theme}
                label={learn_nav_label}
                nav={learn_nav}
                recentPosts={recentPosts}
                isBlog={isBlog}
                cta={learn_cta_nav}
              />
            )}
            {primary_nav.map((item, i) => {
              if (
                (!hide &&
                  !blog &&
                  !isOnboarding &&
                  item.link_text === "Pricing") ||
                item.link_text === "Precios"
              ) {
                let link_lang = item.link;
                return (
                  <PrismicLink
                    activeclass={classNames({
                      "sm:text-blue": theme === "light",
                      "underline-2": true,
                    })}
                    className={classNames({
                      "text-white": theme === "green" || theme !== "light",
                      "text-black": theme === "light",
                      "py-2 opacity-100 my-0 mr-4 md:mr-6 lg:mr-8 pre-underline-2 text-base hover:text-gray-delta hover-underline-2": true,
                      "hidden ml-auto sm:flex transition": true,
                    })}
                    style={{ paddingTop: ".55rem" }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    link={link_lang}
                  >
                    {item.link_text}
                  </PrismicLink>
                );
              }
            })}
          </nav>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div
          className={classNames({
            transition: true,
            "mr-6 md:mr-0": true,
          })}
          style={{ display: "inline-flex" }}
        >
          {!full && <BlogMenu cta={learn_cta_nav} />}
          {switchLang && (
            <LanguageMenu
              lang={lang}
              setLang={setLang}
              enforceLang={enforceLang}
              setEnforceLang={setEnforceLang}
            />
          )}
          {!isOnboarding && <CTAButton />}
          {!isOnboarding && <LoginLink />}
        </div>
      </>
    );
  }
);
