/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/react-in-jsx-scope */
import React, { useContext, useState, Fragment, useRef } from "react";
import classNames from "classnames";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { HeaderContext } from "@context";
import { PrismicLink } from "@utilities";
import { Icon } from "@ui";
import { open as openChat } from "@hubspot";
/*
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
*/

export default React.memo(({ theme, nav, label, cta, recentPosts, width }) => {
  const { currentLocation, isBlog, enforceLang, allowTracking } = useContext(
    HeaderContext
  );
  const [active, setActive] = useState(false);
  const path = currentLocation?.pathname?.replace("/", "");
  const useHover = true;
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const timeoutDuration = 0;
  let timeout;
  const toggleMenu = () => buttonRef?.current?.click();
  const onMouseHover = open => {
    clearTimeout(timeout);
    open && (timeout = setTimeout(() => toggleMenu(), timeoutDuration));
  };
  const defaultWidth = enforceLang ? "max-w-lg" : width;
  const menuWidth = isBlog ? "max-w-3xl" : defaultWidth;
  const wideMenu = isBlog
    ? {
        columnCount: 2,
        flexDirection: "column",
        display: "flow-root",
      }
    : null;
  const itemMargin = isBlog ? "m-0" : "-m-3";
  return (
    <Popover className="relative" style={{ width: "100%" }}>
      <>
        <div className="max-w-7xl mx-auto" style={{ width: "100%" }}>
          <div className="flex justify-between items-center py-2 md:justify-start">
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button
                ref={buttonRef}
                className="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-beta hover:bg-gray-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue"
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex mr-6 lg:mr-10">
              <Popover
                className={classNames({
                  "underline-2": active,
                  "relative hover-underline-2": true,
                })}
              >
                {// eslint-disable-next-line no-shadow
                ({ open }) => (
                  <>
                    <Popover.Button
                      onMouseEnter={() => useHover && onMouseHover(!open)}
                      ref={buttonRef}
                      className={classNames({
                        "text-white": theme !== "light" || theme === "green",
                        "text-black":
                          theme === "light" ||
                          (open && theme !== "light" && theme !== "green"),
                        "text-blue":
                          open && theme === "light" && theme !== "green",
                        "transition group rounded-md inline-flex items-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue": true,
                      })}
                    >
                      <span>{label}</span>
                      <ChevronDownIcon
                        className={classNames({
                          "text-white": theme !== "light" || theme === "green",
                          "text-black":
                            theme === "light" ||
                            (open && theme !== "light" && theme !== "green"),
                          "text-blue":
                            open && theme === "light" && theme !== "green",
                          "ml-2 h-5 w-5 group-hover:text-gray-offwhite hover:text-blue": true,
                        })}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel
                        onClick={() => useHover && onMouseHover(open)}
                        onMouseLeave={() => useHover && onMouseHover(open)}
                        ref={dropdownRef}
                        static
                        className={`toggle-menu absolute z-10 -ml-4 mt-10 transform px-2 w-screen ${menuWidth ||
                          "max-w-sm"} sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2`}
                      >
                        <div className="rounded-bl-xl rounded-br-xl shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div
                            className="relative grid gap-6 bg-white px-5 py-6 sm:gap-6 sm:p-6 text-left"
                            style={wideMenu}
                          >
                            {isBlog && allowTracking && (
                              <>
                                <a
                                  // eslint-disable-next-line react/no-array-index-key
                                  onClick={() => {
                                    openChat();
                                  }}
                                  className={`${itemMargin} p-2 flex items-start rounded-lg hover:bg-gray-menu text-black hover:text-blue cursor-pointer`}
                                  activeclass={classNames({
                                    "bg-gray-light gradient-blue text-white hover:text-purple": true,
                                  })}
                                >
                                  <Icon
                                    className="flex-shrink-0 h-6 w-6 text-blue hover:text-gray"
                                    aria-hidden="true"
                                    heroIcon="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                  />
                                  <div className="ml-4">
                                    <p className="text-base font-medium">
                                      Message Center
                                    </p>
                                    <p className="mt-1 text-xs">
                                      {"Get your latest messages from our team"}
                                    </p>
                                  </div>
                                </a>
                              </>
                            )}
                            {nav.map((item, i) => {
                              const uid = item.link?._meta?.uid;
                              setActive(uid === path);
                              return (
                                <PrismicLink
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={i}
                                  link={item.link}
                                  className={`${itemMargin} p-2 flex items-start rounded-lg hover:bg-gray-menu text-black hover:text-blue cursor-pointer`}
                                  activeclass={classNames({
                                    "bg-gray-light": true,
                                    "bg-gray-light gradient-blue text-white hover:text-purple": !isBlog,
                                    [item.link_text]: true,
                                  })}
                                >
                                  <Icon
                                    className="flex-shrink-0 h-6 w-6 text-blue hover:text-gray"
                                    aria-hidden="true"
                                    heroIcon={item.hero_icon}
                                  />
                                  <div className="ml-4">
                                    <p className="text-base font-medium">
                                      {item.link_text}
                                    </p>
                                    <p className="mt-1 text-xs">
                                      {item.link_description}
                                    </p>
                                  </div>
                                </PrismicLink>
                              );
                            })}
                            {isBlog && (
                              <PrismicLink
                                // eslint-disable-next-line react/no-array-index-key
                                route="/pricing"
                                className={`${itemMargin} p-2 flex items-start rounded-lg hover:bg-gray-menu text-black hover:text-blue cursor-pointer`}
                                activeclass={classNames({
                                  "bg-gray-light gradient-blue text-white hover:text-purple": true,
                                })}
                                // eslint-disable-next-line react/jsx-no-target-blank
                              >
                                <Icon
                                  className="flex-shrink-0 h-6 w-6 text-blue hover:text-gray"
                                  aria-hidden="true"
                                  iconName="IconGraph"
                                />
                                <div className="ml-4">
                                  <p className="text-base font-medium">
                                    Pricing
                                  </p>
                                  <p className="mt-1 text-xs">
                                    {"Learn how Lyra's pricing works"}
                                  </p>
                                </div>
                              </PrismicLink>
                            )}
                          </div>
                          {(!recentPosts || isBlog) && (
                            <div className="px-5 py-3 bg-gray-menu space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                              {cta?.map((item, i) => {
                                const link_lang = item.link;
                                return (
                                  <div key={i} className="flow-root">
                                    <PrismicLink
                                      // eslint-disable-next-line react/no-array-index-key
                                      link={link_lang}
                                      className="contact-sales cursor-pointer -m-3 p-3 flex items-center rounded-md text-sm font-medium text-gray hover:text-purple"
                                    >
                                      <span className="block text-blue w-5 h-5 hover:text-purple">
                                        <Icon
                                          className="flex-shrink-0 h-5 w-5 text-blue hover:text-purple"
                                          aria-hidden="true"
                                          heroIcon={item?.hero_icon}
                                          alt={item?.link_description}
                                        />
                                      </span>
                                      <span className="ml-1">
                                        {item?.link_text}
                                      </span>
                                    </PrismicLink>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {recentPosts && !isBlog && (
                            <div className="px-5 py-5 bg-gray-menu sm:px-8 sm:py-8">
                              <div>
                                <h3 className="text-sm tracking-wide font-medium text-gray-beta uppercase">
                                  Recent Posts
                                </h3>
                                <ul className="mt-4 space-y-4">
                                  {recentPosts.map((post, i) => (
                                    <li
                                      key={post.node.title}
                                      className="text-sm truncate"
                                    >
                                      <PrismicLink
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={i}
                                        route={`/blog/${post.node._meta.uid}`}
                                        className="recent-posts block transition hover:underline text-blue hover:text-base"
                                        activeclass={classNames({
                                          "text-black hover:text-purple underline-2": true,
                                          [`/blog/${post.node._meta.uid}`]: true,
                                        })}
                                      >
                                        {post.node.title}
                                      </PrismicLink>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="mt-5 text-sm">
                                <PrismicLink
                                  // eslint-disable-next-line react/no-array-index-key
                                  route="/blog"
                                  className="view-all-posts block transition hover:underline text-black hover:text-purple"
                                >
                                  {" "}
                                  View all posts{" "}
                                  <span aria-hidden="true">&rarr;</span>
                                </PrismicLink>
                              </div>
                            </div>
                          )}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
          </div>
        </div>
      </>
    </Popover>
  );
});
