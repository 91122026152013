/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { HeaderContext } from "@context";
import { HeroSimple, Container } from "@layout";
import { EopCta, MetaData } from "@common";
import { ShapesPatternRepeat } from "@svg";
import { PageTransition } from "@components";
import ValueCards from "../components/about/ValueCards";
import TeamSection from "../components/about/TeamSection";
import Careers from "../components/about/Careers";

const About = props => {
  // disable eslint because the content is so deep
  // eslint-disable-next-line
  const { about } = props.data.prismic;
  const { setSunrisePage, setBlogLayout } = useContext(HeaderContext);
  useEffect(() => {
    setSunrisePage(false);
    setBlogLayout(false);
  }, []);
  const showCareers = about?.careers[0]?.display_careers_section;
  const showTeam = about?.team_heading[0]?.show_team;
  return (
    <>
      <MetaData content={about} type="webpage" />
      <PageTransition>
        <article className="z-10 relative bg-white">
          <HeroSimple
            content={about.hero[0]}
            variant="dark"
            gradient="gray"
            centered
          />
          <section className="mt-24 relative pt-12 pb-12">
            <Container variant="sm">
              <ValueCards
                content={about.title_card[0]}
                cards={about.value_cards}
              />
            </Container>
            <div className="absolute inset-0 flex items-center justify-center">
              <ShapesPatternRepeat
                className="opacity-3 text-blue stroke-current h-full w-full"
                dimensions={[10, 10]}
              />
            </div>
          </section>
          {showTeam && (
            <section className="relative py-16">
              <Container>
                <TeamSection
                  content={about.team_heading[0]}
                  team={about.team}
                />
              </Container>
            </section>
          )}
          {showCareers === "Enabled" && (
            <section className="wave-form-t relative py-16 bg-blue-offwhite -mb-16">
              <Container>
                <Careers
                  content={about.careers[0]}
                  positions={about.positions}
                />
              </Container>
            </section>
          )}
          <section
            className={classNames({
              "bg-blue-offwhite": showCareers === "Enabled",
              "bg-white": showCareers !== "Enabled",
            })}
          >
            <Container variant="sm" className="pb-24">
              <EopCta content={about.cta_eop[0]} gradient="gray" />
            </Container>
            <div className="bg-transparent h-24 relative z-0" />
          </section>
        </article>
      </PageTransition>
    </>
  );
};

export default React.memo(About);

export const query = graphql`
  query AboutQuery {
    prismic {
      about(lang: "en-us", uid: "about") {
        title
        meta_title
        meta_image
        meta_description
        careers {
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          copy
          heading
          display_careers_section
          positions_heading
          icon
          subheading
        }
        cta_eop {
          tracking_event
          heading
          subheading
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
        }
        hero {
          heading
          subtitle
        }
        positions {
          position_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          position_title
        }
        team {
          team_member {
            ... on PRISMIC_People {
              full_name
              job_title
              photo
              description
              linkedin_url
            }
          }
        }
        team_heading {
          heading
          subheading
          show_team
        }
        title_card {
          caption
        }
        value_cards {
          caption
          heading
          icon
        }
      }
    }
  }
`;
