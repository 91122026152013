/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { trackEvent } from "@analytics";
import { open } from "@hubspot";
import { Icon, Button, RichText } from "@ui";
import { ExtendedEmailSignup } from "@contact";

const ContactSalesButtons = React.memo(
  ({
    content,
    form,
    uid,
    bleed,
    split,
    onboarding,
    volume_tooltip,
    locations_tooltip,
  }) => {
    const handleClick = () => {
      open("home");
      trackEvent("Clicked chat button", {
        button: content.chat_button_text,
      });
    };
    return (
      <div className="flex flex-wrap -mx-4">
        <div
          className={classNames({
            "sm:w-1/2": split,
            "sm:w-1/3": !split,
            "px-4 w-full mb-8": true,
          })}
        >
          <button
            onClick={handleClick}
            type="button"
            id="contact-sales-bot-button"
            className="contact-sales-bot-button rounded-lg block w-full transition gradient-blue shadow text-center p-12 h-full hover:shadow-lift hover:translate-y-lift"
          >
            <div className="flex justify-center">
              <Icon iconName="HubspotChat" />
            </div>
            <h2 className="text-white uppercase text-sm font-bold tracking-2 my-6">
              {content.chat_heading[0].text}
            </h2>
            <div>
              <span className="open_intercom bg-black py-3 rounded-3xl text-sm px-8 text-white font-bold hover:bg-blue hover:border-white border-3 border-transparent hover:opacity-100 cursor-pointer transition ease-out duration-333 translate-y-1 font-bold">
                {content.chat_button_text}
              </span>
            </div>
          </button>
        </div>
        <div
          className={classNames({
            "sm:w-1/2": split,
            "sm:w-2/3": !split,
            "px-4 w-full mb-8": true,
          })}
        >
          {form ? (
            <div className="bg-blue-offwhite shadow text-center h-full flex flex-col">
              <ExtendedEmailSignup
                className="my-0 text-black bg-blue-offwhite"
                sliceMarginClass="my-0"
                ySpacing="py-0"
                uid={uid}
                formId={form.primary.form_id}
                formUrl={form.primary.form_url}
                webhookUrl={form.primary.webhook_url}
                embedMethod={form.primary.form_embed_method}
                // icon={form.primary.icon}
                heading={form.primary.heading[0].text}
                subtitle={form.primary.subtitle}
                buttonText={form.primary.submit_button_text}
                buttonColor={form.primary.submit_button_color}
                orientation={form.primary.orientation}
                successMessage={form.primary.success_message}
                failureMessage={form.primary.failure_message}
                backgroundImageUrl={form.primary?.background_image?.url}
                listId={form.primary.list_id}
                trackingEvent={form.primary.tracking_event}
                promoCode={form.primary.promo_code}
                promoMemo={form.primary.promo_memo}
                fields={form.fields}
                promoReferral={uid}
                requirePhoneNumber={form.primary.require_phone_number}
                showPhoneNumber={form.primary.show_phone_number}
                collectVolume={form.primary.collect_volume}
                volumeTooltip={
                  content?.primary?.volume_tooltip || volume_tooltip
                }
                locationsTooltip={
                  content?.primary?.locations_tooltip || locations_tooltip
                }
                gradient="light"
                bleed={bleed}
                onboarding={onboarding}
                noShapes
              />
            </div>
          ) : (
            <div className="bg-blue-offwhite shadow text-center p-12 h-full flex flex-col">
              {!content.content && (
                <div className="flex justify-center">
                  <Icon iconName="IconPermit" />
                </div>
              )}
              <h2 className="text-black uppercase text-sm font-bold tracking-2 my-6">
                {content.link_heading[0].text}
              </h2>
              <div className="rich-text text-lg mb-8 md:pr-24 lg:pr-12 mb-4 lg:mb-10 antialiased">
                <RichText content={content.content} />
              </div>
              <div className="mt-auto">
                {content.link && (
                  <Button
                    bgClass="bg-blue"
                    textClass="text-white"
                    link={content.link}
                  >
                    {content.link_label}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

ContactSalesButtons.defaultProps = {
  bleed: true,
  split: true,
  onboarding: false,
  volume_tooltip: "Minimum annual commitment is 20/year on the Lyra platform",
  locations_tooltip:
    "Select the markets that your company operates in that Lyra currently supports",
};

ContactSalesButtons.propTypes = {
  bleed: PropTypes.bool,
  split: PropTypes.bool,
  onboarding: PropTypes.bool,
  volume_tooltip: PropTypes.string,
  locations_tooltip: PropTypes.string,
};

export default ContactSalesButtons;
