/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { Button } from "@ui";
import { HeaderContext } from "@context";

export default React.memo(({ content, color }) => {
  const { theme } = useContext(HeaderContext);
  const bgClass =
    theme === "green"
      ? "bg-white hover:bg-black"
      : color || "bg-black hover:bg-blue";
  const buttonTextClass =
    theme === "dark"
      ? "text-white hover:text-white"
      : "text-white hover:text-blue";

  if (
    (typeof content.button_text !== "undefined" &&
      content.button_text !== null) ||
    (typeof content.hero_button_text !== "undefined" &&
      content.hero_button_text !== null)
  ) {
    return (
      <Button
        link={content.button_link || content.hero_button_link}
        bgClass={bgClass}
        textClass={
          theme === "green" ? "text-green hover:text-white" : buttonTextClass
        }
        hoverState={["opacity-75"]}
        target={content.target}
        className="hover:border-white border-3 border-transparent hover:opacity-100 cursor-pointer transition ease-out duration-333 translate-y-1 font-bold"
      >
        {content.button_text || content.hero_button_text}
      </Button>
    );
  }
  return null;
});
