/* eslint-disable react/prop-types */
import React from "react";
import { useUID } from "react-uid";
import Seedrandom from "seedrandom";
import { Square, Circle, FourthCircle } from "./ShapesPattern/index";

const ShapesPatternHomepage = React.memo(({ className, dimensions, style }) => {
  const rn = new Seedrandom(9);
  const times = x => f => {
    if (x > 0) {
      f();
      times(x - 1)(f);
    }
  };
  const ShapeMatrix = countXY => {
    const matrix = [];
    let x = 0;
    let y = 0;

    times(countXY[1])(() => {
      times(countXY[0])(() => {
        matrix.push([x, y]);
        x += 1;
      });
      y += 1;
      x = 0;
    });
    return matrix;
  };
  const Shapes = ShapeMatrix(dimensions).map(value => {
    const uid = useUID();
    const x = value[0];
    const y = value[1];
    const key = `${x < 9 ? `0${x}` : x} + ${y < 9 ? `0${y}` : y}`;
    const randomValue = rn();
    if (x === dimensions[0] - 1 && rn() > 0.5) {
      return <g key={key + uid} />;
    }
    if (randomValue > 0.66) {
      return <FourthCircle key={key + uid} position={[x, y]} />;
    }
    if (randomValue <= 0.25) {
      return <Circle key={key + uid} position={[x, y]} />;
    }
    return <Square key={key + uid} position={[x, y]} />;
  });
  return (
    <svg
      // give the viewbox a little breathing room for the animation and strokes
      width={`${dimensions[0] * 40 + 20}px`}
      height={`${dimensions[1] * 40 + 20}px`}
      className={className}
      style={style}
      version="1.1"
    >
      <g
        className="repeating-shapes"
        vectorEffect="non-scaling-stroke"
        strokeLinejoin="miter"
        strokeWidth="0"
        fill="transparent"
        patternUnits="userSpaceOnUse"
      >
        {Shapes}
      </g>
    </svg>
  );
});

export default ShapesPatternHomepage;
