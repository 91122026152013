/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
// import axios from "axios";
// import parser from "tld-extract";

/* This is a small utility to manage hunts using hunter.io API
 * Hunter provides out of the box risky-ness, but we manage more
 * fine grained rules around was is risky or not, and, we need
 * to allow at the site level to quickly switch on and off risky
 * hunts, or byp[ass hunts due to api thresholds.
 */

let message = null;
let risky = false;
let updatedBypass = false;
let webmailRisky;
let disposableRisky = true;
let invalidRisky = true;
let unkownRisky = true;
let gibberishRisky = true;

// Override site settings for hunts - defaults are all true
if (
  process.env.GATSBY_HUNTER_ALLOW_WEBMAIL &&
  process.env.GATSBY_HUNTER_ALLOW_DISPOSABLE &&
  process.env.GATSBY_HUNTER_ALLOW_INVALID &&
  process.env.GATSBY_HUNTER_ALLOW_UNKOWN &&
  process.env.GATSBY_HUNTER_ALLOW_GIBBERISH
) {
  webmailRisky = process.env.GATSBY_HUNTER_ALLOW_WEBMAIL === "false";
  disposableRisky = process.env.GATSBY_HUNTER_ALLOW_DISPOSABLE === "false";
  invalidRisky = process.env.GATSBY_HUNTER_ALLOW_INVALID === "false";
  unkownRisky = process.env.GATSBY_HUNTER_ALLOW_UNKOWN === "false";
  gibberishRisky = process.env.GATSBY_HUNTER_ALLOW_GIBBERISH === "false";
}

const apiClientMode =
  process.env.GATSBY_HUNTER_MODE === "client" ||
  process.env.GATSBY_HUNTER_MODE === "full"; // 'client' or 'full' not 'server'
export const allowHunting =
  apiClientMode && process.env.GATSBY_HUNTER_ALLOW_HUNTING === "true";

/* Risky or not */
export const huntRisky = (allow, bypass, hunt) => {
  if (allow && allowHunting) {
    const validatedEmail = hunt?.data?.data;
    // const validatedMeta = hunt?.data?.meta?.params;
    const {
      webmail,
      gibberish,
      disposable,
      block,
      result,
      status,
      risky: risk,
      mx_records,
      smtp_check,
      smtp_server,
      score,
    } = validatedEmail;
    risky = risk;
    switch (result) {
      case "deliverable":
        risky = false;
        break;
      case "undeliverable":
        message = "Please try another address";
        risky = true;
        break;
      case "risky":
        message = "Please try another address";
        risky = true;
        break;
      default:
        break;
    }
    switch (status) {
      case "valid":
        message = null;
        risky = false;
        break;
      case "accept_all":
        message = null;
        risky = false;
        break;
      case "invalid":
        message = "Please try a valid address";
        updatedBypass =
          process.env.GATSBY_HUNTER_ALLOW_INVALID === "true" &&
          process.env.GATSBY_HUNTER_ALLOW_BYPASS === "true";
        risky = invalidRisky;
        break;
      case "webmail":
        message = bypass
          ? null
          : "Lyra prefers a business address. Please try another address";
        updatedBypass =
          process.env.GATSBY_HUNTER_ALLOW_WEBMAIL === "true" &&
          process.env.GATSBY_HUNTER_ALLOW_BYPASS === "true";
        risky = webmailRisky;
        break;
      case "disposable":
        message = "Please try a business address";
        updatedBypass =
          process.env.GATSBY_HUNTER_ALLOW_DISPOSABLE === "true" &&
          process.env.GATSBY_HUNTER_ALLOW_BYPASS === "true";
        risky = disposableRisky;
        break;
      case "unknown":
        message = "Is that the correct email address?";
        updatedBypass =
          process.env.GATSBY_HUNTER_ALLOW_UNKOWN === "true" &&
          process.env.GATSBY_HUNTER_ALLOW_BYPASS === "true";
        risky = unkownRisky;
        break;
      default:
        message = null;
        risky = false;
        break;
    }
    if (block) {
      message = "Please try another address";
      risky = true;
      updatedBypass = false;
    } else if (disposable) {
      message = "Please try a business address";
      updatedBypass =
        process.env.GATSBY_HUNTER_ALLOW_DISPOSABLE === "true" &&
        process.env.GATSBY_HUNTER_ALLOW_BYPASS === "true";
      risky = disposableRisky;
    } else if (webmail || status.webmail) {
      message = bypass
        ? null
        : "Lyra prefers a business address. Please try another address";
      updatedBypass =
        process.env.GATSBY_HUNTER_ALLOW_WEBMAIL === "true" &&
        process.env.GATSBY_HUNTER_ALLOW_BYPASS === "true";
      risky = webmailRisky;
    } else if (
      (gibberish && !webmail) ||
      score < 51 ||
      status.invalid ||
      result.undeliverable
    ) {
      message =
        "Are you sure this is a valid address? Please try another address";
      updatedBypass =
        process.env.GATSBY_HUNTER_ALLOW_GIBBERISH === "true" &&
        process.env.GATSBY_HUNTER_ALLOW_BYPASS === "true";
      risky = gibberishRisky;
    }
    const output = {
      message: message ? `<span role="img">⚠️</span> ${message}` : null,
      risky,
      risk: risk || result === "undeliverable",
      webmail,
      gibberish,
      disposable,
      block,
      result,
      status,
      bypass: updatedBypass,
      // meta: validatedMeta,
      // data: validatedEmail,
      mx: mx_records,
      smtp: smtp_check,
      smtpServer: smtp_server,
      score,
    };
    // console.log("Email check: ", output);
    return output;
  }
  console.log("This is an unvalidated email...");
  return {
    message: null,
    risky: false,
    bypass,
    status: "unknown",
  };
};

export const huntErrors = errors => {
  const { code, id, details } = errors && errors.length >= 1 && errors[0];
  message = details;
  switch (code) {
    case 429:
      message = null;
      risky = false;
      break;
    case 400:
      message = null;
      risky = false;
      break;
    case 401:
      message = null;
      risky = false;
      break;
    case 422:
      message = null;
      risky = false;
      break;
    default:
      message = null;
      risky = false;
      break;
  }
  return {
    error: id,
    code,
    message,
    risky,
  };
};

export const huntAccount = payload => {
  const account = payload?.data?.data;
  return {
    available: account?.calls?.available,
    used: account?.calls?.used,
    verifications: account?.requests?.verifications?.available,
    searches: account?.requests?.searches?.available,
    reset_date: account?.reset_date,
    allow:
      account?.requests?.searches?.available >= 1 &&
      account?.requests?.verifications?.available >= 1,
    team: account?.team_id,
  };
};
