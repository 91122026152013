/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import { VideoPlayer } from "@ui";

export default React.memo(({ media }) => {
  if (media != null) {
    if (!media.embed_animation && media.embed_code !== null) {
      return (
        <div
          className={classNames({ "h-full": true })}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: media.embed_code[0].text }}
        />
      );
    }
    if (media.media_url !== null) {
      return <VideoPlayer url={media.media_url} />;
    }
  }
  return null;
});
