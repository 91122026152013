/* eslint-disable react/prop-types */
import React from "react";
import { useUID } from "react-uid";
import { Icon, RichText, ArrowLink } from "@ui";

export default ({ content, positions }) => {
  return (
    <div className="flex items-center flex-wrap -mx-6">
      <div className="w-full sm:w-3/5 px-6 mb-8 sm:md-0 lg:pr-12">
        <div className="mb-8">
          <Icon className="w-20 h-20" iconName={content.icon} />
        </div>
        <h3 className="text-sm font-bold my-4 uppercase tracking-1">
          {content.subheading[0].text}
        </h3>
        <h2 className="text-2xl sm:text-3xl my-4 font-bold">
          {content.heading[0].text}
        </h2>
        <div className="rich-text leading-normal my-6">
          <RichText content={content.copy} />
        </div>
        {content.arrow_link_text && (
          <div>
            <ArrowLink textClass="text-blue" link={content.arrow_link}>
              {content.arrow_link_text}
            </ArrowLink>
          </div>
        )}
      </div>
      <div className="w-full sm:w-2/5 px-6 lg:pl-12">
        <h3 className="text-sm font-bold my-4 uppercase mb-8 tracking-1">
          {content.positions_heading[0].text}
        </h3>
        <div>
          {positions.map(position => {
            const uid = useUID();
            return (
              <div className="my-6">
                <ArrowLink
                  key={uid}
                  textClass="text-blue"
                  link={position.position_link}
                >
                  {position.position_title}
                </ArrowLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
