/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useUID } from "react-uid";
import { Icon, RichText } from "@ui";
import TailwindConfig from "@tailwindConfig";

export default React.memo(({ content, cards }) => {
  const [minHeight, setMinHeight] = useState(0);
  if (typeof window !== "undefined") {
    useEffect(() => {
      const equalizeCards = () => {
        const cardWidth = document.getElementById("title_card").clientWidth;
        setMinHeight(
          window.innerWidth >= TailwindConfig.theme.screens.sm ? cardWidth : 0
        );
      };
      window.addEventListener("resize", equalizeCards);
      equalizeCards();
      return () => {
        window.removeEventListener("resize", equalizeCards);
      };
    }, []);
  }
  return (
    <div className="flex flex-wrap -mx-2 sm:-mx-4">
      <div className="w-full px-2 sm:px-4 sm:w-1/2 mb-4 sm:mb-8 xl:px-8 xl:mb-12">
        <div
          id="title_card"
          className="rounded-2xl bg-black h-full px-4 py-8 sm:p-8 xl:p-12 shadow flex flex-col justify-center"
        >
          <Icon
            iconName="Logo"
            className="logo-inverse w-32 md:w-48 md:px-4 mx-auto h-auto mb-8 md:mb-12"
          />
          <h2 className="text-white text-xl sm:text-3xl text-center font-bold">
            {content.caption[0].text}
          </h2>
        </div>
      </div>
      {cards.map(card => {
        const uid = useUID();
        return (
          <div
            key={uid}
            className="w-full px-2 sm:px-4 xl:px-8 sm:w-1/2 mb-4 sm:mb-8 xl:mb-12"
          >
            <div
              className="rounded-2xl bg-white shadow h-full border-b-4 border-blue p-6 sm:p-8 xl:p-16 md:p-10"
              style={{ minHeight: `${minHeight}px` }}
            >
              <Icon
                iconName={card.icon}
                className="text-center text-center mx-auto w-12 h-12 sm:w-16 sm:h-16 flex justify-center my-6"
              />
              <h3 className="text-xl sm:text-2xl text-center mb-6 sm:text-2-5xl font-bold leading-tight">
                {card.heading[0].text}
              </h3>
              <div className="text-sm">
                <RichText content={card.caption} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});
