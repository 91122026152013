/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { RichText as PrismicRichText } from "prismic-reactjs";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import classNames from "classnames";
import {
  canUseDOM,
  analyticsActive,
  identifyUser,
  trackEvent,
} from "@analytics";
import { updateMessenger, getVisitorId, intercomEnabled } from "@intercom";
import { HeaderContext } from "@context";
import { Button, Icon, RichText, Image } from "@ui";
import { Container } from "@layout";
import { RandomShapes } from "@decoration";
import { ShapesPatternRepeat } from "@svg";

const time_signature = new Date().getTime();
function encode(data) {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

const ExtendedFormSignup = React.memo(
  ({
    icon,
    heading,
    subtitle,
    listId,
    buttonText,
    buttonColor,
    successMessage,
    failureMessage,
    backgroundImageUrl,
    circleTop,
    noShapes,
    trackingEvent,
    promoCode,
    promoMemo,
    promoReferral,
    upload,
    // embed,
    formId,
    formCollector,
    recaptcha,
    // jotForm,
    // jotFormId,
    fields,
  }) => {
    // eslint-disable-next-line no-unused-vars
    const recaptchaRef = React.createRef();
    const [loading, setLoading] = useState(false);
    const [attachment, setAttachment] = useState({});
    const [wasSuccessful, setWasSuccessful] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [formData, setFormData] = useState({
      email: null,
      list_id: listId,
      form_id: formId,
      promo_code: promoCode,
      promo_memo: promoMemo,
      promo_referral: promoReferral,
    });
    const { setNotifications, disabledClientTracking } = useContext(
      HeaderContext
    );
    // const clearForm = () => {
    //   setFormData({ email: "", firstName: "", lastName: "", company: "" });
    // };
    const [intercomId, setIntercomId] = useState(getVisitorId());
    const [recaptchaValue, setRecaptchaValue] = useState(
      recaptchaRef && recaptchaRef.current && recaptchaRef.current.getValue()
    );
    const [intercomLeadDetails, setIntercomLeadDetails] = useState({
      user_id: intercomId,
      email: formData?.email,
      name: `${formData?.firstName} ${formData.lastName}`,
      company: formData?.company,
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      source: trackingEvent || "Website signup",
      promo_code: formData?.promo_code,
      promo_memo: formData?.promo_memo,
      promo_referral: formData?.promo_referral,
      "Referral URL": `https://lyrasolar.com/${formData?.promo_referral}`,
      "Company name": formData?.company,
      stayInTouch: true,
      website_signup: true,
      unsubscribed_from_emails: false,
      utm_source: trackingEvent || "Website signup",
      utm_campaign: formData?.promo_referral,
      time_signature,
    });
    const intercomActive =
      intercomEnabled &&
      (canUseDOM &&
        window &&
        !!window.intercomSettings &&
        !!window.intercomSettings.app_id);
    const serverSideTracking =
      !intercomActive || (!analyticsActive && disabledClientTracking);
    const recaptchaKey =
      process.env.GATSBY_ANALYTICS_ENV === "development"
        ? process.env.GATSBY_DEV_RECAPTCHA_KEY
        : process.env.GATSBY_RECAPTCHA_KEY;

    const handleChange = (field, value) => {
      setFormData({
        ...formData,
        [field]: value,
      });
      setIntercomId(getVisitorId());
      setIntercomLeadDetails({
        ...intercomLeadDetails,
        [field]: value,
        name: `${formData?.firstName} ${formData.lastName}`,
        "Company name": formData?.company,
        user_id: intercomId,
      });
      setHasError(false);
    };

    const handleSelectChange = e => {
      setFormData({
        ...formData,
        options: e,
      });
    };

    const handleRecaptchaChange = token => {
      setRecaptchaValue(token);
    };

    const handleAttachment = e => {
      if (e && e.target && e.target.files[0]) {
        setAttachment({
          [e.target.name]: e.target.files[0],
        });
      }
      setFormData({
        ...formData,
        attachment: {
          [e.target.name]: e.target.files[0] || attachment,
        },
      });
    };

    const handleSuccess = () => {
      // console.log("Success!");
      setLoading(false);
      setWasSuccessful(true);
      setNotifications(prevState => ({
        ...prevState,
        [`notification-${time_signature}`]: {
          type: "black",
          content: PrismicRichText.asText(successMessage),
        },
      }));
      if (intercomId) {
        identifyUser(intercomId, {
          user_id: intercomId,
          ...intercomLeadDetails,
        })
          .then(() => {
            updateMessenger({
              ...intercomLeadDetails,
              user_id: intercomId,
              name: `${intercomLeadDetails?.firstName} ${intercomLeadDetails.lastName}`,
              time_signature,
            });
          })
          .then(() => {
            trackEvent(intercomLeadDetails.source, {
              ...intercomLeadDetails,
              user_id: intercomId,
              name: `${intercomLeadDetails?.firstName} ${intercomLeadDetails.lastName}`,
              time_signature,
            });
          });
      } else {
        identifyUser({
          ...intercomLeadDetails,
          user_id: intercomId,
          name: `${intercomLeadDetails?.firstName} ${intercomLeadDetails.lastName}`,
          time_signature,
        })
          .then(() => {
            updateMessenger({
              ...intercomLeadDetails,
              user_id: intercomId,
              name: `${intercomLeadDetails?.firstName} ${intercomLeadDetails.lastName}`,
              time_signature,
            });
          })
          .then(() => {
            trackEvent(intercomLeadDetails.source, {
              ...intercomLeadDetails,
              user_id: intercomId,
              name: `${intercomLeadDetails?.firstName} ${intercomLeadDetails.lastName}`,
              time_signature,
            });
          });
      }
    };

    const handleError = () => {
      setLoading(false);
      setHasError(true);
      setNotifications(prevState => ({
        ...prevState,
        [`notification-${time_signature}`]: {
          type: "error",
          content: PrismicRichText.asText(failureMessage),
        },
      }));
    };

    const handleSubmit = async event => {
      event.preventDefault();
      setLoading(true);
      setIntercomId(getVisitorId());
      setIntercomLeadDetails({
        ...intercomLeadDetails,
        name: `${formData?.firstName} ${formData.lastName}`,
        "Company name": formData?.company,
        user_id: intercomId,
      });
      if (process.env.GATSBY_NETLIFY_MODE === "on" && !serverSideTracking) {
        const formSubmitData = upload
          ? new FormData({
              "form-name": formId,
              formData,
              intercomLeadDetails,
            })
          : encode({
              "form-name": formId,
              formData,
              intercomLeadDetails,
            });
        axios
          .post("/", {
            method: "POST",
            headers: !upload && {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            // body: new URLSearchParams(intercomLeadDetails).toString(),
            body: formSubmitData,
          })
          .then(handleSuccess)
          .catch(handleError);
      } else {
        handleSuccess();
      }
    };

    // VisitorID
    // console.log(getVisitorId());
    // console.log("intercomLeadDetails", intercomLeadDetails);
    // console.log("formData", formData);
    // console.log("attachment", attachment);
    // console.log("fields", fields);
    // console.log("selectedOption", selectedOption);

    return (
      <div className="py-px relative">
        {!noShapes && (
          <>
            <div className="absolute inset-0 bg-white" />
            <div className="absolute inset-0 flex items-center justify-center">
              <ShapesPatternRepeat
                className="opacity-3 text-blue stroke-current h-full w-full"
                dimensions={[10, 10]}
              />
            </div>
          </>
        )}
        <div className="relative flex w-full justify-center items-center order-last">
          <div className="absolute w-3/7 mt-12 translate-x-1/4 right-0 top-0 mt-12 flex opacity-25">
            <Image src={backgroundImageUrl} transparent />
          </div>
          {circleTop && (
            <div
              className="bg-blue-offwhite absolute -mt-48 inset-0 z-0"
              style={{
                borderRadius: "100%",
                width: "125%",
                height: "200px",
                top: "100px",
                left: "-5%",
              }}
            />
          )}
          <Container variant="heading" className="my-24">
            <div className="bg-white shadow rounded-br-3xl">
              <div className="grid grid-cols-3 gap-0">
                <div
                  className={`col-span-3 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 p-4 md:px-4 ${buttonColor ||
                    "bg-blue"} text-white`}
                >
                  <div className="align-middle my-auto">
                    {icon && (
                      <div className="flex justify-center mb-6">
                        <Icon
                          className="w-16 h-16 text-white my-8"
                          iconName={icon}
                        />
                      </div>
                    )}
                    <h2 className="text-2xl md:text-2-5xl font-bold text-center my-8">
                      {heading}
                    </h2>
                    <div className="rich-text text-center text-lg md:text-xl my-8">
                      <RichText content={subtitle} />
                    </div>
                  </div>
                </div>
                <form
                  name={formId}
                  data-netlify={formCollector}
                  data-netlify-recaptcha={recaptcha}
                  data-netlify-honeypot="bot-field"
                  encType={
                    upload
                      ? "multipart-form/data"
                      : "application/x-www-form-urlencoded"
                  }
                  onSubmit={handleSubmit}
                  className={classNames({
                    "opacity-25": loading || wasSuccessful,
                    "col-span-3 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 p-6 sm:p-12 relative transition": true,
                  })}
                >
                  <input type="hidden" name="form-name" value={formId} />
                  <input
                    type="hidden"
                    name="bot-field"
                    onChange={event => {
                      handleChange("bot-field", event.target.value);
                    }}
                  />
                  <div>
                    {/* email */}
                    <div className="flex flex-wrap sm:flex-no-wrap items-center my-4">
                      <div className="w-full sm:w-1/3 md:w-1/4">
                        <label
                          htmlFor="mailchimpEmail"
                          className="text-sm font-bold text-blue uppercase tracking-2"
                        >
                          Email
                        </label>
                      </div>
                      <div className="flex-grow">
                        <input
                          className="bg-white rounded-none border-blue border-l-4 p-3 w-full shadow focus:border-teal transition"
                          type="email"
                          id="mailchimpEmail"
                          name="mailchimpEmail"
                          disabled={loading}
                          required
                          onChange={event => {
                            handleChange("email", event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* first name */}
                    <div className="flex flex-wrap sm:flex-no-wrap items-center my-4">
                      <div className="w-full sm:w-1/3 md:w-1/4">
                        <label
                          htmlFor="mailchimpFirstName"
                          className="text-sm font-bold text-blue uppercase tracking-2"
                        >
                          First Name
                        </label>
                      </div>
                      <div className="flex-grow">
                        <input
                          className="bg-white rounded-none border-blue border-l-4 p-3 w-full shadow focus:border-teal transition"
                          type="text"
                          id="mailchimpFirstName"
                          name="mailchimpFirstName"
                          disabled={loading}
                          required
                          onChange={event => {
                            handleChange("firstName", event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* last name */}
                    <div className="flex flex-wrap sm:flex-no-wrap items-center my-4">
                      <div className="w-full sm:w-1/3 md:w-1/4">
                        <label
                          htmlFor="mailchimpLastName"
                          className="text-sm font-bold text-blue uppercase tracking-2"
                        >
                          Last Name
                        </label>
                      </div>
                      <div className="flex-grow">
                        <input
                          className="bg-white rounded-none border-blue border-l-4 p-3 w-full shadow focus:border-teal transition"
                          type="text"
                          id="mailchimpLastName"
                          name="mailchimpLastName"
                          disabled={loading}
                          required
                          onChange={event => {
                            handleChange("lastName", event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* company */}
                    <div className="flex flex-wrap sm:flex-no-wrap items-center my-4">
                      <div className="w-full sm:w-1/3 md:w-1/4">
                        <label
                          htmlFor="mailchimpCompany"
                          className="text-sm font-bold text-blue uppercase tracking-2"
                        >
                          Company
                        </label>
                      </div>
                      <div className="flex-grow">
                        <input
                          className="bg-white rounded-none border-blue border-l-4 p-3 w-full shadow focus:border-teal transition"
                          type="text"
                          id="mailchimpCompany"
                          name="mailchimpCompany"
                          disabled={loading}
                          onChange={event => {
                            handleChange("company", event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* select */}
                    {fields && (
                      <div className="flex flex-wrap sm:flex-no-wrap items-center my-4">
                        <div className="w-full sm:w-1/3 md:w-1/4">
                          <label
                            htmlFor="mailchimpEmail"
                            className="text-sm font-bold text-blue uppercase tracking-2"
                          >
                            Select
                          </label>
                        </div>
                        <div className="flex-grow">
                          <Select
                            className="form-multiselect block w-full mt-1 bg-white rounded-none border-blue border-l-4 p-3 w-full shadow focus:border-teal transition"
                            id="mailchimpOption"
                            name="mailchimpOption"
                            isMulti
                            value={formData.options}
                            onChange={handleSelectChange}
                            options={fields}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {upload && (
                    <div className="flex-wrap sm:flex-no-wrap items-center my-4">
                      <div className="justify-center mt-8">
                        <div className="rounded-lg">
                          <div>
                            <label className="inline-block text-sm font-bold text-blue uppercase tracking-2">
                              Upload Specs or Data Sheets
                              <span className="text-xs text-black sm:block -mt-1 tracking-0 font-normal opacity-50 normal-case">
                                optional
                              </span>
                            </label>
                            <div className="flex items-center justify-center w-full">
                              <label className="flex flex-col w-full h-32 border-3 border-dashed border-blue hover:border-black p-6">
                                <div className="flex flex-col items-center justify-center pt-7">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-12 h-12 text-blue group-hover:text-black hover:text-black"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <p className="pt-1 text-sm tracking-wider text-blue group-hover:text-black hover:text-black">
                                    Select a file
                                  </p>
                                </div>
                                <input
                                  name="attachment"
                                  type="file"
                                  className="opacity-0 transition"
                                  onChange={event => {
                                    handleAttachment(event);
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {recaptcha && (
                    <div className="flex items-center justify-center">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={recaptchaKey}
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                  )}
                  <div className="text-center mt-8">
                    <Button
                      type="submit"
                      className="text-sm disabled:bg-blue disabled:opacity-50 disabled:cursor-default"
                      textClass="text-white"
                      bgClass={`${buttonColor} bg-black`}
                      disabled={
                        loading ||
                        hasError ||
                        wasSuccessful ||
                        !recaptchaValue ||
                        !formData.email
                      }
                      button
                      onClick={() => {
                        trackEvent("Website signup click", {
                          ...formData,
                        });
                      }}
                    >
                      {buttonText || "Sign Up"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <RandomShapes opacity="0.5" seed="asdsad" />
          </Container>
        </div>
      </div>
    );
  }
);

ExtendedFormSignup.defaultProps = {
  buttonColor: "bg-black",
};

ExtendedFormSignup.propTypes = {
  buttonColor: PropTypes.string,
};

export default ExtendedFormSignup;
