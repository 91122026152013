import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { RichText } from "@ui";

import { Container } from "@layout";

const PullQuoteBlock = ({ content, darkTheme, variant }) => {
  return (
    <Container variant={variant}>
      <aside>
        <blockquote className="bg-blue-offwhite rounded-xl transition hover:translate-y-lift shadow hover:shadow-lift p-6 md:py-12 md:px-16 font-normal">
          <div className="italic text-blue text-2-5xl">
            <RichText content={content.primary.quote} />
          </div>
          <cite
            className={classNames({
              hidden: content.primary.citation == null,
              "mt-6": true,
              "text-black": darkTheme,
            })}
          >
            <RichText content={content.primary.citation} />
          </cite>
        </blockquote>
      </aside>
    </Container>
  );
};

PullQuoteBlock.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  darkTheme: PropTypes.bool,
  variant: PropTypes.string,
};

PullQuoteBlock.defaultProps = {
  darkTheme: false,
  variant: "copy",
};

export default PullQuoteBlock;
