/* eslint-disable react/prop-types */
import React from "react";
import { RichText } from "@ui";

export default React.memo(({ content }) => {
  if (content.hero_subheading != null || content.hero_copy != null) {
    return (
      <div className="rich-text text-lg lg:text-xl mb-8 lg:pr-12 mb-4 lg:mb-10 antialiased">
        <RichText content={content.hero_subheading || content.hero_copy} />
      </div>
    );
  }
  return null;
});
