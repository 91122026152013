/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef, useContext, useEffect } from "react";
import classNames from "classnames";
import { Icon, Button } from "@ui";
import { PrismicLink } from "@utilities";
import { HeaderContext } from "@context";
import Menu from "./Popover";
import MobileMenu from "./MobileMenu";

export default React.memo(
  ({
    mobile_cta_link,
    mobile_cta_link_text,
    mobile_nav,
    mobile_nav_label,
    topAnchor,
  }) => {
    const { theme, navOpen, setNavOpen, isOnboarding } = useContext(
      HeaderContext
    );
    const AppMobileNav = useRef();

    /*
    if (hasWindow) {
      // nav open effects
      useEffect(() => {
        const navItems = Array.from(AppMobileNav.current.childNodes.values());
        if (navOpen) {
          tl.set(AppMobileNav.current, {
            // top: `${topAnchor}px`,
            opacity: "1",
          }).kill(AppMobileNav.current);
          navItems.map((node, index) => {
            tl.to(node, 0.5, { opacity: 1 }, index === 0 ? `.333` : `-=.4`);
            return true;
          });
        }
        if (!navOpen) {
          tl.delay(0.333)
            .to(AppMobileNav.current, 0.333, {
              opacity: "0",
            })
            .invalidate();
          navItems.map(node => {
            tl.set(node, { opacity: 0 }, 0.333).kill(node);
            return true;
          });
        }
      }, [navOpen]);

      // nav theme effects
      useEffect(() => {
        const appMobileNav = document.getElementById("AppMobileNav");
        const closeButton = document.getElementById("CloseButton");
        const lyraIcon = document.getElementById("LyraIcon");
        if (theme === "light") {
          tl.to(
            appMobileNav,
            0.222,
            {
              background:
                // eslint-disable-next-line no-nested-ternary
                theme === "green"
                  ? TailwindConfig.theme.extend.colors.green
                  : theme === "light"
                  ? TailwindConfig.theme.extend.colors.white
                  : "linear-gradient(130deg, #133095, #2D80EF)",
              boxShadow: TailwindConfig.theme.extend.boxShadow.default,
              "-webkit-mask":
                "radial-gradient(var(--R) at 50% calc(1.8*var(--size)),#000 99%,#0000 101%) calc(50% - 2*var(--size)) 0/calc(4*var(--size)) 100%, radial-gradient(var(--R) at 50% calc(-.8*var(--size)),#0000 99%,#000 101%) 50% var(--size)/calc(4*var(--size)) 100% repeat-x;",
            },
            "-=.666"
          );
          tl.to(closeButton, 0.222, {
            color: TailwindConfig.theme.extend.colors.white,
          });
          tl.to(lyraIcon, 0.222, {
            color: TailwindConfig.theme.extend.colors.white,
          });
        } else {
          tl.to(
            appMobileNav,
            0.222,
            {
              backgroundColor: TailwindConfig.theme.extend.colors.white,
            },
            "-=.666"
          );
          tl.to(closeButton, 0.222, {
            color: TailwindConfig.theme.extend.colors.black,
          });
          tl.to(lyraIcon, 0.222, {
            color: TailwindConfig.theme.extend.colors.blue.default,
          });
        }
        const navLinks = Array.from(
          document.getElementsByClassName("mobile-link")
        );
        // eslint-disable-next-line array-callback-return
        navLinks.map(node => {
          if (theme === "light") {
            tl.to(node, 0.222, {
              color: TailwindConfig.theme.extend.colors.white,
            });
          } else {
            tl.to(node, 0.222, {
              color: TailwindConfig.theme.extend.colors.black,
            });
          }
        });
      }, ["", theme]);
    }
    */

    return (
      <nav
        id="AppMobileNav"
        ref={AppMobileNav}
        className={classNames({
          shadow: navOpen,
          "bg-white flex md:hidden absolute -translate-y-full top-0 left-0 right-0 flex-col font-semibold text-center w-full justify-center items-center z-40 py-12 rounded-br-heroxs": true,
        })}
        style={{ top: `${navOpen ? topAnchor : 0}px` }}
      >
        <PrismicLink
          route={theme === "green" ? "/?sunrise=lyra" : "/"}
          className={classNames({
            "cursor-pointer": true,
          })}
          activeStyle={{ cursor: "default" }}
        >
          <Icon
            id="LyraIcon"
            iconName="LyraIcon"
            className="fill-current stroke-current w-8 h-8 mb-4 text-blue hover:text-black"
          />
        </PrismicLink>
        <div className="px-4 pt-2">
          <div
            className={classNames({
              "text-black bg-white w-full max-w-md relative grid gap-6 py-6 sm:gap-6 sm:p-6 text-left": true,
            })}
          >
            {mobile_nav && (
              <MobileMenu label={mobile_nav_label} nav={mobile_nav} />
            )}
          </div>
        </div>
        {!isOnboarding && (
          <div>
            <Button
              bgClass="bg-black"
              textClass="text-white"
              className={classNames({
                "mt-4 sm:mt-0 sm:text-white sm:text-white border-3 border-transparent hover:opacity-100 cursor-pointer transition ease-out duration-333 translate-y-1 font-bold": true,
                "hover:bg-blue hover:border-white": theme !== "light",
                "hover:bg-white hover:border-blue hover:text-blue selection:bg-white selection:border-blue selection:text-blue":
                  theme === "light",
                [mobile_cta_link]: true,
              })}
              link={mobile_cta_link}
            >
              {mobile_cta_link_text}
            </Button>
          </div>
        )}
        <button
          type="button"
          className="open-nav block h-8 w-8 absolute top-0 right-0 mt-12 mr-8 cursor-pointer md:hidden"
          onClick={() => {
            setNavOpen(false);
          }}
        >
          <Icon
            id="CloseButton"
            iconName="Close"
            className={classNames({
              "stroke-current fill-current w-full h-full text-black hover:text-blue": true,
            })}
          />
        </button>
      </nav>
    );
  }
);
