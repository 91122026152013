/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container } from "@layout";
import Signup from "./Hero/Signup";
import Subheading from "./Hero/Subheading";
import HeroButton from "./Hero/HeroButton";
import HeroShapes from "./Hero/HeroShapes";

const Hero = ({
  content,
  variant,
  centered,
  size,
  signup,
  gradient,
  title,
  topPadding,
  yPadding,
  xPadding,
  heroClass,
}) => {
  const gradientColor = gradient ? `gradient-${gradient}` : null;
  if (content.hero_heading != null) {
    return (
      <section
        className={classNames(`${gradientColor} ${topPadding} ${heroClass}`, {
          "hero-small hero-sm": size === "xs",
          "bg-green text-white": variant === "green",
          "bg-white": variant === "light",
          "bg-blue text-white": variant !== "green" && variant !== "light",
          "min-h-hero-mobile sm:min-h-hero-sm pb-3 flex flex-col justify-center items-center relative mt-10 md:mt-0": true,
        })}
      >
        <HeroShapes variant={variant} />
        <Container size={size}>
          <div className="flex flex-wrap items-center">
            <div
              className={`${yPadding} ${xPadding} xxs:px-0 sm:px-10 sm:p-0 order-2 sm:order-1 z-10 relative ${
                centered ? "text-center mx-auto" : ""
              }`}
            >
              <h1
                className={classNames({
                  "text-black": variant === "light",
                  "text-white": variant === "green" || variant !== "light",
                  "leading-tight font-bold antialiased text-3xl md:text-4xl mb-6 lg:mb-10": true,
                })}
              >
                {title || content.hero_heading[0].text}
              </h1>
              <Subheading content={content} />
              <div>
                <HeroButton content={content} />
              </div>
              <div className="max-w-md">
                <Signup signup={signup} />
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
  return (
    <section
      className={classNames({
        "bg-green text-white": variant === "green",
        "bg-white": variant === "light",
        "bg-blue text-white": variant === "green" && variant !== "light",
        "hero--page absolute inset-0 z-0 min-h-hero-mobile sm:min-h-hero-sm pt-32 pb-6 flex flex-col justify-center items-center": true,
      })}
    >
      <HeroShapes variant={variant} />
    </section>
  );
};

Hero.defaultProps = {
  size: "md",
  variant: "light",
  signup: {},
  title: null,
  yPadding: "py-6",
  xPadding: "xxs:w-full w-6/7 sm:w-5/7 lg:pl-0 md:w-2/3 xl:w-1/2",
  topPadding: "pt-32",
  heroClass: "hero--page",
};

Hero.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  signup: PropTypes.object,
  title: PropTypes.string,
  xPadding: PropTypes.string,
  yPadding: PropTypes.string,
  topPadding: PropTypes.string,
  heroClass: PropTypes.string,
};

export default Hero;
