/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable valid-typeof */
import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Widget } from "@typeform/embed-react";
import { InlineWidget } from "react-calendly";
import Select from "react-select";
import { HeaderContext } from "@context";
import { Container } from "@layout";
import { Button, Icon, Image, RichText } from "@ui";
import { PrismicLink } from "@utilities";
import { RandomShapes } from "@decoration";
import { Hubspot } from "@ux";

const EmbedUrl = React.memo(props => {
  const { hubspot, embed_form, hasWindow } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const embedForm = embed_form?.embed_url_or_code;
  const embedSelection = embed_form?.add_embed_selection;
  const embedFormCode = embed_form?.embed_code;
  const embedFormCode2 = embed_form?.embed_code_2;
  const embedTypeform = embed_form?.typeform;
  const embedHubspot = embed_form?.hubspot;
  const embedCalendly = embed_form?.calendly;
  const applyBlueFormBg = embed_form?.apply_blue_bg;
  const {
    locked_page,
    require_uid_or_message_id,
    utm_campaign,
    utm_term,
    utm_content,
    utm_source,
    heading,
    text,
    link,
    link_text,
    height,
  } = embed_form;
  const isLockedPage = locked_page;
  const ctaLink = link || {
    __typename: "PRISMIC_Pages",
    _linkType: "Link.document",
    meta_title: "Get Started with Lyra",
    path: null,
    title: "Get Started with Lyra",
    _meta: {
      uid: "get-started",
    },
  };

  // See if we're passing embed details and if so, pass them to the emebd url
  const { currentLocation, isMobile } = useContext(HeaderContext);
  const location = currentLocation;
  const urlParams = new URLSearchParams(location.search);
  // const hasEmbedUrlParams = new URLSearchParams(embedFormCode);
  // const hasEnriched = hasEmbedUrlParams.get("source");
  // const isEnriched = hasEnriched === "enriched";
  const hasParams = !!urlParams;
  /* const isDemoPage =
    location.pathname === "/schedule-a-demo" ||
    location.pathname === "/solutions/schedule-a-demo" ||
    location.pathname === "/features/schedule-a-demo";
  */
  const isEmbeddedDemoPage =
    location.pathname === "/solutions/try-before-you-fly" ||
    location.pathname === "/features/try-before-you-fly" ||
    location.pathname === "/try-before-you-fly";
  const requiresLocking = isLockedPage || isEmbeddedDemoPage;
  const isActivated =
    ((isLockedPage || isEmbeddedDemoPage) &&
      hasParams &&
      (urlParams.get("utm_campaign") === utm_campaign ||
        urlParams.get("utm_campaign") === "lyra-web" ||
        urlParams.get("utm_campaign") === "QualifierReEngagement") &&
      (urlParams.get("utm_term") === utm_term ||
        urlParams.get("utm_term") === "demo") &&
      (urlParams.get("utm_content") === utm_content ||
        urlParams.get("utm_content") === "schedule-a-demo" ||
        urlParams.get("utm_content") === "try-before-you-fly" ||
        urlParams.get("utm_content") === "verify-demo-request") &&
      (urlParams.get("utm_source") === utm_source ||
        urlParams.get("utm_source") === "gamma")) ||
    (urlParams.get("utm_source") === "intercom" &&
      (require_uid_or_message_id &&
        (urlParams.get("messages") || urlParams.get("uid")))) ||
    (urlParams.get("utm_source") === "hubspot" &&
      (require_uid_or_message_id &&
        (urlParams.get("messages") || urlParams.get("uid"))));
  // eslint-disable-next-line no-nested-ternary
  const _utms = `?utm_campaign=${utm_campaign}&utm_medium=${urlParams.get(
    "utm_medium"
  ) ||
    "website"}&utm_source=${utm_source}&utm_term=${utm_term}&utm_content=${utm_content}`;
  const [unlocked] = useState(requiresLocking && isActivated);
  const [noLock] = useState(!requiresLocking);
  const [readyToEmbed] = useState(noLock || unlocked);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [embedSwitchUrl, setSwitchEmbedUrl] = useState(null);
  const [setTimer] = useState(isEmbeddedDemoPage);
  const [defaultHeight] = useState(isMobile ? "1020px" : height || "1111px");
  const [utms] = useState(_utms);

  /*
  test with: ?utm_campaign=QualifierReEngagement&utm_medium=email&utm_source=hubspot&utm_term=demo&utm_content=schedule-a-demo&message=93b4ce88-87cd-4ed6-b23a-8ad21cf318ea&uid=93b4ce88-87cd-4ed6-b23a-8ad21cf318ea
  test with: ?utm_campaign=lyra-web&utm_medium=web&utm_source=gamma&utm_term=demo&utm_content=schedule-a-demo
  test with: ?utm_campaign=lyra-web&utm_medium=web&utm_source=gamma&utm_term=demo&utm_content=try-before-you-fly
  const embedFormCodeWithParams =
    embedFormCode && isEnriched && hasParams && urlParams
      ? `${embedFormCode}${urlParams}`
      : embedFormCode;
  */
  // console.log("location", location);
  useEffect(() => {
    let mounted = true;
    if (readyToEmbed) {
      setEmbedUrl(embedFormCode);
    }
    if (isEmbeddedDemoPage) {
      setTimeout(() => {
        if (mounted) setTimer(false);
      }, 2801);
    }
    return () => {
      mounted = false;
    };
  }, []);

  const states = [
    { value: "Alabama", label: "Alabama", load: 1 },
    { value: "Alaska", label: "Alaska", load: 2 },
    { value: "Arizona", label: "Arizona", load: 2 },
    { value: "Arkansas", label: "Arkansas", load: 1 },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado", load: 2 },
    { value: "Connecticut", label: "Connecticut", load: 1 },
    { value: "Delaware", label: "Delaware", load: 1 },
    { value: "Florida", label: "Florida", load: 1 },
    { value: "Georgia", label: "Georgia", load: 1 },
    { value: "Hawaii", label: "Hawaii", load: 2 },
    { value: "Idaho", label: "Idaho", load: 2 },
    { value: "Illinois", label: "Illinois", load: 1 },
    { value: "Indiana", label: "Indiana", load: 1 },
    { value: "Iowa", label: "Iowa", load: 1 },
    { value: "Kansas", label: "Kansas", load: 1 },
    { value: "Kentucky", label: "Kentucky", load: 1 },
    { value: "Louisiana", label: "Louisiana", load: 1 },
    { value: "Maine", label: "Maine", load: 1 },
    { value: "Maryland", label: "Maryland", load: 1 },
    { value: "Massachusetts", label: "Massachusetts", load: 1 },
    { value: "Michigan", label: "Michigan", load: 1 },
    { value: "Minnesota", label: "Minnesota", load: 1 },
    { value: "Mississippi", label: "Mississippi", load: 1 },
    { value: "Missouri", label: "Missouri", load: 1 },
    { value: "Montana", label: "Montana", load: 2 },
    { value: "Nebraska", label: "Nebraska", load: 2 },
    { value: "Nevada", label: "Nevada", load: 2 },
    { value: "New Hampshire", label: "New Hampshire", load: 1 },
    { value: "New Jersey", label: "New Jersey", load: 1 },
    { value: "New Mexico", label: "New Mexico", load: 2 },
    { value: "New York", label: "New York", load: 1 },
    { value: "North Carolina", label: "North Carolina", load: 1 },
    { value: "North Dakota", label: "North Dakota", load: 2 },
    { value: "Ohio", label: "Ohio", load: 1 },
    { value: "Oklahoma", label: "Oklahoma", load: 2 },
    { value: "Oregon", label: "Oregon", load: 2 },
    { value: "Pennsylvania", label: "Pennsylvania", load: 1 },
    { value: "Puerto Rico", label: "Puerto Rico", load: 2 },
    { value: "Rhode Island", label: "Rhode Island", load: 1 },
    { value: "South Carolina", label: "South Carolina", load: 1 },
    { value: "South Dakota", label: "South Dakota", load: 2 },
    { value: "Tennessee", label: "Tennessee", load: 1 },
    { value: "Texas", label: "Texas", load: 2 },
    { value: "Utah", label: "Utah", load: 2 },
    { value: "Vermont", label: "Vermont", load: 1 },
    { value: "Virginia", label: "Virginia", load: 1 },
    { value: "Washington", label: "Washington", load: 2 },
    { value: "West Virginia", label: "West Virginia", load: 1 },
    { value: "Wisconsin", label: "Wisconsin", load: 1 },
    { value: "Wyoming", label: "Wyoming", load: 2 },
  ];
  const handleChange = event => {
    if (event.load === 1) {
      setSwitchEmbedUrl(embedFormCode);
    } else {
      setSwitchEmbedUrl(embedFormCode2);
    }
  };
  const TerritorySwitch = () => {
    if (embedSwitchUrl) {
      return (
        <div className="block p-6 md:px-12 md:py-12 bg-white shadow my-12 text-center transition transition flex flex-wrap sm:flex-no-wrap items-top my-4">
          <iframe
            src={`${embedSwitchUrl}${utms}`}
            allowFullScreen
            title="embedHubspotForm"
            security="restricted"
            height={defaultHeight}
            width="100%"
            style={{
              minHeight: defaultHeight,
            }}
          />
        </div>
      );
    }
    return (
      <div
        style={{ padding: isMobile ? "2rem" : "6rem" }}
        className="block p-12 md:px-12 md:py-12 bg-white shadow my-12 text-center transition transition flex flex-wrap sm:flex-no-wrap items-top my-4"
      >
        <div className="xxs:w-full m:w-1/2">
          <h2 className="text-xl leading-tighter font-bold antialiased text-md mb-4">
            {heading || "Discover the perfect fit with Lyra"}
          </h2>
          <p>
            {text ? (
              <RichText content={text} />
            ) : (
              "But first, let us know where your business is located."
            )}
          </p>
        </div>
        <div className="xxs:w-full m:w-1/2">
          <div>
            <label
              htmlFor="territory"
              className="text-black text-sm font-bold tracking-2"
            >
              {link_text || "Where is your headquarters?"}
            </label>
          </div>
          <div className="flex-grow">
            <Select
              className="p-3 bg-white border-blue focus:border-teal form-multiselect block w-full rounded-none border-l-4 p-1 pr-0 w-full transition"
              id="territory"
              name="territory"
              onChange={handleChange}
              placeholder="Please select one"
              isSearchable
              options={states}
              aria-required
              required
            />
          </div>
        </div>
      </div>
    );
  };
  const EmbedComponent = () => {
    if (readyToEmbed && embedTypeform && typeof window !== "undefined") {
      return (
        <Widget
          id={`${embedTypeform}${utms}`}
          className="embed"
          autoFocus={false}
          shareGaInstance
          inlineOnMobile
          keepSession
          height={defaultHeight || "100%"}
        />
      );
    }
    if (readyToEmbed && embedHubspot && typeof window !== "undefined") {
      if (embedUrl) {
        return (
          <>
            <iframe
              src={`${embedUrl}${utms}`}
              allowFullScreen
              title="embedHubspotForm"
              security="restricted"
            />
          </>
        );
      }
      return (
        <Hubspot.Form
          id={embedHubspot}
          utm={utms}
          className="embed"
          autoFocus={false}
          shareGaInstance
          inlineOnMobile
          keepSession
          height={defaultHeight || "100%"}
          hubspot={hubspot}
        />
      );
    }
    if (
      readyToEmbed &&
      embedSelection &&
      embedFormCode &&
      embedFormCode2 &&
      typeof window !== "undefined"
    ) {
      return <TerritorySwitch />;
    }
    if (readyToEmbed && embedCalendly && typeof window !== "undefined") {
      return (
        <InlineWidget
          url={`${embedCalendly}${utms}`}
          className="embed"
          id="calendly"
          autoFocus={false}
          shareGaInstance
          inlineOnMobile
          keepSession
          height={defaultHeight || "100%"}
        />
      );
    }
    if (
      readyToEmbed &&
      !embedTypeform &&
      !embedHubspot &&
      !embedCalendly &&
      embedUrl &&
      typeof window !== "undefined"
    ) {
      return (
        <iframe
          src={`${embedUrl}${utms}`}
          allowFullScreen
          title="embedForm"
          security="restricted"
        />
      );
    }
    return null;
  };

  const EmbedURL = () => {
    if (readyToEmbed) {
      return (
        <div
          className="common__embed-url"
          id="url-embed"
          style={{ height: defaultHeight, minHeight: defaultHeight }}
        >
          <EmbedComponent />
        </div>
      );
    }
    return (
      <div className="common__embed-url" id="url-embed">
        <div className="relative flex w-full justify-center items-center order-last">
          <div className="absolute w-3/7 mt-12 translate-x-1/4 right-0 top-0 bottom-0 -z-10 flex opacity-25">
            <Image
              src="https://gatsby-lyra.imgix.net/https%3A%2F%2Fimages.prismic.io%2Fgatsby-lyra%2Ff60e97ca919262136011e267d798a07f62ff1b69_718ff4d5d68b038b3297ede4e80d238b4b3cd84e.png%3Fauto%3Dcompress%2Cformat?auto=compress%2Cformat&fit=max&crop=faces%2Cedges%2Centropy&w=1612&h=1100&ixlib=js-2.0.0&s=d2fed093798f581ad3167bc15e29de6b"
              transparent
            />
          </div>
          <div
            className="bg-blue-offwhite absolute -mt-48 inset-0 z-0"
            style={{
              borderRadius: "100%",
              width: "125%",
              height: "200px",
              top: "200px",
              left: "-5%",
            }}
          />
          <Container variant="heading">
            <PrismicLink
              link={ctaLink}
              className="block p-6 md:px-12 md:py-12 bg-white shadow my-12 text-center transition hover:translate-y-lift hover:shadow-lift"
            >
              <div className="text-blue mx-auto flex justify-center my-4 w-12 h-12">
                <Icon iconName="LyraIcon" />
              </div>
              <h2 className="text-2xl sm:text-3xl font-bold my-4">
                {heading || "Oops..."}
              </h2>
              <div className="rich-text max-w-xl mx-auto text-xl my-4">
                {text ? (
                  <RichText content={text} />
                ) : (
                  "The link you used wasn't activated. Please try again using the link we provided you."
                )}
              </div>
              <div className="mt-10">
                <Button
                  bgClass="bg-black"
                  textClass="text-white"
                  hoverState={["opacity-75"]}
                >
                  {link_text || link?.title || "Continue"} →
                </Button>
              </div>
            </PrismicLink>
            <RandomShapes
              opacity=".1"
              seed="1"
              shapes={[
                { Circle: "coral" },
                { Square: "blue" },
                { FourthCircle: "coral" },
                { FourthCircle: "blue" },
              ]}
            />
          </Container>
        </div>
      </div>
    );
  };

  return (
    <>
      {embedForm &&
        (embedFormCode ||
          embedTypeform ||
          embedCalendly ||
          embedHubspot ||
          embedFormCode2) &&
        hasWindow &&
        typeof window !== "undefined" && (
          <section
            key={`${embedHubspot}${utms}`}
            className={classNames({
              "bg-blue gradient-blue": applyBlueFormBg,
              "bg-white":
                !applyBlueFormBg && (!isEmbeddedDemoPage || !embedHubspot),
              "bg-gray-form": isEmbeddedDemoPage || embedHubspot,
              "pt-0": isEmbeddedDemoPage,
              "pt-10": !isEmbeddedDemoPage,
              relative: true,
            })}
          >
            <div className="justify-center items-center order-last">
              <Container size="lg">
                <EmbedURL />
              </Container>
            </div>
          </section>
        )}
    </>
  );
});

export default EmbedUrl;
