/* eslint-disable react/prop-types */
import React from "react";
import { RichText } from "@ui";

export default ({ content }) => {
  if (content != null && content.length > 0) {
    return (
      <figcaption className="mt-4 text-right text-sm">
        <RichText content={content} />
      </figcaption>
    );
  }
  return null;
};
