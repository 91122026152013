/* eslint-disable react/button-has-type */
// TODO: Remove eslint-disable
/* eslint-disable no-unused-vars */

import React from "react";
import PropTypes from "prop-types";
import { PrismicLink } from "@utilities";

const hoverStates = hoverState => {
  let classes = "";
  // eslint-disable-next-line array-callback-return
  hoverState.map(value => {
    classes += ` hover:${value}`;
  });
  return classes;
};

const Button = ({
  link,
  onClick,
  bgClass,
  textClass,
  children,
  hoverState,
  className,
  activeclass,
  variant,
  route,
  type,
  disabled,
  rounded,
  style,
}) => {
  const handleClick = event => {
    if (onClick) {
      onClick(event);
    }
  };

  if (link === null && route == null) {
    return (
      <>
        <button
          type={type}
          onClick={event => handleClick(event)}
          disabled={disabled}
          className={`${bgClass} ${textClass} ${className} transition disabled:cursor-default font-bold text-sm ${rounded} inline-block ${
            // eslint-disable-next-line no-nested-ternary
            variant === "sm"
              ? "py-2 px-4"
              : variant === "xs"
              ? "py-2 px-3"
              : "py-3 px-6"
          } ${hoverStates(hoverState)}`}
          style={style}
        >
          {children}
        </button>
      </>
    );
  }
  return (
    <PrismicLink
      className={`transition inline-block inline-flex justify-center text-sm font-medium border-transparent ${rounded} focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue ${bgClass} ${textClass} ${className} ${
        // eslint-disable-next-line no-nested-ternary
        variant === "sm"
          ? "py-2 px-4"
          : variant === "xs"
          ? "py-2 px-3"
          : "py-3 px-6"
      } ${hoverStates(hoverState)}`}
      activeclass={`${activeclass}`}
      link={link || null}
      route={route || null}
      onClick={handleClick}
      style={style}
    >
      {children}
    </PrismicLink>
  );
};

Button.defaultProps = {
  bgClass: "bg-black",
  textClass: "text-white",
  hoverState: ["opacity-100"],
  className: "",
  activeclass: "",
  link: null,
  onClick: null,
  variant: "md",
  route: null,
  type: "button",
  disabled: false,
  rounded: "rounded-3xl",
  children: {},
  style: null,
};

Button.propTypes = {
  children: PropTypes.node,
  link: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  onClick: PropTypes.func,
  route: PropTypes.string,
  bgClass: PropTypes.string,
  textClass: PropTypes.string,
  hoverState: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  activeclass: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  rounded: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
};

export default React.memo(Button);
