/* eslint-disable global-require */
/* eslint-disable react/prop-types */
/** @jsx React.createElement */
/** @jsxFrag React.Fragment */
import React, { useEffect, useState } from "react";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import "./algoliasearchNetlify.css";
import { detect } from "@utils";

const hasWindow = detect.browser().window;
let algoliasearchNetlify = hasWindow
  ? require("@algolia/algoliasearch-netlify-frontend/dist/algoliasearchNetlify")
  : null;

export const SearchBox = ({ limit, selector, placeHolder }) => {
  const [mounted, setMounted] = useState(false);
  const useSelector = selector ? `#${selector}` : "#search";
  const useAlgoliaNetlify =
    process.env.GATSBY_ALGOLIA_APP_ID &&
    process.env.GATSBY_ALGOLIA_API_SEARCH_KEY &&
    process.env.GATSBY_ALGOLIA_SITE_ID;
  const ALGOLIA_BRANCHES =
    process.env.GATSBY_ALGOLIA_BRANCHES &&
    process.env.GATSBY_ALGOLIA_BRANCHES.split(",");
  const ALGOLIA_BRANCH =
    process.env.GATSBY_ALGOLIA_BRANCH || process.env.HEAD || "master";

  const algoliaNetlifySettings = {
    appId: process.env.GATSBY_ALGOLIA_APP_ID,
    apiKey: process.env.GATSBY_ALGOLIA_API_SEARCH_KEY,
    siteId: process.env.GATSBY_ALGOLIA_SITE_ID,
    branch: ALGOLIA_BRANCH,
    mainBranch: ALGOLIA_BRANCH,
    branches: ALGOLIA_BRANCHES || [
      "master",
      "staging",
      "netlify",
      "styles/EmbedUrl",
      "search/crawler1",
    ],
    // pathPrefix: "/blog",
    selector: useSelector,
    placeholder: placeHolder || "Search the blog...",
    detached: false,
    theme: {
      mark: "#2D80EF", // Color of the matching content
      selected: "#f5f5f5", // Background Color of the selected item
      colorSourceIcon: "#2D80EF", // Color of the icon on the left of results
    },
    hitsPerPage: limit || 108,
    openOnFocus: false,
    analytics: process.env.HEAD === "master",
  };

  const Netlify = () => {
    useEffect(() => {
      algoliasearchNetlify =
        algoliasearchNetlify || hasWindow
          ? require("@algolia/algoliasearch-netlify-frontend/dist/algoliasearchNetlify")
          : null;
      if (
        !mounted &&
        hasWindow &&
        !!algoliasearchNetlify &&
        !!useAlgoliaNetlify
      ) {
        algoliasearchNetlify(algoliaNetlifySettings);
        setMounted(true);
      }
    }, [mounted]);
    return <></>;
  };

  return <Netlify />;
};

export default SearchBox;
