/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { graphql, StaticQuery } from "gatsby";
import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import { Icon } from "@ui";
import { PrismicLink } from "@utilities";
import TailwindConfig from "@tailwindConfig";

import { Container } from "@layout";
import { HeaderContext } from "@context";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import AnnouncementEs from "../AnnouncementEs";

const query = graphql`
  {
    prismic {
      allPostss(
        sortBy: published_date_DESC
        lang: "en-us"
        where: { hide: false }
      ) {
        edges {
          node {
            _meta {
              uid
              lastPublicationDate
              firstPublicationDate
            }
            category
            summary
            title
            hide
            published_date
            dark_theme
          }
        }
      }
      site_settings(lang: "es-pr", uid: "site-settings") {
        maintenance
        _linkType
        cta_link {
          _linkType
          ... on PRISMIC__ExternalLink {
            url
            target
          }
          ... on PRISMIC_Design {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Posts {
            _meta {
              uid
            }
          }
          ... on PRISMIC_For_manufacturers {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Pages {
            path
            title
            meta_title
            _linkType
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Contact {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Pricing {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Permit_packages {
            title
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_About {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Blog {
            _linkType
            _meta {
              alternateLanguages {
                uid
              }
            }
          }
          ... on PRISMIC_Home {
            title
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC__FileLink {
            url
          }
          ... on PRISMIC__ImageLink {
            url
          }
        }
        mobile_cta_link {
          _linkType
          ... on PRISMIC__ExternalLink {
            url
            target
          }
          ... on PRISMIC_Design {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Posts {
            _meta {
              uid
            }
          }
          ... on PRISMIC_For_manufacturers {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Pages {
            path
            title
            meta_title
            _linkType
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Contact {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Pricing {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Permit_packages {
            title
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_About {
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC_Blog {
            _linkType
            _meta {
              alternateLanguages {
                uid
              }
            }
          }
          ... on PRISMIC_Home {
            title
            _meta {
              uid
              lang
            }
          }
          ... on PRISMIC__FileLink {
            url
          }
          ... on PRISMIC__ImageLink {
            url
          }
        }
        mobile_nav_label
        mobile_nav {
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_text
          link_description
          hero_icon
        }
        mobile_cta_link_text
        mobile_cta_hero_icon
        mobile_secondary_cta_link_text
        mobile_secondary_cta_hero_icon
        primary_nav_label
        primary_nav {
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_text
          link_description
          hero_icon
        }
        learn_nav_label
        learn_nav {
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_text
          link_description
          hero_icon
        }
        solutions_nav_label
        solutions_nav {
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_text
          link_description
          hero_icon
        }
        features_nav_label
        features_nav {
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
                lang
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_text
          link_description
          hero_icon
        }
        cta_link_text
        cta_hero_icon
        secondary_cta_link_text
        secondary_cta_hero_icon
        show_sign_in
        features_nav_cta_one_label
        features_nav_cta_one_link
        features_nav_cta_two_label
        features_nav_cta_two_link
        solutions_nav_cta_one_label
        solutions_nav_cta_one_link
        solutions_nav_cta_two_label
        solutions_nav_cta_two_link
      }
    }
  }
`;

const Header = React.memo(({ data }) => {
  // let timeout;
  const hasWindow = typeof window !== `undefined`;
  const headerNav = data.prismic.site_settings;
  const recentPosts = data.prismic.allPostss.edges;
  const {
    loadSDTSunset,
    theme,
    navOpen,
    setNavOpen,
    announcementShowing,
    tl,
    setBlogCategories,
    setBlogPosts,
    isBlog,
    isBlogLayout,
    isFullPage,
    setIsMaintenance,
    lang,
  } = useContext(HeaderContext);
  const windowPath = hasWindow && window && window.location?.pathname;
  const isBlogWindow = windowPath && windowPath.includes("blog");
  const showBlog = isBlogWindow || isBlogLayout || isBlog || isFullPage;
  const headerVariant = showBlog ? "full" : "site";

  // a default header offset in case there is an announcement
  const [fixed, setFixed] = useState(false);
  const [logo, setLogo] = useState("LogoHeader");
  const [headerHeight, setHeaderHeight] = useState(0);
  const [scrollY, setScrollY] = useState(hasWindow ? window.pageYOffset : 0);
  const [scrollingUp, setScrollingUp] = useState(false);
  const [topAnchor, setTopAnchor] = useState(0);
  const prevScrollY = useRef(); // React recommends storing prev values in a ref
  const AppHeader = useRef();
  const homeLangRoute = lang ? `/${lang}/home` : "/";
  const [homeRoute] = useState(homeLangRoute);

  // disable eslint because the content is so deep
  // eslint-disable-next-line
  const { mobile_cta_link_text,
    mobile_cta_link,
    mobile_secondary_cta_link_text,
    mobile_secondary_cta_link,
    mobile_nav,
    mobile_nav_label,
    cta_link_text,
    cta_link,
    primary_nav,
    learn_nav,
    learn_nav_label,
    solutions_nav,
    solutions_nav_label,
    features_nav,
    features_nav_label,
    features_nav_cta_one_label,
    features_nav_cta_one_link,
    features_nav_cta_two_label,
    features_nav_cta_two_link,
    solutions_nav_cta_one_label,
    solutions_nav_cta_one_link,
    solutions_nav_cta_two_label,
    solutions_nav_cta_two_link,
    show_sign_in,
    maintenance,
  } = headerNav;

  // Loop through all posts to get an array of categories
  const categories = [];
  recentPosts.forEach(({ node }) => {
    if (categories.findIndex(cat => cat.name === node.category) === -1) {
      categories.push({
        name: node.category,
        slug: node.category
          .toLowerCase()
          .split(" ")
          .join("-"),
      });
    }
  });
  const recentPostsFiltered = recentPosts.filter((val, i) => i < 3);

  // useEffect if hasWindow
  if (hasWindow) {
    const addBackground = background => {
      const appHeader = document.getElementById("AppHeader");
      if (background) {
        tl.kill({ background: true, boxShadow: true }, appHeader).to(
          appHeader,
          0.333,
          {
            background: showBlog
              ? TailwindConfig.theme.extend.colors.black
              : theme === "green"
              ? TailwindConfig.theme.extend.colors.green
              : theme === "light"
              ? TailwindConfig.theme.extend.colors.white
              : "linear-gradient(130deg, #133095, #2D80EF)",
            boxShadow: TailwindConfig.theme.extend.boxShadow.default,
            "-webkit-mask":
              "radial-gradient(var(--R) at 50% calc(1.8*var(--size)),#000 99%,#0000 101%) calc(50% - 2*var(--size)) 0/calc(4*var(--size)) 100%, radial-gradient(var(--R) at 50% calc(-.8*var(--size)),#0000 99%,#000 101%) 50% var(--size)/calc(4*var(--size)) 100% repeat-x;",
          },
          0.125
        );
      } else {
        tl.kill({ background: true, boxShadow: true }, appHeader).to(
          appHeader,
          0.333,
          {
            background: "transparent",
            boxShadow: TailwindConfig.theme.extend.boxShadow.none,
          },
          0.125
        );
      }
    };

    // LISTEN TO SCROLL AND RESIZE
    // attach event listeners
    useEffect(() => {
      const container = document.getElementById("HeaderContainer");
      if (showBlog && container) {
        container.classList.add("w-full");
        container.classList.add("blog--nav--layout");
        container.classList.remove("max-w-5xl");
        container.classList.remove("xl:max-w-6xl");
      }
      const appHeader = document.getElementById("AppHeader");
      const onResize = () => {
        setHeaderHeight(appHeader?.clientHeight);
      };
      const onScroll = () => {
        // if (timeout) {
        //   window.cancelAnimationFrame(timeout);
        // }
        // timeout = window.requestAnimationFrame(() => {
        setScrollY(window.pageYOffset);
        // });
      };
      window.addEventListener("scroll", onScroll, false);
      window.addEventListener("resize", onResize);
      onResize();
      return () => {
        window.removeEventListener("scroll", onScroll);
        window.removeEventListener("resize", onResize);
      };
    }, []);

    useEffect(() => {
      const baseDuration = navOpen ? 0.5 : 0;
      const logoIcon = document.getElementsByClassName("logo-header-icon")[0];
      if ((loadSDTSunset.page || loadSDTSunset.domain) && theme === "light") {
        setLogo("LogoHeaderSunriseDark");
      } else if (
        (loadSDTSunset.page || loadSDTSunset.domain) &&
        theme !== "light"
      ) {
        setLogo("LogoHeaderSunrise");
      } else {
        setLogo("LogoHeader");
      }
      const logoLetters = Array.from(
        document.getElementsByClassName("logo-header-letter")
      );
      if (logoIcon && logoLetters) {
        if (theme === "light") {
          tl.kill(null, logoIcon).to(
            logoIcon,
            0.333,
            { fill: TailwindConfig.theme.extend.colors.blue.default },
            baseDuration + 0.25
          );
          // eslint-disable-next-line array-callback-return
          logoLetters.map(letter => {
            tl.kill(null, letter).to(
              letter,
              0.333,
              { fill: TailwindConfig.theme.extend.colors.black },
              baseDuration + 0.25
            );
          });
        } else if (theme !== null) {
          tl.to(
            logoIcon,
            0.333,
            { fill: TailwindConfig.theme.extend.colors.white },
            baseDuration + 0.25
          );
          // eslint-disable-next-line array-callback-return
          logoLetters.map(letter => {
            tl.to(
              letter,
              0.333,
              { fill: TailwindConfig.theme.extend.colors.white },
              baseDuration + 0.25
            );
          });
        }
      }
    }, [theme]);

    // SHOW ANNOUNCEMENT
    // move body down if announcementShowing === true
    useEffect(() => {
      const App = document.getElementById("layout-container");

      // need delay to get actual height of announcement window which is being animated
      // not the most graceful way to handle this but explicit height isn't responsive
      setTimeout(() => {
        if (announcementShowing) {
          const announcementHeight = document.getElementById("AppAnnouncement")
            ? document.getElementById("AppAnnouncement").clientHeight
            : 0;
          tl.to(App, 0.333, { paddingTop: `${announcementHeight}px` });
          setHeaderHeight(
            document.getElementById("AppHeader")
              ? document.getElementById("AppHeader").clientHeight
              : 0
          );
        } else {
          tl.to(App, 0.333, { paddingTop: 0 });
          setHeaderHeight(
            document.getElementById("AppHeader")
              ? document.getElementById("AppHeader").clientHeight
              : 0
          );
        }
      }, 100);
    }, [announcementShowing]);

    // SCROLL DIR
    // check if scrolling up or not
    useEffect(() => {
      const lastScrollY = prevScrollY.current;
      if (
        scrollY < lastScrollY &&
        !scrollingUp &&
        (scrollY < topAnchor - 2 || scrollY > topAnchor + headerHeight * 2) &&
        scrollY > 0
      ) {
        setFixed(true);
        addBackground(true);
        setScrollingUp(true);
        setTopAnchor(0);
        AppHeader.current.style.position = "fixed";
      } else if (
        (scrollY >= lastScrollY + 2 && scrollingUp && !navOpen) ||
        scrollY <= 0
      ) {
        setFixed(false);
        setScrollingUp(false);
        setTopAnchor(scrollY <= 0 ? 0 : scrollY);
        if (scrollY <= 0) {
          addBackground(false);
        } else {
          addBackground(true);
        }
        AppHeader.current.style.position = "absolute";
      }
      prevScrollY.current = scrollY;
    }, [scrollY, announcementShowing]);

    // OPEN/CLOSE NAV
    // stop body scroll if mobile nav open // reset header height after animation ends
    useEffect(() => {
      const navHeight = document.getElementById("AppMobileNav").clientHeight;
      const header = document.getElementById("AppHeader");
      const App = document.getElementById("layout-container");
      const MenuButton = document.getElementById("MenuButton");
      const { body } = document;
      const currentScrollPos = scrollY;
      if (navOpen === true) {
        setFixed(false);
        AppHeader.current.style.position = "absolute";
        header.style.top = `${currentScrollPos}px`;
        tl.invalidate()
          // move layout down
          .to(App, 0.333, { y: navHeight }, 0)
          // move menu down
          .to(AppHeader.current, 0.333, { y: navHeight }, 0)
          // hide menu button
          .to(
            MenuButton,
            0.333,
            {
              opacity: 0,
            },
            0.125
          );
        body.classList.add("overflow-hidden");
        addBackground(true);
      } else {
        body.classList.remove("overflow-hidden");
        tl.invalidate()
          // move layout back up
          .to(App, 0.333, { y: 0 }, 0)
          // move nav back up
          .to(AppHeader.current, 0.333, { y: 0 }, 0)
          // enable nav button again
          .to(
            MenuButton,
            0.333,
            {
              opacity: 1,
            },
            0.125
          );
        setScrollingUp(false);
        if (document.getElementById("AppAnnouncement")) {
          document.getElementById("AppAnnouncement").style.opacity = null;
          document.getElementById("AppAnnouncement").style.pointerEvents = null;
        }
        addBackground(false);
      }
    }, [navOpen]);

    // ADD BLOG POSTS TO CONTEXT FOR USE IN MENUS
    // And update whether the AppHeader container needs full layout or site
    useEffect(() => {
      setBlogCategories(categories);
      setBlogPosts(recentPosts);
    }, []);

    // Set if site maintenance is on or off (from prismic site_settings)
    useEffect(() => {
      setIsMaintenance(maintenance);
    }, []);
  }
  return (
    <>
      <header
        ref={AppHeader}
        id="AppHeader"
        className={classNames({
          "bg-black gradient-black": showBlog,
          green: loadSDTSunset.page,
          dark: (theme !== "light" || showBlog) && !loadSDTSunset.page,
          "bg-blue gradient-blue":
            theme !== "light" && !loadSDTSunset.page && !showBlog,
          light: theme === "light" && !loadSDTSunset.page && !showBlog,
          absolute: !fixed,
          fixed,
          "top-0 left-0 right-0 text-white shadow-lg z-40 sm:tracking-0.5": true,
        })}
        style={{
          top: topAnchor,
        }}
      >
        <AnnouncementEs />
        <MobileNav
          topAnchor={topAnchor}
          mobile_nav={mobile_nav}
          mobile_nav_label={mobile_nav_label}
          mobile_cta_link={mobile_cta_link}
          mobile_cta_link_text={mobile_cta_link_text}
          mobile_secondary_cta_link_text={mobile_secondary_cta_link_text}
          mobile_secondary_cta_link={mobile_secondary_cta_link}
          learn_nav={learn_nav}
          learn_nav_label={learn_nav_label}
          recentPosts={recentPostsFiltered}
          isBlog={showBlog}
        />
        <Container
          id="HeaderContainer"
          className={classNames({
            "w-full blog--nav": showBlog,
            "xxs:h-20 h-24": true,
          })}
          variant={headerVariant}
        >
          <div className="flex w-full h-full sm:w-auto items-center justify-between z-10">
            {/* logo / link home */}
            <PrismicLink
              route={
                theme === "green" ? `${homeRoute}?sunrise=lyra` : homeRoute
              }
              className="cursor-pointer"
              activeStyle={{ cursor: "default" }}
            >
              <Icon
                iconName={logo}
                className={classNames({
                  "logo-normal": theme === "light",
                  "logo-white": theme !== "light",
                  "xxs:w-20 w-24 sm:w-32 sm:pr-8 md:pr-4 lg:pr-2 h-auto sm:mb-4": true,
                })}
              />
            </PrismicLink>
            <DesktopNav
              primary_nav={primary_nav}
              learn_nav={learn_nav}
              learn_nav_label={learn_nav_label}
              solutions_nav={solutions_nav}
              solutions_nav_label={solutions_nav_label}
              features_nav={features_nav}
              features_nav_label={features_nav_label}
              features_nav_cta_one_label={features_nav_cta_one_label}
              features_nav_cta_one_link={features_nav_cta_one_link}
              features_nav_cta_two_label={features_nav_cta_two_label}
              features_nav_cta_two_link={features_nav_cta_two_link}
              solutions_nav_cta_one_label={solutions_nav_cta_one_label}
              solutions_nav_cta_one_link={solutions_nav_cta_one_link}
              solutions_nav_cta_two_label={solutions_nav_cta_two_label}
              olutions_nav_cta_two_link={solutions_nav_cta_two_link}
              cta_link={cta_link}
              cta_link_text={cta_link_text}
              recentPosts={recentPostsFiltered}
              show_sign_in={show_sign_in}
              hide={showBlog}
              blog={showBlog}
              full={isFullPage}
            />
            {/* mobile nav button */}
            <div className="md:hidden z-40 m-2">
              <button
                type="button"
                className="text-white relative cursor-pointer"
                id="MobileButton"
                onClick={() => {
                  setNavOpen(true);
                }}
              >
                <Icon
                  id="MenuButton"
                  iconName="Menu"
                  className={classNames({
                    "text-black": theme === "light",
                    "text-white": theme === "green" || theme !== "light",
                    "stroke-current inset-0 m-auto block h-7 w-7": true,
                  })}
                />
              </button>
            </div>
          </div>
        </Container>
      </header>
    </>
  );
});

// and export as a static query
export default React.memo(() => (
  <StaticQuery query={`${query}`} render={data => <Header data={data} />} />
));
