/* eslint-disable react/prop-types */
import React from "react";
import { Button } from "@ui";

const CTAButton = React.memo(({ content }) => {
  if (content.button_link !== null) {
    return (
      <div className="mt-8">
        <Button
          link={content.button_link}
          bgClass="bg-transparent"
          textClass="text-white font-bold"
          className="cursor-pointer py-2 pre-underline-2 border-3 border-white"
          activeclass="opacity-100"
          variant="lg"
          hoverState={["text-blue", "bg-white"]}
        >
          {content.button_text}
        </Button>
      </div>
    );
  }
  return null;
});

export default CTAButton;
