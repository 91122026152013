/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from "react";
import lottie from "lottie-web";
import { Player } from "@lottiefiles/react-lottie-player";
import { HeroAnimation } from "@json";
import { HeaderContext } from "@context";

export default ({ transitionStatus, animation, style }) => {
  const { navOpen } = useContext(HeaderContext);
  const loadLocal = animation && animation.endsWith("json");
  useEffect(() => {
    if (!loadLocal && transitionStatus === "entered") {
      setTimeout(() => {
        lottie.loadAnimation({
          container: document.getElementById("animation"),
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: HeroAnimation,
        });
      }, 500);
    }
    if (!loadLocal && transitionStatus === "exiting") {
      lottie.destroy();
    }
  }, [transitionStatus]);
  useEffect(() => {
    if (!loadLocal && navOpen) {
      lottie.pause();
    } else if (!loadLocal) {
      lottie.play();
    }
  }, [navOpen]);
  const localAnimation = !loadLocal && (
    <div id="animation" className="-my-32 w-full md:translate-x-50px -mx-8" />
  );
  const remoteAnimation = loadLocal ? (
    <Player autoplay loop src={animation} style={style} />
  ) : null;
  return remoteAnimation || localAnimation;
};
