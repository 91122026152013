import React from "react";
import PropTypes from "prop-types";

const SectionNumber = ({ number, className, color }) => {
  return (
    <svg
      className={`w-16 h-16 xl:h-20 opacity-90 text-${color} text-3xl fill-current stroke-current font-bold ${className}`}
      version="1.1"
      viewBox="0 0 42 20"
    >
      <text x="1.5" y="12" fill="none" strokeWidth="1px">
        {number}
      </text>
      <text x="0" y="13.5" stroke="none" className="opacity-33">
        {number}
      </text>
    </svg>
  );
};

SectionNumber.propTypes = {
  number: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

SectionNumber.defaultProps = {
  className: "",
  color: "blue",
};

export default SectionNumber;
