/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";

const SectionHeading = React.memo(
  ({ content, className, variant, heading, subheading }) => {
    const SectionSubheading = ({ text }) => {
      if (subheading || content.subheading[0].text.length > 0) {
        return (
          <h3 className="text-center uppercase tracking-3 mb-2 text-sm sm:text-base font-bold text-blue">
            {text}
          </h3>
        );
      }
      return null;
    };
    return (
      <div className={className}>
        <SectionSubheading text={subheading || content.subheading[0].text} />
        <h2
          className={classNames({
            "text-xl sm:text-2-5xl": variant === "sm",
            "text-2xl sm:text-4xl": variant !== "sm",
            "text-center leading-tight font-bold": true,
          })}
        >
          {heading || content.heading[0].text}
        </h2>
      </div>
    );
  }
);

export default SectionHeading;
