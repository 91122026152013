/* eslint-disable import/no-cycle */
export { default as EmailSignup } from "./EmailSignup";
export { default as ExtendedEmailSignup } from "./ExtendedEmailSignup";
export { default as ExtendedFormSignup } from "./ExtendedFormSignup";
export { default as ContactSalesButtons } from "./ContactSalesButtons";
export { default as DefaultLocations } from "./locations";
export const DefaultRoles = [
  { value: "Installer", label: "Installer" },
  { value: "Distributor", label: "Distributor" },
  { value: "Manufacturer", label: "Manufacturer" },
  { value: "PV Engineer", label: "PV Engineer" },
  { value: "Software Company", label: "Software Company" },
  { value: "Homeowner", label: "Homeowner" },
  { value: "Other", label: "Other" },
];
