/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { HeaderContext } from "@context";
import { HeroContact, Container } from "@layout";
import { EopCta, MetaData } from "@common";
import { ShapesPatternRepeat } from "@svg";
// import ContactButtons from "../components/contact/ContactButtons";
import { ContactSalesButtons } from "@contact";
import { PageTransition } from "@components";

const Contact = props => {
  // disable eslint because the content is so deep
  // eslint-disable-next-line
  const { contact } = props.data.prismic;
  const { setSunrisePage, setBlogLayout } = useContext(HeaderContext);
  useEffect(() => {
    setSunrisePage(false);
    setBlogLayout(false);
  }, []);
  // const contactOptions = contact.contact_options[0];
  // const showSlices = contactOptions.show_form;
  // const showSalesButtons = contactOptions.display_link;
  return (
    <>
      <MetaData content={contact} type="webpage" />
      <PageTransition theme="light">
        <article className="z-10 relative">
          <HeroContact
            contactOptions={contact.contact_options[0]}
            content={contact.hero[0]}
            variant="light"
            curve
            inverted
          />
          <section className="relative pb-24">
            <div className="absolute inset-0 flex items-center justify-center mt-8">
              <ShapesPatternRepeat
                className="opacity-3 text-blue stroke-current h-full w-full"
                dimensions={[10, 10]}
              />
            </div>
            <Container className="-mt-8 pt-24">
              <ContactSalesButtons
                form={contact.body[0]}
                uid={contact._meta.uid}
                content={contact.contact_options[0]}
                variant="light"
              />
            </Container>
            <Container>
              <EopCta content={contact.cta_eop[0]} gradient="blue" />
            </Container>
            <div className="bg-transparent h-24 relative z-0" />
          </section>
        </article>
      </PageTransition>
    </>
  );
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    prismic {
      contact(lang: "en-us", uid: "contact") {
        _meta {
          uid
        }
        title
        meta_title
        meta_image
        meta_description
        contact_options {
          show_form
          chat_button_text
          chat_heading
          display_link
          content
          email_address
          email_heading
          link_heading
          link_label
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
        hero {
          heading
          subtitle
          button_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
        cta_eop {
          tracking_event
          heading
          subheading
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
        }
        body {
          ... on PRISMIC_ContactBodyEmail_signup_block {
            type
            label
            primary {
              background_image
              failure_message
              heading
              submit_button_text
              submit_button_color
              icon
              subtitle
              success_message
              list_id
              promo_code
              promo_memo
              tracking_event
              form_collector
              form_id
              form_url
              webhook_url
              form_embed_method
              upload_widget
              recaptcha_widget
              require_phone_number
              show_phone_number
              collect_volume
            }
            fields {
              label
              value
            }
          }
        }
      }
    }
  }
`;
