/* eslint-disable react/prop-types */
import React from "react";
import { ShapesPatternHomepage } from "@svg";

export default React.memo(() => {
  return (
    <div
      className="absolute flex flex-col items-start justify-center"
      style={{
        height: "200%",
        width: "200%",
        top: "-33%",
        left: "-50%",
      }}
    >
      <ShapesPatternHomepage
        className="shapes-pattern-homepage opacity-8 stroke-current"
        dimensions={[12, 22]}
        style={{
          marginLeft: "25%",
        }}
      />
    </div>
  );
});
