/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import axios from "axios";
import parser from "tld-extract";
import { huntRisky, huntErrors, huntAccount, allowHunting } from "@utils";

const apiKey =
  process.env.GATSBY_ACTIVE_ENV === "development"
    ? process.env.GATSBY_HUNTER_DEV_API_KEY
    : process.env.GATSBY_HUNTER_API_KEY;
const backupApiKey1 = process.env.GATSBY_HUNTER_BACKUP_API_KEY;
const backupApiKey2 = process.env.GATSBY_HUNTER_DEV_API_KEY;
const apiBase = `https://api.hunter.io/v2/`;
const endPoint = (method, backup) => {
  let backupKey = backupApiKey1;
  if (backup === 2) backupKey = backupApiKey2;
  return `${apiBase}${method}&api_key=${backup ? backupKey : apiKey}`;
};

/*  Account check
@domain = string
This API endpoint allows you to check the Hunter account - useful for knowing limits
https://hunter.io/api-documentation/v2#account
*/
export const huntAccountCheck = async () => {
  if (allowHunting) {
    let hunted;
    return axios
      .get(endPoint(`account?check=true`))
      .then(results => {
        hunted = huntAccount(results);
        // console.log("Results", results);
        // console.log("Account: ", hunted?.team, hunted);
        if (hunted.allow) {
          return hunted;
        }
        // try the backup account
        return axios
          .get(endPoint(`account?check=true`, 1))
          .then(results1 => {
            hunted = huntAccount(results1);
            // console.log("Account 2: ", hunted?.team, hunted);
            if (hunted.allow) {
              return hunted;
            }
            // try the 2nd backup (dev) account
            return axios
              .get(endPoint(`account?check=true`, 2))
              .then(results2 => {
                hunted = huntAccount(results2);
                // console.log("Account 3: ", hunted?.team, hunted);
                return hunted;
              })
              .catch(errors => {
                // console.log("Hunt tier 3 not activated.", errors);
                return huntErrors(errors);
              });
          })
          .catch(errors => {
            // console.log("Hunt tier 2 not activated.", errors);
            return huntErrors(errors);
          });
      })
      .catch(errors => {
        // console.log("Hunt tier 1 not activated.", errors);
        return huntErrors(errors);
      });
  }
  return { allow: false };
};

/* Email Verification
@allowHunting = bool
@bypass = bool
@email = string
Check if a given email address is deliverable and has been found on the internet.
https://hunter.io/api-documentation/v2#email-verifier
*/
export const huntVerify = async (allow, bypass, email) => {
  if (bypass) {
    return huntRisky(false, bypass, null);
  }
  if (allow && allowHunting) {
    return axios
      .get(endPoint(`email-verifier?email=${encodeURIComponent(email)}`))
      .then(hunt => {
        // console.log("huntVerify: hunt", hunt);
        return huntRisky(allow, bypass, hunt);
      })
      .catch(errors => {
        const { message } = errors;
        console.error(message);
        return huntErrors(errors);
      });
  }
  // allow: false, bypass: true, data: null
  return huntRisky(false, bypass, null);
};

/* Email Finder
@data = object
Get every email address found on the internet using a given domain name, with sources.
https://hunter.io/api-documentation/v2#email-finder
object: {
  domain: "lyrasolar.com",
  company: "Lyra Solar",
  first_name: "Derek",
  last_name: "Mitchell",
  full_name: "Derek Mitchell",
}
*/
export const huntEmail = data => {
  const address = data.email.split("@").pop();
  const domain = parser(address);
  axios
    .get(
      endPoint(
        `email-finder?domain=${domain}&first_name=${data.firstName}&last_name=${data.lastName}`
      )
    )
    .then(results => {
      // console.log("Email", results);
      return results;
    })
    .catch(errors => {
      // console.log("No email results", error);
      return huntErrors(errors);
    });
};

/* Domain Search
@domain = string
Get every email address found on the internet using a given domain name, with sources.
https://hunter.io/api-documentation/v2#domain-search
*/
export const huntDomain = domain => {
  axios
    .get(endPoint(`domain-search?domain=${domain}`))
    .then(results => {
      // console.log("Domain", results);
      return results;
    })
    .catch(errors => {
      // console.log("No domain results", error);
      return huntErrors(errors);
    });
};

/*  Email Count
@domain = string
This API endpoint allows you to know how many email addresses we have for 
one domain or for one company.
https://hunter.io/api-documentation/v2#email-count
*/
export const huntCount = domain => {
  axios
    .get(endPoint(`email-count?domain=${domain}`))
    .then(results => {
      // console.log("Count", results);
      return results;
    })
    .catch(errors => {
      // console.log("Count not found.", error);
      return huntErrors(errors);
    });
};
