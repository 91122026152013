/* eslint-disable react/prop-types */
import React from "react";
import { RichText, Icon } from "@ui";
import { Container } from "@layout";

export default ({ content }) => {
  return (
    <Container variant="heading" className="pt-16 pb-12 highlight-card">
      <div className="text-center">
        <div className="mb-6">
          <Icon iconName="LyraIcon" className="w-12 h-12 mx-auto text-blue" />
        </div>
        <h3 className="text-blue text-sm uppercase tracking-1 mb-4 font-bold">
          {content.subheading[0].text}
        </h3>
        <h2 className="text-black leading-tight text-3xl md:text-4xl mb-8 font-bold">
          {content.heading[0].text}
        </h2>
        <div className="rich-text rich-text--bullets-only">
          <RichText content={content.bullets} />
        </div>
      </div>
    </Container>
  );
};
