/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-plusplus */
import React from "react";
import { _hbspt } from "@hubspot";
import { detect } from "@utils";

const hasWindow = detect.browser().window;
export const hubspotId = process.env.GATSBY_HUBSPOT_HUB_ID;
export const hubspotEnabled = !!(
  process.env.GATSBY_HUBSPOT_MODE === "on" && process.env.GATSBY_HUBSPOT_HUB_ID
);
export const hubspotActive =
  hubspotEnabled &&
  hasWindow &&
  window?.HubSpotConversations &&
  window.HubSpotConversations?.widget.status();
let loadTriggered = false;

/*
{
    id: props.id,
    portalId: PORTAL_ID,
    guid: props.guid,
    name: props.name,
    action: props.action || "",
    method: props.method || "POST",
    cssClass: props.cssClass,
    redirect: props.redirect,
    submitText: props.submitText,
    followUpId: props.followUpId,
    notifyRecipients: props.notifyRecipients,
    leadNurturingCampaignId: props.leadNurturingCampaignId,
    formFieldGroups: props.fieldGroups,
    metaData: props.metaData,
    inlineMessage: props.inlineMessage,
    isPublished: props.isPublished,
    thankYouMessageJson: props.thankYouMessageJson,
    children: [],
    parent: `__SOURCE__`,
    internal: {
        type: `HubspotForm`,
    }
*/
const loadForm = props => {
  console.log("hubspot loadForm", props);
  if (_hbspt) {
    console.log("_hbspt loadForm", props);
    loadTriggered = true;
    return window.hbspt.forms.create({
      ...props, // see https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
      // Required
      formId:
        props?.formId ||
        process.env.GATSBY_HUBSPOT_DEFAULT_FORM ||
        "cae62dff-35c8-426f-9d0c-7d1813a4b847",
      region: props?.region || process.env.GATSBY_HUBSPOT_REGION || "na1",
      portalId:
        props?.portalId || process.env.GATSBY_HUBSPOT_HUB_ID || "43908954",
      // Optional
      guid: props?.guid,
      name: props?.name,
      action: props?.action || "",
      method: props?.method || "POST",
      cssClass: props?.cssClass,
      redirect: props?.redirect,
      submitText: props?.submitText,
      followUpId: props?.followUpId,
      notifyRecipients: props?.notifyRecipients,
      leadNurturingCampaignId: props?.leadNurturingCampaignId,
      formFieldGroups: props?.fieldGroups,
      metaData: props?.metaData,
      inlineMessage: props?.inlineMessage,
      isPublished: props?.isPublished,
      thankYouMessageJson: props?.thankYouMessageJson,
      height: props?.height || "100%",
      target: props?.target || "#hubspotForm",
    });
  }
};

const Form = props => {
  if (!loadTriggered && _hbspt) {
    return loadForm(props);
  }
  setTimeout(() => {
    loadForm(props);
  }, 555);

  return (
    <div>
      <div style={{ height: props?.height || "100%" }} id="hubspotForm" />
    </div>
  );
};

const Hubspot = {
  Form,
};

export default Hubspot;
