/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AnimateHeight from "react-animate-height";
import { Icon } from "@ui";

const Notification = ({ notification }) => {
  const [visible, setVisible] = useState(false);
  const { type, content, time } = notification;
  const defaultTime = time || 4500;
  useEffect(() => {
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, defaultTime);
  }, []);
  return (
    <AnimateHeight
      duration={333}
      height={visible ? "auto" : 0} // see props documentation below
    >
      <div
        className={classNames({
          "bg-coral": type === "error",
          "bg-teal": type === "success",
          "bg-blue": type === "info",
          "bg-black": type === "black",
          "bg-purple": type === "purple",
          "px-8 py-8 my-6 flex justify-between shadow text-white text-left": true,
        })}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <div className="pr-3" dangerouslySetInnerHTML={{ __html: content }} />
        <button
          type="button"
          className="flex-shrink-0"
          onClick={() => {
            setVisible(false);
          }}
        >
          <Icon className="w-5 h-5" iconName="Close" />
        </button>
      </div>
    </AnimateHeight>
  );
};

Notification.propTypes = {
  notification: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])
  ),
};

Notification.defaultProps = {
  notification: {
    time: 4500,
  },
};

export default Notification;
