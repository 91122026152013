/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { HeaderContext } from "@context";
import { HeroPosts, HeroSignup, Slices, Container } from "@layout";
import { PageTransition } from "@components";
import { BlogNav } from "@blog";
import { MetaData, EopCta, EmbedUrl } from "@common";
import { SearchBox } from "@search";
import { detect } from "@utils";

const BlogPost = props => {
  // eslint-disable-next-line react/destructuring-assignment
  const { data } = props;
  const { posts, blog } = data.prismic;
  const {
    setSunrisePage,
    setBlogActivePost,
    setBlogLayout,
    setPostsMenu,
    setPostsShowBlogMenu,
  } = useContext(HeaderContext);
  const darkTheme = posts.dark_theme
    ? "bg-blue-offwhite dark-link hero--posts-pad"
    : "bg-white";
  const sliceClassName = posts.dark_theme ? "dark-link" : null;
  const postsNav = blog?.show_blog_nav ? blog?.blog_nav : blog?.post_nav;

  useEffect(() => {
    setSunrisePage(false);
    setBlogActivePost(posts.category);
    setBlogLayout(true);
    setPostsMenu(postsNav);
    setPostsShowBlogMenu(blog?.show_blog_nav);
  }, []);

  const hasWindow = detect.browser().window;
  const showSidebar = blog?.sidebar && posts?.show_sidebar !== false;
  const showSignup = blog?.email_signup;
  const signupContent = {
    ...showSignup,
    lightTheme: false,
  };
  const embedForm = posts?.embed_form && posts?.embed_form[0];
  const embedUrl = !!embedForm;
  const embedBeforeContent = embedForm?.show_before_content;
  const embedWebinars = blog?.webinars_embed_link;
  const showWebinars = blog?.show_webinars_posts;

  return (
    <>
      <MetaData content={posts} type="article" category={posts.category} />
      <PageTransition>
        <article>
          <Container variant="blog" noPadding className="gradient-black pt-24">
            <div className="flex items-top flex-wrap">
              <div className="hidden bg-gray-menu md:block md:w-1/5 lg:w-1/5 xl:w-1/5 md:px-0 xs:pl-6 sm:md-0 relative sticky top-0 z-20">
                <div className="site--search relative top-0 pt-1">
                  <div
                    className="mx-3 mt-8 max-w-xs"
                    style={{ maxWidth: "330px" }}
                  >
                    <div id="postSearch" className="search--box" />
                    {hasWindow && window && (
                      <SearchBox
                        selector="postSearch"
                        limit={108}
                        placeHolder="Search..."
                      />
                    )}
                  </div>
                </div>
                <BlogNav
                  active={posts.category}
                  limit={12}
                  nav={blog?.show_blog_nav ? blog?.blog_nav : blog?.post_nav}
                />
                {showSidebar && (
                  <section className="mx-8 md:mb-4 lg:mb-6 xl:mb-12">
                    <Slices content={blog.sidebar} uid={posts._meta.uid} />
                    {showWebinars && embedWebinars && (
                      <>
                        <h3 className="text-xl font-bold my-4">
                          Upcoming Webinars
                        </h3>
                        <iframe
                          src={embedWebinars}
                          allowFullScreen
                          title="embedWebinars"
                          security="restricted"
                          className="rounded-md border-transparent"
                          height="555px"
                          width="100%"
                          frameBorder="0"
                          aria-hidden="false"
                        />
                      </>
                    )}
                  </section>
                )}
              </div>
              <div className="w-full md:w-4/5 lg:w-4/5 xl:w-4/5 xs:pr-6 md:pr-0 mb-0 sm:md-0 bg-white">
                <HeroPosts content={posts} variant="light" overflow="visible" />
                <section className="bg-white md:px-6">
                  {embedUrl && embedBeforeContent && (
                    <EmbedUrl hasWindow={hasWindow} embed_form={embedForm} />
                  )}
                  <Slices
                    content={posts.body}
                    darkTheme={posts.dark_theme}
                    className={sliceClassName}
                    variant="blog"
                    noPadding
                    ySpacing="py-1"
                    xSpacing="px-3"
                  />
                  {embedUrl && !embedBeforeContent && (
                    <EmbedUrl hasWindow={hasWindow} embed_form={embedForm} />
                  )}
                  {showSignup && (
                    <section className="relative h-full w-full m-auto">
                      <HeroSignup
                        signup={signupContent}
                        noPadding
                        className="w-3/4 m-auto"
                      />
                    </section>
                  )}
                  {posts.cta_eop &&
                    posts.cta_eop[0] &&
                    posts.cta_eop[0]?.heading &&
                    posts.cta_eop[0]?.heading[0]?.text && (
                      <section className={`pb-24 ${darkTheme}`}>
                        <Container variant="sm">
                          <EopCta content={posts.cta_eop[0]} gradient="blue" />
                        </Container>
                        <div className="bg-transparent h-24 relative z-0" />
                      </section>
                    )}
                </section>
              </div>
            </div>
          </Container>
        </article>
      </PageTransition>
    </>
  );
};

export default BlogPost;

export const query = graphql`
  query PostsQuery($uid: String!) {
    prismic {
      blog(lang: "en-us", uid: "blog") {
        webinars_embed_link
        show_webinars
        show_webinars_posts
        show_blog_nav
        blog_nav {
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_text
          link_description
          hero_icon
        }
        post_nav {
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          link_text
          link_description
          hero_icon
        }
        email_signup {
          background_image
          heading
          icon
          submit_button_text
          submit_button_color
          subtitle
          success_message
          failure_message
          tracking_event
          list_id
          promo_code
          promo_memo
        }
        sidebar {
          ... on PRISMIC_BlogSidebarCopy_block {
            type
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_BlogSidebarImage_block {
            type
            label
            primary {
              image_layout
              remove_white_space
            }
            fields {
              caption
              link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
                ... on PRISMIC_Design {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Posts {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_For_manufacturers {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pages {
                  path
                  title
                  meta_title
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Contact {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pricing {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Permit_packages {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_About {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Blog {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Home {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__ImageLink {
                  url
                }
              }
              image
            }
          }
          ... on PRISMIC_BlogSidebarPull_quote_block {
            type
            label
            primary {
              citation
              quote
            }
          }
          ... on PRISMIC_BlogSidebarLead_copy_block {
            type
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_BlogSidebarVideo_block {
            type
            label
            primary {
              video_caption
              video_url
            }
          }
          ... on PRISMIC_BlogSidebarEmail_signup_block {
            type
            label
            primary {
              background_image
              failure_message
              heading
              submit_button_text
              submit_button_color
              icon
              subtitle
              success_message
              list_id
              promo_code
              promo_memo
              tracking_event
              form_collector
              form_id
              upload_widget
              recaptcha_widget
              require_phone_number
              show_phone_number
              show_messenger
              volume_low_title
              volume_low_subtitle
            }
            fields {
              label
              value
            }
          }
          ... on PRISMIC_BlogSidebarCta_block {
            type
            label
            primary {
              background
              icon_image
              title
              paragraph
              button_label
              button_link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
                ... on PRISMIC_Design {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Posts {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_For_manufacturers {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pages {
                  path
                  title
                  meta_title
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Contact {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pricing {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Permit_packages {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_About {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Blog {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Home {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
          }
        }
      }
      posts(lang: "en-us", uid: $uid) {
        title
        hide
        published_date
        dark_theme
        meta_title
        meta_image
        meta_description
        meta_no_index
        show_sidebar
        primary_image
        hero_background_image
        hero_light_text
        body {
          ... on PRISMIC_PostsBodyCopy_block {
            type
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_PostsBodyImage_block {
            type
            label
            primary {
              image_layout
              remove_white_space
            }
            fields {
              caption
              image
            }
          }
          ... on PRISMIC_PostsBodyPull_quote_block {
            type
            label
            primary {
              citation
              quote
            }
          }
          ... on PRISMIC_PostsBodyLead_copy_block {
            type
            label
            primary {
              copy
            }
          }
          ... on PRISMIC_PostsBodyVideo_block {
            type
            label
            primary {
              video_caption
              video_url
            }
          }
          ... on PRISMIC_PostsBodyEmail_signup_block {
            type
            label
            primary {
              background_image
              failure_message
              heading
              submit_button_text
              submit_button_color
              icon
              subtitle
              success_message
              list_id
              tracking_event
              promo_code
              promo_memo
              require_phone_number
              show_phone_number
            }
          }
          ... on PRISMIC_PostsBodyCta_block {
            type
            label
            primary {
              background
              icon_image
              title
              paragraph
              button_label
              button_link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
                ... on PRISMIC_Design {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Posts {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_For_manufacturers {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pages {
                  path
                  title
                  meta_title
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Contact {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Pricing {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Permit_packages {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_About {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Blog {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Home {
                  title
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
          }
        }
        category
        author {
          ... on PRISMIC_People {
            full_name
            photo
            linkedin_url
          }
        }
        title
        _meta {
          firstPublicationDate
          uid
        }
        cta_eop {
          tracking_event
          heading
          subheading
          button_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          button_text
          arrow_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
          arrow_link_text
        }
        embed_form {
          embed_url_or_code
          embed_code
          typeform
          hubspot
          apply_blue_bg
          show_before_content
          height
          locked_page
          utm_campaign
          utm_term
          utm_content
          utm_source
          heading
          text
          link_text
          link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
            ... on PRISMIC_Design {
              meta_title
              _meta {
                uid
              }
            }
            ... on PRISMIC_Posts {
              meta_title
              _meta {
                uid
              }
            }
            ... on PRISMIC_For_manufacturers {
              meta_title
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pages {
              meta_title
              path
              title
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Contact {
              meta_title
              _meta {
                uid
              }
            }
            ... on PRISMIC_Pricing {
              meta_title
              _meta {
                uid
              }
            }
            ... on PRISMIC_Permit_packages {
              meta_title
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC_About {
              meta_title
              _meta {
                uid
              }
            }
            ... on PRISMIC_Blog {
              meta_title
              _linkType
              _meta {
                uid
              }
            }
            ... on PRISMIC_Home {
              meta_title
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__FileLink {
              url
            }
            ... on PRISMIC__ImageLink {
              url
            }
          }
        }
      }
    }
  }
`;
