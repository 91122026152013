/* eslint-disable react/prop-types */
import React from "react";
import { Image } from "@ui";
import TailwindConfig from "@tailwindConfig";

const ixparams = {
  fit: "facearea",
  facepad: "4",
  con: "40",
  bri: "25",
  blend: TailwindConfig.theme.extend.colors.blue.default,
  bm: "color",
};

const Headshot = React.memo(({ src, className }) => {
  return (
    <div
      className={`overflow-hidden opacity-90 flex-shrink-0 ${className}`}
      style={{ borderRadius: "9px", borderTopRightRadius: "40%" }}
    >
      <Image
        src={src}
        ixparams={ixparams}
        fit="cover"
        style={{ width: "105%", marginLeft: "-5%" }}
      />
    </div>
  );
});

Headshot.defaultProps = {
  className: "w-24 h-24",
};

export default Headshot;
