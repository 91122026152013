/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { SectionNumber } from "@svg";

const NumberedSectionHeading = React.memo(({ content, className }) => {
  const SectionSubheading = () => {
    if (content.subheading[0].text.length > 0) {
      return (
        <h3 className="uppercase tracking-1 mb-2 text-sm font-bold text-blue">
          {content.subheading[0].text}
        </h3>
      );
    }
    return null;
  };
  return (
    <div className={className}>
      <SectionNumber number={content.number} />
      <SectionSubheading text={content.subheading[0].text} />
      <h2 className="leading-tight text-xl sm:text-2-5xl font-bold">
        {content.heading[0].text}
      </h2>
    </div>
  );
});

NumberedSectionHeading.defaultProps = {
  className: "",
};

NumberedSectionHeading.propTypes = {
  className: PropTypes.string,
};

export default NumberedSectionHeading;
