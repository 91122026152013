/* eslint-disable react/prop-types */
import React from "react";
import { useUID } from "react-uid";
import { SectionHeading, Headshot } from "@common";
import { Container } from "@layout";
import { RichText, Icon } from "@ui";

export default React.memo(({ content, team }) => {
  return (
    <div>
      <Container variant="copy">
        <SectionHeading content={content} />
      </Container>
      <div className="-mx-6 mt-16 flex flex-wrap">
        {team.map(({ team_member }) => {
          const uid = useUID();
          return (
            <div key={uid} className="w-full sm:w-1/2 px-6 flex mb-12 sm:mb-8">
              <Headshot src={team_member.photo.url} />
              <div className="pl-8">
                <div className="flex flex-col justify-start mb-3">
                  <div className="text-xl font-bold">
                    {team_member.full_name}
                    {team_member?.linkedin_url && (
                      // eslint-disable-next-line react/jsx-no-target-blank
                      <a href={team_member?.linkedin_url} target="_blank">
                        <Icon
                          className="w-6 h-6 inline-flex pl-3"
                          iconName="LinkedIn"
                        />
                      </a>
                    )}
                  </div>
                  <div className="tracking-2 text-sm text-blue uppercase font-bold mt-1 leading-none -mb-1">
                    {team_member.job_title}
                  </div>
                </div>
                <div className="pb-8 sm:pb-6">
                  <RichText content={team_member.description} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
