import React from "react";
import PropTypes from "prop-types";
import TransitionLink from "gatsby-plugin-transition-link";
import { linkResolver } from "@utils";

const PrismicLink = React.memo(
  ({ className, link, children, activeclass, route, style, id }) => {
    // build external link
    if (
      (link == null || link?._linkType !== "Link.document") &&
      route == null
    ) {
      return (
        <a
          className={className}
          target={link?.target}
          rel="noopener noreferrer"
          href={typeof link !== "undefined" && link !== null ? link?.url : "#"}
          id={id}
        >
          {children}
        </a>
      );
    }
    // build internal link
    let to;
    if (route == null) {
      const linkWithLang = link?._meta?.lang
        ? `/${link?._meta?.lang}${linkResolver(link)}`
        : linkResolver(link);
      to = linkWithLang;
    } else {
      to = route;
    }
    return (
      <TransitionLink
        className={`cursor-pointer ${className}`}
        // activeStyle={{ cursor: "default" }}
        activeClassName={activeclass}
        to={to}
        exit={{
          length: 1,
        }}
        entry={{
          length: 0,
          delay: 0,
        }}
        style={style}
        id={id}
      >
        {children}
      </TransitionLink>
    );
  }
);

PrismicLink.defaultProps = {
  className: "",
  activeclass: "",
  route: null,
  link: { _linkType: null },
  style: {},
  children: {},
  id: null,
};

PrismicLink.propTypes = {
  link: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  className: PropTypes.string,
  activeclass: PropTypes.string,
  route: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  id: PropTypes.string,
};

export default PrismicLink;
