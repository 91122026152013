/* eslint-disable react/button-has-type */
// TODO: Remove eslint-disable
/* eslint-disable no-unused-vars */

import React from "react";
import PropTypes from "prop-types";
import { PrismicLink } from "@utilities";
import { Arrow } from "@svg";
import { Button } from "@ui";

const hoverStates = hoverState => {
  let classes = "";
  // eslint-disable-next-line array-callback-return
  hoverState.map(value => {
    classes += ` hover:${value}`;
  });
  return classes;
};

const ArrowLink = ({
  link,
  route,
  children,
  hoverState,
  activeclass,
  className,
  textClass,
  bgClass,
  button,
  type,
  disabled,
  arrow,
  inline,
  variant,
}) => {
  const inlineLink = inline ? "" : null;
  if (button === true) {
    if (type === "custom" && route) {
      return (
        <Button
          route={route}
          className={className}
          activeclass={activeclass}
          variant={variant}
          hoverState={hoverState}
          textClass={textClass}
          bgClass={bgClass}
        >
          {children}
          {arrow && (
            <Arrow className="ml-6 group-hover:translate-x-full transition" />
          )}
        </Button>
      );
    }
    return (
      <button
        type={type}
        disabled={disabled}
        className={`${textClass} ${className} disabled:opacity-50 disabled:cursor-default text-sm transition cursor-pointer font-bold antialiased group items-center inline-flex ${inlineLink} ${hoverStates(
          hoverState
        )}`}
      >
        {children}
        {arrow && (
          <Arrow className="ml-6 group-hover:translate-x-full transition" />
        )}
      </button>
    );
  }
  if (link === null) {
    return (
      <span
        className={`${textClass} ${className} text-sm transition cursor-pointer font-bold antialiased group items-center inline-flex ${inlineLink} ${hoverStates(
          hoverState
        )}`}
      >
        {children}
        {arrow && (
          <Arrow className="ml-6 group-hover:translate-x-full transition" />
        )}
      </span>
    );
  }
  return (
    <PrismicLink
      className={`${textClass} ${className} text-sm transition font-bold antialiased group items-center inline-flex ${inlineLink} ${hoverStates(
        hoverState
      )}`}
      link={link}
    >
      {children}
      {arrow && (
        <Arrow className="ml-6 group-hover:translate-x-full transition" />
      )}
    </PrismicLink>
  );
};

ArrowLink.defaultProps = {
  hoverState: ["opacity-75"],
  className: "",
  activeclass: "",
  link: null,
  route: null,
  textClass: "text-teal",
  bgClass: "bg-transparent",
  button: false,
  type: "button",
  children: {},
  disabled: false,
  arrow: true,
  inline: true,
  variant: "variant",
};

ArrowLink.propTypes = {
  children: PropTypes.node,
  link: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  route: PropTypes.string,
  hoverState: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  activeclass: PropTypes.string,
  textClass: PropTypes.string,
  bgClass: PropTypes.string,
  button: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  arrow: PropTypes.bool,
  inline: PropTypes.bool,
  variant: PropTypes.string,
};

export default React.memo(ArrowLink);
