/* eslint-disable react/prop-types */
import React from "react";
import { useUID } from "react-uid";
import { RichText, Image, Button } from "@ui";

import { SectionNumber, Checkmark } from "@svg";

import { Container } from "@layout";
import TailwindConfig from "@tailwindConfig";

const SupportedCodes = ({ content, codes }) => {
  const params = {
    bm: "normal",
    balph: "95",
    blend: TailwindConfig.theme.extend.colors.blue.default.replace("#", ""),
  };
  return (
    <div className="bg-blue text-white py-12 px-6 sm:px-12 md:px-0 md:py-20 relative">
      <div className="absolute inset-0 w-full h-full z-0">
        <Image
          src={content.background_image.url}
          ixparams={params}
          fit="cover"
        />
      </div>
      <Container className="relative z-10">
        <SectionNumber number={content.number} color="white" />
        <div className="flex flex-wrap -mx-6">
          <div className="sm:w-1/2 md:w-3/5 md:pr-12 px-6">
            <h4 className="mt-8 text-sm font-bold uppercase tracking-1">
              {content.subheading[0].text}
            </h4>
            <h3 className="mt-2 text-xl leading-tight md:text-2-5xl font-bold">
              {content.heading[0].text}
            </h3>
            <div className="leading-normal rich-text mt-6 sm:my-10">
              <RichText content={content.copy} />
            </div>
          </div>
          <div className="sm:w-1/2 md:w-2/5 px-6 mb-8 sm:mb-0">
            <h4 className="mt-6 text-sm font-bold uppercase tracking-1">
              {content.supported_codes_heading}
            </h4>
            {codes.map(item => {
              const uid = useUID();
              const supportedCodes = item.list_of_codes.split(",");
              return (
                <div key={uid}>
                  <h5 className="mb-2 font-bold text-lg mt-6">
                    {item.code_description}
                  </h5>
                  <ul className="flex flex-wrap">
                    {supportedCodes.map(code => {
                      const subuid = useUID();
                      return (
                        <li
                          key={subuid}
                          className="w-1/2 md:w-2/5 text-sm font-bold my-2 flex items-center"
                        >
                          <span className="h-4 w-4 mr-3">
                            <Checkmark />
                          </span>
                          {code}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <Button variant="md" link={content.button_link}>
            {content.button_text}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default React.memo(SupportedCodes);
