/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import ImgixClient from "imgix-core-js";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import SEO from "./seo";

const query = graphql`
  query EsMetaQuery {
    prismic {
      site_settings(lang: "es-pr", uid: "site-settings") {
        default_meta_author
        default_meta_image
        default_meta_title
        default_meta_description
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        imageUrl
      }
    }
  }
`;

const Template = ({ data, content, type, title, category }) => {
  const {
    default_meta_author,
    default_meta_image,
    default_meta_title,
    default_meta_description,
  } = data.prismic.site_settings;
  const {
    title: defaultTitle,
    description: defaultDescription,
    author: defaultAuthor,
    imageUrl: defaultImageUrl,
  } = data.site.siteMetadata;
  const client = new ImgixClient({
    domain: process.env.GATSBY_IMGIX_DOMAIN,
    secureURLToken: process.env.GATSBY_IMGIX_TOKEN,
  });
  const src =
    content.meta_image != null
      ? content.meta_image.url
      : default_meta_image.url;
  const srcOptions = src || defaultImageUrl;
  const meta_image = client.buildURL(srcOptions, {
    auto: "compress,format",
    fit: "crop",
    crop: "faces,edges,entropy",
    w: 1200,
    h: 1200,
  });
  const pageMetaTitle =
    title || content.meta_title || content.title
      ? content.meta_title || content.title
      : default_meta_title;
  const pageMetaDescription =
    content.meta_description != null
      ? content.meta_description[0].text
      : default_meta_description;
  const metaTitleOptions = pageMetaTitle || defaultTitle;
  const metaDescriptionOptions = pageMetaDescription || defaultDescription;
  const metaAuthorOptions = content?.author?.full_name
    ? `${content?.author?.full_name} @ ${default_meta_author || defaultAuthor}`
    : default_meta_author || defaultAuthor;
  const metaNoIndex = content.meta_no_index || content.hide;

  const [metaType] = useState(type || "website");
  const [metaArticleCategory] = useState(category || "blog");
  const [metaAuthor, setMetaAuthor] = useState(metaAuthorOptions);
  const [metaTitle, setMetaTitle] = useState(
    `${metaTitleOptions} | ${metaAuthor}`
  );
  const [metaImage, setMetaImage] = useState(meta_image);
  const [metaDescription, setMetaDescription] = useState(
    metaDescriptionOptions
  );
  useEffect(() => {
    return () => {
      setMetaAuthor(metaAuthorOptions);
      setMetaImage(meta_image);
      setMetaDescription(metaDescriptionOptions);
      setMetaTitle(`${metaTitleOptions} | ${metaAuthor}`);
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta name="author" content={metaAuthor} />
        <meta property="og:site_name" content={defaultTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:type" content={metaType} />
        <meta property="og:image" content={meta_image} />
        <meta name="webcontent:additionalType" content={metaType} />
        <meta property="article:section" content={metaType} />
        {type === "article" && (
          <meta property="article:author" content={metaAuthorOptions} />
        )}
        {type === "article" && (
          <meta property="article:tag" content={metaArticleCategory} />
        )}
        <meta name="twitter:description" content={metaDescription} />
        {metaNoIndex && (
          <meta
            name="robots"
            content="noindex,nofollow,nosnippet,noarchive,notranslate,noimageindex"
          />
        )}
        {metaNoIndex && (
          <meta
            name="googlebot"
            content="noindex,nofollow,nosnippet,noarchive,notranslate,noimageindex"
          />
        )}
        {metaNoIndex && <meta name="googlebot" content="indexifembedded" />}
        {!metaNoIndex && <meta name="robots" content="all" />}
      </Helmet>
      <SEO
        lang="en"
        author={metaAuthor}
        title={metaTitle}
        image={metaImage}
        description={metaDescription}
        type={metaType}
      />
    </>
  );
};

export default ({ content, title, type, category }) => (
  <StaticQuery
    query={`${query}`}
    render={data => (
      <Template
        content={content}
        data={data}
        title={title}
        type={type}
        category={category}
      />
    )}
  />
);
